export const settlementFieldNamesDTO = {
  columnNames: ["outstandingInvoices", "overdueInvoices"],
};
export const settlementDTO = {
    result: {
      additionalProp1: {},
      additionalProp2: {},
      additionalProp3: {}
    }
  }

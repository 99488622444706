import {
  FacetParam,
  ISearchResultsResponse,
} from "../../dto/searchResults.types";

export const STUB_SEARCH: ISearchResultsResponse = {
  size: 80,
  products: [
    {
      sku: "1002003030",
      debugFields: {
        name: "SENSOR3 H+6 Sensitive",
        index: "1002003030",
        score: "1765.9388",
      },
    },
    {
      sku: "1001000171",
      debugFields: {
        name: "LENOR Płyn Sensitive 930ml",
        index: "1001000171",
        score: "1728.0203",
      },
    },
    {
      sku: "1002003028",
      debugFields: {
        name: "SENSOR3 Wkład Sensitive 8szt",
        index: "1002003028",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000445",
      debugFields: {
        name: "PAMPERS Chust Sensitive 52",
        index: "1007000445",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000447",
      debugFields: {
        name: "PAMPERS Chust Sensitive 3x52",
        index: "1007000447",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000449",
      debugFields: {
        name: "PAMPERS Chust Sensitive 4x52",
        index: "1007000449",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000451",
      debugFields: {
        name: "PAMPERS Chust Sensitive 80",
        index: "1007000451",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000453",
      debugFields: {
        name: "PAMPERS Chust Sensitive 4x80",
        index: "1007000453",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000465",
      debugFields: {
        name: "PAMPERS Chust Sensitive 12x52",
        index: "1007000465",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000887",
      debugFields: {
        name: "PAMPERS Chust Sensitive 15x80",
        index: "1007000887",
        score: "1728.0203",
      },
    },
    {
      sku: "5001003795",
      debugFields: {
        name: "Always Sensitive display BIG",
        index: "5001003795",
        score: "1728.0203",
      },
    },
    {
      sku: "5001005858",
      debugFields: {
        name: "ALWAYS Sensitive Display Small",
        index: "5001005858",
        score: "1728.0203",
      },
    },
    {
      sku: "1001000145",
      debugFields: {
        name: "LENOR Płyn Sensitive 1,36L",
        index: "1001000145",
        score: "1621.7427",
      },
    },
    {
      sku: "1001001110",
      debugFields: {
        name: "LENOR Płyn Sensitive 1,8L",
        index: "1001001110",
        score: "1621.7427",
      },
    },
    {
      sku: "1001001976",
      debugFields: {
        name: "ZZ_ARIEL Kapsułki Sensitive 26",
        index: "1001001976",
        score: "1621.7427",
      },
    },
    {
      sku: "1001001977",
      debugFields: {
        name: "ZZ_ARIEL Kapsułki Sensitive 33",
        index: "1001001977",
        score: "1621.7427",
      },
    },
    {
      sku: "1001002101",
      debugFields: {
        name: "ZZ_VIZIR Kapsułki Sensitive 27",
        index: "1001002101",
        score: "1621.7427",
      },
    },
    {
      sku: "1001002631",
      debugFields: {
        name: "ARIEL Kapsułki Sensitive 26_ZZ",
        index: "1001002631",
        score: "1621.7427",
      },
    },
    {
      sku: "1001002635",
      debugFields: {
        name: "ARIEL Kapsułki Sensitive 13_ZZ",
        index: "1001002635",
        score: "1621.7427",
      },
    },
    {
      sku: "1002000399",
      debugFields: {
        name: "ZZ_MACH3 Wkład Sensitive 4szt",
        index: "1002000399",
        score: "1621.7427",
      },
    },
    {
      sku: "1002001662",
      debugFields: {
        name: "BLUE3 Masz Sensitive 9+3",
        index: "1002001662",
        score: "1621.7427",
      },
    },
    {
      sku: "1002002021",
      debugFields: {
        name: "BLUE3 Masz Sensitive 5+1szt",
        index: "1002002021",
        score: "1621.7427",
      },
    },
    {
      sku: "1002002022",
      debugFields: {
        name: "BLUE3 Masz Sensitive 6+2szt",
        index: "1002002022",
        score: "1621.7427",
      },
    },
    {
      sku: "1007000455",
      debugFields: {
        name: "ZZ_PAMPERS Chust Sensitive 6x80",
        index: "1007000455",
        score: "1621.7427",
      },
    },
    {
      sku: "1001003247",
      debugFields: {
        name: "FAIRY PGP HDW Sensitive 5L",
        index: "1001003247",
        score: "1605.5939",
      },
    },
    {
      sku: "1002001606",
      debugFields: {
        name: "BLUE3 Masz Sensitive 3 szt",
        index: "1002001606",
        score: "1605.5939",
      },
    },
    {
      sku: "1002001654",
      debugFields: {
        name: "VENUS Masz Sensitive 3 szt",
        index: "1002001654",
        score: "1605.5939",
      },
    },
    {
      sku: "1002001655",
      debugFields: {
        name: "VENUS3 Masz Sensitive 3 szt",
        index: "1002001655",
        score: "1605.5939",
      },
    },
    {
      sku: "1002001657",
      debugFields: {
        name: "VENUS3 Masz Sensitive 6 szt",
        index: "1002001657",
        score: "1605.5939",
      },
    },
    {
      sku: "1002001887",
      debugFields: {
        name: "WELLAFLEX Lakier (3) Sensitive 250ml",
        index: "1002001887",
        score: "1605.5939",
      },
    },
  ],
  parameters: [
    {
      name: "Brand",
      type: FacetParam.FACET,
      values: [
        { name: "A10200030011", count: 1 },
        { name: "A82000250074", count: 3 },
        { name: "A82000250076", count: 7 },
        { name: "A82000250077", count: 2 },
        { name: "A82000250078", count: 2 },
        { name: "A82000250081", count: 4 },
        { name: "A82000250083", count: 7 },
        { name: "A82000280089", count: 2 },
        { name: "A82000300107", count: 15 },
        { name: "A82000310109", count: 12 },
        { name: "A82000310111", count: 4 },
        { name: "A82000320113", count: 2 },
        { name: "A82000340117", count: 4 },
        { name: "A82000350126", count: 3 },
        { name: "A82000370130", count: 7 },
        { name: "A86600380132", count: 1 },
        { name: "AZ9999999997", count: 4 },
      ],
    },
    {
      name: "EAN",
      type: FacetParam.FACET,
      values: [
        { name: "10000011085", count: 1 },
        { name: "10000012272", count: 1 },
        { name: "10000012569", count: 1 },
        { name: "10000012794", count: 1 },
        { name: "10000014293", count: 1 },
        { name: "3014260219949", count: 1 },
        { name: "4015400259480", count: 2 },
        { name: "4084500105690", count: 1 },
        { name: "4084500583115", count: 2 },
        { name: "4210201299899", count: 1 },
        { name: "5011321336124", count: 1 },
        { name: "7702018037896", count: 1 },
        { name: "7702018352883", count: 1 },
        { name: "7702018442423", count: 1 },
        { name: "7702018442447", count: 1 },
        { name: "7702018457786", count: 1 },
        { name: "7702018459803", count: 2 },
        { name: "7702018464715", count: 1 },
        { name: "7702018464876", count: 1 },
        { name: "7702018487028", count: 1 },
        { name: "7702018487158", count: 1 },
        { name: "7702018490080", count: 1 },
        { name: "7702018490134", count: 1 },
        { name: "7702018490646", count: 1 },
        { name: "7702018491544", count: 1 },
        { name: "7702018498505", count: 1 },
        { name: "7702018505678", count: 1 },
        { name: "7702018589029", count: 1 },
        { name: "7702018589043", count: 1 },
        { name: "7702018604005", count: 1 },
        { name: "7702018604104", count: 1 },
        { name: "7702018609994", count: 1 },
        { name: "7702018617098", count: 1 },
        { name: "7702018837830", count: 1 },
        { name: "8001090206930", count: 2 },
        { name: "8001090207418", count: 1 },
        { name: "8001090214324", count: 1 },
        { name: "8001090566508", count: 1 },
        { name: "8001090791313", count: 1 },
        { name: "8001090992512", count: 1 },
        { name: "8001841041391", count: 2 },
        { name: "8001841041421", count: 2 },
        { name: "8001841041483", count: 1 },
        { name: "8001841061979", count: 1 },
        { name: "8001841062556", count: 2 },
        { name: "8001841062624", count: 1 },
        { name: "8001841062754", count: 1 },
        { name: "8001841062976", count: 1 },
        { name: "8001841063188", count: 1 },
        { name: "8001841117348", count: 1 },
        { name: "8001841163666", count: 1 },
        { name: "8001841375564", count: 2 },
        { name: "8001841597881", count: 1 },
        { name: "8001841597928", count: 1 },
        { name: "8001841601694", count: 1 },
        { name: "8001841693583", count: 1 },
        { name: "8001841693804", count: 1 },
        { name: "8001841924182", count: 1 },
        { name: "8006540100448", count: 1 },
        { name: "8006540115374", count: 1 },
        { name: "8006540117125", count: 2 },
        { name: "8006540120217", count: 1 },
        { name: "8006540120255", count: 1 },
        { name: "8006540120293", count: 2 },
        { name: "8006540174531", count: 1 },
        { name: "8006540209561", count: 1 },
        { name: "8006540210802", count: 1 },
        { name: "8006540523056", count: 1 },
        { name: "8006540523087", count: 1 },
        { name: "8699568543283", count: 1 },
      ],
    },
    {
      name: "ERPCategory",
      type: FacetParam.FACET,
      values: [
        { name: "A001", count: 14 },
        { name: "A002", count: 12 },
        { name: "A003", count: 2 },
        { name: "A006", count: 2 },
        { name: "A007", count: 5 },
        { name: "A010", count: 1 },
        { name: "A013", count: 2 },
        { name: "A018", count: 2 },
        { name: "A021", count: 23 },
        { name: "A053", count: 1 },
        { name: "A998", count: 5 },
        { name: "A999", count: 11 },
      ],
    },
    {
      name: "FullName",
      type: FacetParam.FACET,
      values: [
        { name: "ALWAYS Classic Sensitive Super 9", count: 1 },
        { name: "ALWAYS Classic Sensitive Super 9 GRATIS", count: 1 },
        { name: "ALWAYS Sensitive Dis Small REBRANDING", count: 1 },
        { name: "ALWAYS Sensitive Display Big POL", count: 1 },
        { name: "ALWAYS Sensitive Display Small", count: 1 },
        { name: "ALWAYS Sensitive JFM22 Display Small", count: 1 },
        { name: "ARIEL Kapsułki Sensitive 13_ZZ", count: 1 },
        { name: "ARIEL Kapsułki Sensitive 13_ZZ GRATIS", count: 1 },
        { name: "ARIEL Kapsułki Sensitive 26_ZZ", count: 1 },
        { name: "ARIEL Płyn Sensitive 42W 2,310L", count: 1 },
        { name: "ARIEL Płyn Sensitive 62W 3,410L", count: 1 },
        { name: "ARIEL Płyn Sensitive 62W 3,410L GRATIS", count: 1 },
        { name: "Always Sensitive display BIG", count: 1 },
        { name: "BLUE3 Masz Sensitive 3 szt", count: 1 },
        { name: "BLUE3 Masz Sensitive 5+1szt", count: 1 },
        { name: "BLUE3 Masz Sensitive 6+2szt", count: 1 },
        { name: "BLUE3 Masz Sensitive 9+3", count: 1 },
        { name: "FAIRY PGP HDW Sensitive 5L", count: 1 },
        { name: "FAIRY PGP Płyn Sensitive 5L GRATIS", count: 1 },
        { name: "FUSION Żel D/G Sensitive 200ml", count: 1 },
        { name: "FUSION_5 Żel d/g Ultra Sensitive 75ml", count: 1 },
        { name: "GT Żel Pro Sensitive 200ml", count: 1 },
        { name: "GT Żel Skin Ultra Sensitive 200ml", count: 1 },
        { name: "H&amp;S Szamp Sensitive 400ml", count: 1 },
        { name: "LENOR PGP Płyn Sensitive 4,75L", count: 1 },
        { name: "LENOR Płyn Sensitive 1,36L", count: 1 },
        { name: "LENOR Płyn Sensitive 1,8L", count: 1 },
        { name: "LENOR Płyn Sensitive 1,8L GRATIS", count: 1 },
        { name: "LENOR Płyn Sensitive 930ml", count: 1 },
        { name: "LENOR Płyn Sensitive 930ml GRATIS", count: 1 },
        { name: "LENOR Płyn Sensitive XXL BP 2x1,36L", count: 1 },
        { name: "ORALB Końcówki Pulsonic Sensitive SR32-4", count: 1 },
        { name: "PAMPERS Chust Sensitive 12x52", count: 1 },
        { name: "PAMPERS Chust Sensitive 15x80", count: 1 },
        { name: "PAMPERS Chust Sensitive 3x52", count: 1 },
        { name: "PAMPERS Chust Sensitive 3x52 GRATIS", count: 1 },
        { name: "PAMPERS Chust Sensitive 4x52", count: 1 },
        { name: "PAMPERS Chust Sensitive 4x52 PlasticFree", count: 1 },
        { name: "PAMPERS Chust Sensitive 4x80", count: 1 },
        { name: "PAMPERS Chust Sensitive 52", count: 1 },
        { name: "PAMPERS Chust Sensitive 52 GRATIS", count: 1 },
        { name: "PAMPERS Chust Sensitive 52 Plastic Free", count: 1 },
        { name: "PAMPERS Chust Sensitive 6x80 New", count: 1 },
        { name: "PAMPERS Chust Sensitive 80", count: 1 },
        { name: "PAMPERS Chust Sensitive 80 GRATIS", count: 1 },
        { name: "SENSOR3 H+6 Sensitive", count: 1 },
        { name: "SENSOR3 Wkład Sensitive 8szt", count: 1 },
        { name: "SRS Pianka d/g Sensitive Cool 250 GRATIS", count: 1 },
        { name: "SRS Pianka d/g Sensitive Cool 250ml", count: 1 },
        { name: "SRS Żel d/g Sensitive 75ml", count: 1 },
        { name: "SRS Żel d/g Sensitive Cool 200ml", count: 1 },
        { name: "SRS Żel d/g Ultra Sensitive 2x200 GRATIS", count: 1 },
        { name: "VENUS Masz Delux Sensitive Rosegold H+3", count: 1 },
        { name: "VENUS Masz Sensitive 3 szt", count: 1 },
        { name: "VENUS Wkład Extra Smooth Sensitive 4szt", count: 1 },
        { name: "VENUS3 Masz Sensitive 3 szt", count: 1 },
        { name: "VENUS3 Masz Sensitive 6 szt", count: 1 },
        { name: "WELLAFLEX Lakier (3) Sensitive 250ml", count: 1 },
        { name: "ZZ_ALWAYS Classic Sensitiv Normal 20", count: 1 },
        { name: "ZZ_ARIEL Kapsułki Sensitive 26", count: 1 },
        { name: "ZZ_ARIEL Kapsułki Sensitive 33", count: 1 },
        { name: "ZZ_ARIEL Płyn Sensitive 18W 990ml", count: 1 },
        { name: "ZZ_ARIEL Płyn Sensitive 2,2L", count: 1 },
        { name: "ZZ_ARIEL Płyn Sensitive 2,64L", count: 1 },
        { name: "ZZ_ARIEL Płyn Sensitive 35W 1,925L", count: 1 },
        { name: "ZZ_FAIRY PGP Płyn Sensitive 5L", count: 1 },
        { name: "ZZ_FUSION_5 Żel d/g Ultra Sensitive200ml", count: 1 },
        { name: "ZZ_H&amp;S Szamp Sensitive 250ml", count: 1 },
        { name: "ZZ_LENOR PGP Płyn Sensitiv 4,75L", count: 1 },
        { name: "ZZ_LENOR płyn Sensitive 2,905L", count: 1 },
        { name: "ZZ_MACH3 Wkład Sensitive 4szt", count: 1 },
        { name: "ZZ_MACH3 Żel d/g Sensitive 200ml", count: 1 },
        { name: "ZZ_MACH3 Żel d/g Sensitive 2x200ml", count: 1 },
        { name: "ZZ_PAMPERS Chust Sensitive 6x80", count: 1 },
        { name: "ZZ_PAMPERS Chust Sensitive SMP 12", count: 1 },
        { name: "ZZ_SKINGUARD Masz Sensitive H + 3 Wkł", count: 1 },
        { name: "ZZ_VIZIR Kapsułki Sensitive 27", count: 1 },
        { name: "ZZ_VIZIR Proszek Sensitive 35W 2,625kg", count: 1 },
        { name: "ZZ_VIZIR Proszek Sensitive 36W 2,7kg", count: 1 },
        { name: "ZZ_VZIR Proszek Sensitive 51W 3,825kg", count: 1 },
      ],
    },
    {
      name: "ItemCategoryGroup",
      type: FacetParam.FACET,
      values: [
        { name: "NORM", count: 64 },
        { name: "ZAGR", count: 11 },
        { name: "ZAPN", count: 5 },
      ],
    },
    {
      name: "Manufacturer",
      type: FacetParam.FACET,
      values: [
        { name: "A02", count: 1 },
        { name: "A20", count: 63 },
      ],
    },
    {
      name: "MaterialID",
      type: FacetParam.FACET,
      values: [
        { name: "1001000145", count: 1 },
        { name: "1001000169", count: 1 },
        { name: "1001000171", count: 1 },
        { name: "1001000685", count: 1 },
        { name: "1001001059", count: 1 },
        { name: "1001001110", count: 1 },
        { name: "1001001200", count: 1 },
        { name: "1001001334", count: 1 },
        { name: "1001001976", count: 1 },
        { name: "1001001977", count: 1 },
        { name: "1001002015", count: 1 },
        { name: "1001002019", count: 1 },
        { name: "1001002101", count: 1 },
        { name: "1001002205", count: 1 },
        { name: "1001002631", count: 1 },
        { name: "1001002635", count: 1 },
        { name: "1001002645", count: 1 },
        { name: "1001002646", count: 1 },
        { name: "1001002647", count: 1 },
        { name: "1001002652", count: 1 },
        { name: "1001002665", count: 1 },
        { name: "1001003247", count: 1 },
        { name: "1001003525", count: 1 },
        { name: "1002000098", count: 1 },
        { name: "1002000100", count: 1 },
        { name: "1002000399", count: 1 },
        { name: "1002000441", count: 1 },
        { name: "1002000458", count: 1 },
        { name: "1002000466", count: 1 },
        { name: "1002001531", count: 1 },
        { name: "1002001532", count: 1 },
        { name: "1002001536", count: 1 },
        { name: "1002001537", count: 1 },
        { name: "1002001606", count: 1 },
        { name: "1002001654", count: 1 },
        { name: "1002001655", count: 1 },
        { name: "1002001657", count: 1 },
        { name: "1002001662", count: 1 },
        { name: "1002001887", count: 1 },
        { name: "1002002000", count: 1 },
        { name: "1002002021", count: 1 },
        { name: "1002002022", count: 1 },
        { name: "1002002204", count: 1 },
        { name: "1002003028", count: 1 },
        { name: "1002003030", count: 1 },
        { name: "1002003142", count: 1 },
        { name: "1002003153", count: 1 },
        { name: "1002003154", count: 1 },
        { name: "1002003177", count: 1 },
        { name: "1007000444", count: 1 },
        { name: "1007000445", count: 1 },
        { name: "1007000447", count: 1 },
        { name: "1007000449", count: 1 },
        { name: "1007000451", count: 1 },
        { name: "1007000453", count: 1 },
        { name: "1007000455", count: 1 },
        { name: "1007000465", count: 1 },
        { name: "1007000508", count: 1 },
        { name: "1007000510", count: 1 },
        { name: "1007000885", count: 1 },
        { name: "1007000887", count: 1 },
        { name: "1007000971", count: 1 },
        { name: "1007000972", count: 1 },
        { name: "1011002229", count: 1 },
        { name: "5001003795", count: 1 },
        { name: "5001004804", count: 1 },
        { name: "5001005807", count: 1 },
        { name: "5001005858", count: 1 },
        { name: "5001007155", count: 1 },
        { name: "5002002871", count: 1 },
        { name: "5002002956", count: 1 },
        { name: "5002002965", count: 1 },
        { name: "5002003001", count: 1 },
        { name: "5002003002", count: 1 },
        { name: "5002003007", count: 1 },
        { name: "5002003203", count: 1 },
        { name: "5002003243", count: 1 },
        { name: "5002003274", count: 1 },
        { name: "5002007003", count: 1 },
        { name: "5002007927", count: 1 },
      ],
    },
    {
      name: "MaterialType",
      type: FacetParam.FACET,
      values: [
        { name: "1001", count: 23 },
        { name: "1002", count: 26 },
        { name: "1007", count: 14 },
        { name: "1011", count: 1 },
        { name: "5001", count: 5 },
        { name: "5002", count: 11 },
      ],
    },
    {
      name: "PortalBrand",
      type: FacetParam.FACET,
      values: [
        { name: "107", count: 4 },
        { name: "111", count: 1 },
        { name: "28", count: 3 },
        { name: "35", count: 24 },
        { name: "37", count: 2 },
        { name: "4", count: 4 },
        { name: "49", count: 9 },
        { name: "69", count: 1 },
        { name: "71", count: 15 },
        { name: "8", count: 10 },
      ],
    },
    {
      name: "PortalProducer",
      type: FacetParam.FACET,
      values: [
        { name: "13", count: 1 },
        { name: "31", count: 63 },
        { name: "56", count: 3 },
      ],
    },
    {
      name: "Producer",
      type: FacetParam.FACET,
      values: [
        { name: "A02", count: 1 },
        { name: "A20", count: 78 },
        { name: "A66", count: 1 },
      ],
    },
    {
      name: "ProductName",
      type: FacetParam.FACET,
      values: [
        {
          name: "ALWAYS Classic Sensitive Normal, podpaski, 20 szt.",
          count: 1,
        },
        { name: "ALWAYS Classic Sensitive Super, podpaski, 9 szt.", count: 2 },
        { name: "ARIEL Sensitive, kapsułki do prania, 13 szt.", count: 1 },
        { name: "ARIEL Sensitive, kapsułki do prania, 26 szt.", count: 1 },
        { name: "ARIEL Sensitive, kapsułki do prania, 33 szt.", count: 1 },
        { name: "ARIEL Sensitive, płyn do prania, 1.93 l", count: 1 },
        { name: "ARIEL Sensitive, płyn do prania, 2.2 l", count: 1 },
        { name: "ARIEL Sensitive, płyn do prania, 2.31 l", count: 1 },
        { name: "ARIEL Sensitive, płyn do prania, 3.41 l", count: 2 },
        { name: "ARIEL Sensitive, płyn do prania, 990 ml", count: 1 },
        { name: "FAIRY Sensitive, płyn do mycia naczyń, 5 l", count: 2 },
        {
          name: "GILLETTE Blue 3 Sensitive, maszynki jednorazowe, 12 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Blue 3 Sensitive, maszynki jednorazowe, 3 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Blue 3 Sensitive, maszynki jednorazowe, 6 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Blue 3 Sensitive, maszynki jednorazowe, 8 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Fusion 5 Ultra Sensitive, żel do golenia, 200 ml",
          count: 1,
        },
        {
          name: "GILLETTE Fusion Ultra Sensitive, żel do golenia, 75 ml",
          count: 1,
        },
        { name: "GILLETTE Fusion, żel do golenia, 200 ml", count: 1 },
        {
          name: "GILLETTE Mach 3 Sensitive, żel do golenia, 2x200ml",
          count: 1,
        },
        {
          name: "GILLETTE Match 3 Sensitive, wkłady do maszynki, 4 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Match 3 Sensitive, żel do golenia, 200 ml",
          count: 1,
        },
        { name: "GILLETTE Pro, żel do golenia, 200 ml", count: 1 },
        { name: "GILLETTE Sensitive Cool, żel do golenia, 200 ml", count: 1 },
        { name: "GILLETTE Sensitive, żel do golenia, 75 ml", count: 1 },
        {
          name: "GILLETTE Sensor3 Sensitive 1+6, maszynka do golenia, 6 wkł.",
          count: 1,
        },
        {
          name: "GILLETTE Sensor3 Sensitive, wkłady do maszynki, 8 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Series Sensitive Cool, pianka / żel / krem, 250 ml",
          count: 1,
        },
        {
          name: "GILLETTE Series Sensitive Cool, pianka do golenia, 250 ml",
          count: 1,
        },
        {
          name: "GILLETTE Skinguard Sensitive, maszynka do golenia, 3 wkł.",
          count: 1,
        },
        { name: "GILLETTE Ultra Sensitive, żel do golenia, 200 ml", count: 1 },
        {
          name: "GILLETTE Venus 3 Sensitive, maszynki jednorazowe, 3 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Venus 3 Sensitive, maszynki jednorazowe, 6 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Venus Deluxe Smooth Sensitive, maszynka do golenia, 3 wkł.",
          count: 1,
        },
        {
          name: "GILLETTE Venus Extra Smooth Sensitive, wkłady do maszynki, 4 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Venus Sensitive, maszynki jednorazowe, 3 szt.",
          count: 1,
        },
        { name: "HEAD&SHOULDERS Sensitive, szampon, 250 ml", count: 1 },
        { name: "HEAD&SHOULDERS Sensitive, szampon, 400 ml", count: 1 },
        { name: "LENOR Sensitive, płyn do płukania, 1360 ml", count: 1 },
        { name: "LENOR Sensitive, płyn do płukania, 1800 ml", count: 2 },
        { name: "LENOR Sensitive, płyn do płukania, 2.91 l", count: 1 },
        { name: "LENOR Sensitive, płyn do płukania, 2x1360 ml", count: 1 },
        { name: "LENOR Sensitive, płyn do płukania, 4750 ml", count: 1 },
        { name: "LENOR Sensitive, płyn do płukania, 930 ml", count: 2 },
        {
          name: "ORAL-B Pulsonic Sensitive SR32-4, końcówki do szczoteczek, 4 szt.",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive , chusteczki pielęgnacyjne, 4x52 szt.",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, 100% z włókien roślinnych, chusteczki pielęgnacyjne, 52 szt.",
          count: 1,
        },
        { name: "PAMPERS Sensitive, chusteczki pielęgnacyjne", count: 1 },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 12 szt.",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 12x52szt",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 15x80 szt.",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 3x52szt",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 4x52szt",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 4x80szt",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 52 szt.",
          count: 2,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 6x80 szt.",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 6x80szt",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 80 szt.",
          count: 2,
        },
        { name: "VIZIR Delikatny, proszek do prania, 2.63 kg", count: 1 },
        { name: "VIZIR Sensitive, proszek do prania, 3.83 kg", count: 1 },
        { name: "WELLAFLEX Sensitive 3, lakier do włosów, 250 ml", count: 1 },
      ],
    },
    {
      name: "Size",
      type: FacetParam.FACET,
      values: [
        { name: "A0015", count: 1 },
        { name: "A0016", count: 1 },
        { name: "A0049", count: 1 },
        { name: "A0051", count: 2 },
        { name: "A0053", count: 2 },
        { name: "A0060", count: 1 },
        { name: "A0061", count: 2 },
        { name: "A0062", count: 1 },
        { name: "A0094", count: 1 },
        { name: "A0095", count: 1 },
        { name: "A0096", count: 1 },
        { name: "A0098", count: 1 },
        { name: "A0221", count: 1 },
        { name: "A0223", count: 1 },
        { name: "A0226", count: 4 },
        { name: "A0244", count: 1 },
        { name: "A0247", count: 1 },
        { name: "A0274", count: 1 },
        { name: "A0275", count: 1 },
        { name: "A0278", count: 1 },
        { name: "A0281", count: 1 },
        { name: "A0289", count: 1 },
        { name: "A0392", count: 1 },
        { name: "A0393", count: 1 },
        { name: "A0395", count: 1 },
        { name: "A0396", count: 1 },
        { name: "A0397", count: 1 },
        { name: "A0398", count: 2 },
        { name: "A0399", count: 2 },
        { name: "A0422", count: 1 },
        { name: "A0468", count: 1 },
        { name: "A0474", count: 1 },
        { name: "A0476", count: 1 },
        { name: "A0477", count: 1 },
        { name: "A0505", count: 1 },
        { name: "A0527", count: 1 },
        { name: "A0537", count: 1 },
        { name: "A0539", count: 1 },
        { name: "A0553", count: 2 },
        { name: "A0556", count: 1 },
        { name: "A0561", count: 1 },
        { name: "A0568", count: 1 },
        { name: "A0586", count: 2 },
        { name: "A0587", count: 1 },
        { name: "A0629", count: 1 },
        { name: "A0631", count: 1 },
        { name: "A0757", count: 1 },
        { name: "A0762", count: 2 },
        { name: "A0804", count: 1 },
        { name: "A0834", count: 1 },
        { name: "A0835", count: 1 },
        { name: "A0841", count: 1 },
        { name: "AZZZZ", count: 1 },
      ],
    },
    {
      name: "cat02",
      type: FacetParam.TREE,
      values: [
        { name: "Household cleaners", count: 20, id: "0202", parentId: "02" },
        {
          name: "Cosmetics, care & hygiene",
          count: 28,
          id: "0203",
          parentId: "02",
        },
        { name: "Baby care", count: 12, id: "0204", parentId: "02" },
        { name: "Small home appliances", count: 1, id: "0205", parentId: "02" },
        { name: "Professional products", count: 3, id: "0206", parentId: "02" },
      ],
    },
    {
      name: "cat03",
      type: FacetParam.TREE,
      values: [
        {
          name: "Laundry detergents",
          count: 19,
          id: "020201",
          parentId: "0202",
        },
        { name: "Dishwashing", count: 1, id: "020202", parentId: "0202" },
        { name: "Feminine care", count: 2, id: "020301", parentId: "0203" },
        { name: "Hair care", count: 3, id: "020302", parentId: "0203" },
        {
          name: "Shaving & depilation",
          count: 22,
          id: "020304",
          parentId: "0203",
        },
        {
          name: "Cleansing & make-up removal",
          count: 1,
          id: "020307",
          parentId: "0203",
        },
        { name: "Care wipes", count: 12, id: "020402", parentId: "0204" },
        { name: "Care & beauty", count: 1, id: "020501", parentId: "0205" },
        {
          name: "Washing powders & liquids",
          count: 2,
          id: "020602",
          parentId: "0206",
        },
        {
          name: "Disinfection products",
          count: 1,
          id: "020603",
          parentId: "0206",
        },
      ],
    },
    {
      name: "dedicated_to",
      type: FacetParam.FACET,
      values: [
        { name: "man", count: 16 },
        { name: "women", count: 5 },
      ],
    },
    {
      name: "pools",
      type: FacetParam.FACET,
      values: [{ name: "Gratis", count: 5 }],
    },
    {
      name: "weight",
      type: FacetParam.FACET,
      values: [
        { name: "0,035 kg", count: 1 },
        { name: "0,039 kg", count: 1 },
        { name: "0,042 kg", count: 1 },
        { name: "0,057 kg", count: 1 },
        { name: "0,061 kg", count: 1 },
        { name: "0,064 kg", count: 1 },
        { name: "0,073 kg", count: 1 },
        { name: "0,079 kg", count: 1 },
        { name: "0,084 kg", count: 1 },
        { name: "0,094 kg", count: 1 },
        { name: "0,097 kg", count: 1 },
        { name: "0,117 kg", count: 1 },
        { name: "0,121 kg", count: 1 },
        { name: "0,13 kg", count: 1 },
        { name: "0,131 kg", count: 1 },
        { name: "0,144 kg", count: 1 },
        { name: "0,161 kg", count: 1 },
        { name: "0,258 kg", count: 2 },
        { name: "0,275 kg", count: 1 },
        { name: "0,28 kg", count: 3 },
        { name: "0,289 kg", count: 1 },
        { name: "0,32 kg", count: 1 },
        { name: "0,347 kg", count: 1 },
        { name: "0,38 kg", count: 1 },
        { name: "0,456 kg", count: 1 },
        { name: "0,531 kg", count: 1 },
        { name: "0,563 kg", count: 1 },
        { name: "0,715 kg", count: 1 },
        { name: "0,928 kg", count: 1 },
        { name: "0,976 kg", count: 1 },
        { name: "1,051 kg", count: 1 },
        { name: "1,13 kg", count: 1 },
        { name: "1,398 kg", count: 1 },
        { name: "1,413 kg", count: 1 },
        { name: "1,859 kg", count: 1 },
        { name: "2,133 kg", count: 1 },
        { name: "2,139 kg", count: 1 },
        { name: "2,427 kg", count: 1 },
        { name: "2,55 kg", count: 1 },
        { name: "2,648 kg", count: 1 },
        { name: "2,817 kg", count: 1 },
        { name: "3,022 kg", count: 1 },
        { name: "3,747 kg", count: 1 },
        { name: "3,856 kg", count: 1 },
        { name: "4,467 kg", count: 1 },
      ],
    },
  ],
};

export const STUB_SEARCH_B2B: ISearchResultsResponse = {
  size: 80,
  products: [
    {
      sku: "1002003030",
      debugFields: {
        name: "SENSOR3 H+6 Sensitive",
        index: "1002003030",
        score: "1765.9388",
      },
    },
    {
      sku: "1001000171",
      debugFields: {
        name: "LENOR Płyn Sensitive 930ml",
        index: "1001000171",
        score: "1728.0203",
      },
    },
    {
      sku: "1002003028",
      debugFields: {
        name: "SENSOR3 Wkład Sensitive 8szt",
        index: "1002003028",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000445",
      debugFields: {
        name: "PAMPERS Chust Sensitive 52",
        index: "1007000445",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000447",
      debugFields: {
        name: "PAMPERS Chust Sensitive 3x52",
        index: "1007000447",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000449",
      debugFields: {
        name: "PAMPERS Chust Sensitive 4x52",
        index: "1007000449",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000451",
      debugFields: {
        name: "PAMPERS Chust Sensitive 80",
        index: "1007000451",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000453",
      debugFields: {
        name: "PAMPERS Chust Sensitive 4x80",
        index: "1007000453",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000465",
      debugFields: {
        name: "PAMPERS Chust Sensitive 12x52",
        index: "1007000465",
        score: "1728.0203",
      },
    },
    {
      sku: "1007000887",
      debugFields: {
        name: "PAMPERS Chust Sensitive 15x80",
        index: "1007000887",
        score: "1728.0203",
      },
    },
    {
      sku: "5001003795",
      debugFields: {
        name: "Always Sensitive display BIG",
        index: "5001003795",
        score: "1728.0203",
      },
    },
    {
      sku: "5001005858",
      debugFields: {
        name: "ALWAYS Sensitive Display Small",
        index: "5001005858",
        score: "1728.0203",
      },
    },
    {
      sku: "1001000145",
      debugFields: {
        name: "LENOR Płyn Sensitive 1,36L",
        index: "1001000145",
        score: "1621.7427",
      },
    },
    {
      sku: "1001001110",
      debugFields: {
        name: "LENOR Płyn Sensitive 1,8L",
        index: "1001001110",
        score: "1621.7427",
      },
    },
    {
      sku: "1001001976",
      debugFields: {
        name: "ZZ_ARIEL Kapsułki Sensitive 26",
        index: "1001001976",
        score: "1621.7427",
      },
    },
    {
      sku: "1001001977",
      debugFields: {
        name: "ZZ_ARIEL Kapsułki Sensitive 33",
        index: "1001001977",
        score: "1621.7427",
      },
    },
    {
      sku: "1001002101",
      debugFields: {
        name: "ZZ_VIZIR Kapsułki Sensitive 27",
        index: "1001002101",
        score: "1621.7427",
      },
    },
    {
      sku: "1001002631",
      debugFields: {
        name: "ARIEL Kapsułki Sensitive 26_ZZ",
        index: "1001002631",
        score: "1621.7427",
      },
    },
    {
      sku: "1001002635",
      debugFields: {
        name: "ARIEL Kapsułki Sensitive 13_ZZ",
        index: "1001002635",
        score: "1621.7427",
      },
    },
    {
      sku: "1002000399",
      debugFields: {
        name: "ZZ_MACH3 Wkład Sensitive 4szt",
        index: "1002000399",
        score: "1621.7427",
      },
    },
    {
      sku: "1002001662",
      debugFields: {
        name: "BLUE3 Masz Sensitive 9+3",
        index: "1002001662",
        score: "1621.7427",
      },
    },
    {
      sku: "1002002021",
      debugFields: {
        name: "BLUE3 Masz Sensitive 5+1szt",
        index: "1002002021",
        score: "1621.7427",
      },
    },
    {
      sku: "1002002022",
      debugFields: {
        name: "BLUE3 Masz Sensitive 6+2szt",
        index: "1002002022",
        score: "1621.7427",
      },
    },
    {
      sku: "1007000455",
      debugFields: {
        name: "ZZ_PAMPERS Chust Sensitive 6x80",
        index: "1007000455",
        score: "1621.7427",
      },
    },
    {
      sku: "1001003247",
      debugFields: {
        name: "FAIRY PGP HDW Sensitive 5L",
        index: "1001003247",
        score: "1605.5939",
      },
    },
    {
      sku: "1002001606",
      debugFields: {
        name: "BLUE3 Masz Sensitive 3 szt",
        index: "1002001606",
        score: "1605.5939",
      },
    },
    {
      sku: "1002001654",
      debugFields: {
        name: "VENUS Masz Sensitive 3 szt",
        index: "1002001654",
        score: "1605.5939",
      },
    },
    {
      sku: "1002001655",
      debugFields: {
        name: "VENUS3 Masz Sensitive 3 szt",
        index: "1002001655",
        score: "1605.5939",
      },
    },
    {
      sku: "1002001657",
      debugFields: {
        name: "VENUS3 Masz Sensitive 6 szt",
        index: "1002001657",
        score: "1605.5939",
      },
    },
    {
      sku: "1002001887",
      debugFields: {
        name: "WELLAFLEX Lakier (3) Sensitive 250ml",
        index: "1002001887",
        score: "1605.5939",
      },
    },
  ],
  parameters: [
    {
      name: "Brand",
      type: FacetParam.FACET,
      values: [
        { name: "A10200030011", count: 1 },
        { name: "A82000250074", count: 3 },
        { name: "A82000250076", count: 7 },
        { name: "A82000250077", count: 2 },
        { name: "A82000250078", count: 2 },
        { name: "A82000250081", count: 4 },
        { name: "A82000250083", count: 7 },
        { name: "A82000280089", count: 2 },
        { name: "A82000300107", count: 15 },
        { name: "A82000310109", count: 12 },
        { name: "A82000310111", count: 4 },
        { name: "A82000320113", count: 2 },
        { name: "A82000340117", count: 4 },
        { name: "A82000350126", count: 3 },
        { name: "A82000370130", count: 7 },
        { name: "A86600380132", count: 1 },
        { name: "AZ9999999997", count: 4 },
      ],
    },
    {
      name: "EAN",
      type: FacetParam.FACET,
      values: [
        { name: "10000011085", count: 1 },
        { name: "10000012272", count: 1 },
        { name: "10000012569", count: 1 },
        { name: "10000012794", count: 1 },
        { name: "10000014293", count: 1 },
        { name: "3014260219949", count: 1 },
        { name: "4015400259480", count: 2 },
        { name: "4084500105690", count: 1 },
        { name: "4084500583115", count: 2 },
        { name: "4210201299899", count: 1 },
        { name: "5011321336124", count: 1 },
        { name: "7702018037896", count: 1 },
        { name: "7702018352883", count: 1 },
        { name: "7702018442423", count: 1 },
        { name: "7702018442447", count: 1 },
        { name: "7702018457786", count: 1 },
        { name: "7702018459803", count: 2 },
        { name: "7702018464715", count: 1 },
        { name: "7702018464876", count: 1 },
        { name: "7702018487028", count: 1 },
        { name: "7702018487158", count: 1 },
        { name: "7702018490080", count: 1 },
        { name: "7702018490134", count: 1 },
        { name: "7702018490646", count: 1 },
        { name: "7702018491544", count: 1 },
        { name: "7702018498505", count: 1 },
        { name: "7702018505678", count: 1 },
        { name: "7702018589029", count: 1 },
        { name: "7702018589043", count: 1 },
        { name: "7702018604005", count: 1 },
        { name: "7702018604104", count: 1 },
        { name: "7702018609994", count: 1 },
        { name: "7702018617098", count: 1 },
        { name: "7702018837830", count: 1 },
        { name: "8001090206930", count: 2 },
        { name: "8001090207418", count: 1 },
        { name: "8001090214324", count: 1 },
        { name: "8001090566508", count: 1 },
        { name: "8001090791313", count: 1 },
        { name: "8001090992512", count: 1 },
        { name: "8001841041391", count: 2 },
        { name: "8001841041421", count: 2 },
        { name: "8001841041483", count: 1 },
        { name: "8001841061979", count: 1 },
        { name: "8001841062556", count: 2 },
        { name: "8001841062624", count: 1 },
        { name: "8001841062754", count: 1 },
        { name: "8001841062976", count: 1 },
        { name: "8001841063188", count: 1 },
        { name: "8001841117348", count: 1 },
        { name: "8001841163666", count: 1 },
        { name: "8001841375564", count: 2 },
        { name: "8001841597881", count: 1 },
        { name: "8001841597928", count: 1 },
        { name: "8001841601694", count: 1 },
        { name: "8001841693583", count: 1 },
        { name: "8001841693804", count: 1 },
        { name: "8001841924182", count: 1 },
        { name: "8006540100448", count: 1 },
        { name: "8006540115374", count: 1 },
        { name: "8006540117125", count: 2 },
        { name: "8006540120217", count: 1 },
        { name: "8006540120255", count: 1 },
        { name: "8006540120293", count: 2 },
        { name: "8006540174531", count: 1 },
        { name: "8006540209561", count: 1 },
        { name: "8006540210802", count: 1 },
        { name: "8006540523056", count: 1 },
        { name: "8006540523087", count: 1 },
        { name: "8699568543283", count: 1 },
      ],
    },
    {
      name: "ERPCategory",
      type: FacetParam.FACET,
      values: [
        { name: "A001", count: 14 },
        { name: "A002", count: 12 },
        { name: "A003", count: 2 },
        { name: "A006", count: 2 },
        { name: "A007", count: 5 },
        { name: "A010", count: 1 },
        { name: "A013", count: 2 },
        { name: "A018", count: 2 },
        { name: "A021", count: 23 },
        { name: "A053", count: 1 },
        { name: "A998", count: 5 },
        { name: "A999", count: 11 },
      ],
    },
    {
      name: "FullName",
      type: FacetParam.FACET,
      values: [
        { name: "ALWAYS Classic Sensitive Super 9", count: 1 },
        { name: "ALWAYS Classic Sensitive Super 9 GRATIS", count: 1 },
        { name: "ALWAYS Sensitive Dis Small REBRANDING", count: 1 },
        { name: "ALWAYS Sensitive Display Big POL", count: 1 },
        { name: "ALWAYS Sensitive Display Small", count: 1 },
        { name: "ALWAYS Sensitive JFM22 Display Small", count: 1 },
        { name: "ARIEL Kapsułki Sensitive 13_ZZ", count: 1 },
        { name: "ARIEL Kapsułki Sensitive 13_ZZ GRATIS", count: 1 },
        { name: "ARIEL Kapsułki Sensitive 26_ZZ", count: 1 },
        { name: "ARIEL Płyn Sensitive 42W 2,310L", count: 1 },
        { name: "ARIEL Płyn Sensitive 62W 3,410L", count: 1 },
        { name: "ARIEL Płyn Sensitive 62W 3,410L GRATIS", count: 1 },
        { name: "Always Sensitive display BIG", count: 1 },
        { name: "BLUE3 Masz Sensitive 3 szt", count: 1 },
        { name: "BLUE3 Masz Sensitive 5+1szt", count: 1 },
        { name: "BLUE3 Masz Sensitive 6+2szt", count: 1 },
        { name: "BLUE3 Masz Sensitive 9+3", count: 1 },
        { name: "FAIRY PGP HDW Sensitive 5L", count: 1 },
        { name: "FAIRY PGP Płyn Sensitive 5L GRATIS", count: 1 },
        { name: "FUSION Żel D/G Sensitive 200ml", count: 1 },
        { name: "FUSION_5 Żel d/g Ultra Sensitive 75ml", count: 1 },
        { name: "GT Żel Pro Sensitive 200ml", count: 1 },
        { name: "GT Żel Skin Ultra Sensitive 200ml", count: 1 },
        { name: "H&amp;S Szamp Sensitive 400ml", count: 1 },
        { name: "LENOR PGP Płyn Sensitive 4,75L", count: 1 },
        { name: "LENOR Płyn Sensitive 1,36L", count: 1 },
        { name: "LENOR Płyn Sensitive 1,8L", count: 1 },
        { name: "LENOR Płyn Sensitive 1,8L GRATIS", count: 1 },
        { name: "LENOR Płyn Sensitive 930ml", count: 1 },
        { name: "LENOR Płyn Sensitive 930ml GRATIS", count: 1 },
        { name: "LENOR Płyn Sensitive XXL BP 2x1,36L", count: 1 },
        { name: "ORALB Końcówki Pulsonic Sensitive SR32-4", count: 1 },
        { name: "PAMPERS Chust Sensitive 12x52", count: 1 },
        { name: "PAMPERS Chust Sensitive 15x80", count: 1 },
        { name: "PAMPERS Chust Sensitive 3x52", count: 1 },
        { name: "PAMPERS Chust Sensitive 3x52 GRATIS", count: 1 },
        { name: "PAMPERS Chust Sensitive 4x52", count: 1 },
        { name: "PAMPERS Chust Sensitive 4x52 PlasticFree", count: 1 },
        { name: "PAMPERS Chust Sensitive 4x80", count: 1 },
        { name: "PAMPERS Chust Sensitive 52", count: 1 },
        { name: "PAMPERS Chust Sensitive 52 GRATIS", count: 1 },
        { name: "PAMPERS Chust Sensitive 52 Plastic Free", count: 1 },
        { name: "PAMPERS Chust Sensitive 6x80 New", count: 1 },
        { name: "PAMPERS Chust Sensitive 80", count: 1 },
        { name: "PAMPERS Chust Sensitive 80 GRATIS", count: 1 },
        { name: "SENSOR3 H+6 Sensitive", count: 1 },
        { name: "SENSOR3 Wkład Sensitive 8szt", count: 1 },
        { name: "SRS Pianka d/g Sensitive Cool 250 GRATIS", count: 1 },
        { name: "SRS Pianka d/g Sensitive Cool 250ml", count: 1 },
        { name: "SRS Żel d/g Sensitive 75ml", count: 1 },
        { name: "SRS Żel d/g Sensitive Cool 200ml", count: 1 },
        { name: "SRS Żel d/g Ultra Sensitive 2x200 GRATIS", count: 1 },
        { name: "VENUS Masz Delux Sensitive Rosegold H+3", count: 1 },
        { name: "VENUS Masz Sensitive 3 szt", count: 1 },
        { name: "VENUS Wkład Extra Smooth Sensitive 4szt", count: 1 },
        { name: "VENUS3 Masz Sensitive 3 szt", count: 1 },
        { name: "VENUS3 Masz Sensitive 6 szt", count: 1 },
        { name: "WELLAFLEX Lakier (3) Sensitive 250ml", count: 1 },
        { name: "ZZ_ALWAYS Classic Sensitiv Normal 20", count: 1 },
        { name: "ZZ_ARIEL Kapsułki Sensitive 26", count: 1 },
        { name: "ZZ_ARIEL Kapsułki Sensitive 33", count: 1 },
        { name: "ZZ_ARIEL Płyn Sensitive 18W 990ml", count: 1 },
        { name: "ZZ_ARIEL Płyn Sensitive 2,2L", count: 1 },
        { name: "ZZ_ARIEL Płyn Sensitive 2,64L", count: 1 },
        { name: "ZZ_ARIEL Płyn Sensitive 35W 1,925L", count: 1 },
        { name: "ZZ_FAIRY PGP Płyn Sensitive 5L", count: 1 },
        { name: "ZZ_FUSION_5 Żel d/g Ultra Sensitive200ml", count: 1 },
        { name: "ZZ_H&amp;S Szamp Sensitive 250ml", count: 1 },
        { name: "ZZ_LENOR PGP Płyn Sensitiv 4,75L", count: 1 },
        { name: "ZZ_LENOR płyn Sensitive 2,905L", count: 1 },
        { name: "ZZ_MACH3 Wkład Sensitive 4szt", count: 1 },
        { name: "ZZ_MACH3 Żel d/g Sensitive 200ml", count: 1 },
        { name: "ZZ_MACH3 Żel d/g Sensitive 2x200ml", count: 1 },
        { name: "ZZ_PAMPERS Chust Sensitive 6x80", count: 1 },
        { name: "ZZ_PAMPERS Chust Sensitive SMP 12", count: 1 },
        { name: "ZZ_SKINGUARD Masz Sensitive H + 3 Wkł", count: 1 },
        { name: "ZZ_VIZIR Kapsułki Sensitive 27", count: 1 },
        { name: "ZZ_VIZIR Proszek Sensitive 35W 2,625kg", count: 1 },
        { name: "ZZ_VIZIR Proszek Sensitive 36W 2,7kg", count: 1 },
        { name: "ZZ_VZIR Proszek Sensitive 51W 3,825kg", count: 1 },
      ],
    },
    {
      name: "ItemCategoryGroup",
      type: FacetParam.FACET,
      values: [
        { name: "NORM", count: 64 },
        { name: "ZAGR", count: 11 },
        { name: "ZAPN", count: 5 },
      ],
    },
    {
      name: "Manufacturer",
      type: FacetParam.FACET,
      values: [
        { name: "A02", count: 1 },
        { name: "A20", count: 63 },
      ],
    },
    {
      name: "MaterialID",
      type: FacetParam.FACET,
      values: [
        { name: "1001000145", count: 1 },
        { name: "1001000169", count: 1 },
        { name: "1001000171", count: 1 },
        { name: "1001000685", count: 1 },
        { name: "1001001059", count: 1 },
        { name: "1001001110", count: 1 },
        { name: "1001001200", count: 1 },
        { name: "1001001334", count: 1 },
        { name: "1001001976", count: 1 },
        { name: "1001001977", count: 1 },
        { name: "1001002015", count: 1 },
        { name: "1001002019", count: 1 },
        { name: "1001002101", count: 1 },
        { name: "1001002205", count: 1 },
        { name: "1001002631", count: 1 },
        { name: "1001002635", count: 1 },
        { name: "1001002645", count: 1 },
        { name: "1001002646", count: 1 },
        { name: "1001002647", count: 1 },
        { name: "1001002652", count: 1 },
        { name: "1001002665", count: 1 },
        { name: "1001003247", count: 1 },
        { name: "1001003525", count: 1 },
        { name: "1002000098", count: 1 },
        { name: "1002000100", count: 1 },
        { name: "1002000399", count: 1 },
        { name: "1002000441", count: 1 },
        { name: "1002000458", count: 1 },
        { name: "1002000466", count: 1 },
        { name: "1002001531", count: 1 },
        { name: "1002001532", count: 1 },
        { name: "1002001536", count: 1 },
        { name: "1002001537", count: 1 },
        { name: "1002001606", count: 1 },
        { name: "1002001654", count: 1 },
        { name: "1002001655", count: 1 },
        { name: "1002001657", count: 1 },
        { name: "1002001662", count: 1 },
        { name: "1002001887", count: 1 },
        { name: "1002002000", count: 1 },
        { name: "1002002021", count: 1 },
        { name: "1002002022", count: 1 },
        { name: "1002002204", count: 1 },
        { name: "1002003028", count: 1 },
        { name: "1002003030", count: 1 },
        { name: "1002003142", count: 1 },
        { name: "1002003153", count: 1 },
        { name: "1002003154", count: 1 },
        { name: "1002003177", count: 1 },
        { name: "1007000444", count: 1 },
        { name: "1007000445", count: 1 },
        { name: "1007000447", count: 1 },
        { name: "1007000449", count: 1 },
        { name: "1007000451", count: 1 },
        { name: "1007000453", count: 1 },
        { name: "1007000455", count: 1 },
        { name: "1007000465", count: 1 },
        { name: "1007000508", count: 1 },
        { name: "1007000510", count: 1 },
        { name: "1007000885", count: 1 },
        { name: "1007000887", count: 1 },
        { name: "1007000971", count: 1 },
        { name: "1007000972", count: 1 },
        { name: "1011002229", count: 1 },
        { name: "5001003795", count: 1 },
        { name: "5001004804", count: 1 },
        { name: "5001005807", count: 1 },
        { name: "5001005858", count: 1 },
        { name: "5001007155", count: 1 },
        { name: "5002002871", count: 1 },
        { name: "5002002956", count: 1 },
        { name: "5002002965", count: 1 },
        { name: "5002003001", count: 1 },
        { name: "5002003002", count: 1 },
        { name: "5002003007", count: 1 },
        { name: "5002003203", count: 1 },
        { name: "5002003243", count: 1 },
        { name: "5002003274", count: 1 },
        { name: "5002007003", count: 1 },
        { name: "5002007927", count: 1 },
      ],
    },
    {
      name: "MaterialType",
      type: FacetParam.FACET,
      values: [
        { name: "1001", count: 23 },
        { name: "1002", count: 26 },
        { name: "1007", count: 14 },
        { name: "1011", count: 1 },
        { name: "5001", count: 5 },
        { name: "5002", count: 11 },
      ],
    },
    {
      name: "PortalBrand",
      type: FacetParam.FACET,
      values: [
        { name: "107", count: 4 },
        { name: "111", count: 1 },
        { name: "28", count: 3 },
        { name: "35", count: 24 },
        { name: "37", count: 2 },
        { name: "4", count: 4 },
        { name: "49", count: 9 },
        { name: "69", count: 1 },
        { name: "71", count: 15 },
        { name: "8", count: 10 },
      ],
    },
    {
      name: "PortalProducer",
      type: FacetParam.FACET,
      values: [
        { name: "13", count: 1 },
        { name: "31", count: 63 },
        { name: "56", count: 3 },
      ],
    },
    {
      name: "Producer",
      type: FacetParam.FACET,
      values: [
        { name: "A02", count: 1 },
        { name: "A20", count: 78 },
        { name: "A66", count: 1 },
      ],
    },
    {
      name: "ProductName",
      type: FacetParam.FACET,
      values: [
        {
          name: "ALWAYS Classic Sensitive Normal, podpaski, 20 szt.",
          count: 1,
        },
        { name: "ALWAYS Classic Sensitive Super, podpaski, 9 szt.", count: 2 },
        { name: "ARIEL Sensitive, kapsułki do prania, 13 szt.", count: 1 },
        { name: "ARIEL Sensitive, kapsułki do prania, 26 szt.", count: 1 },
        { name: "ARIEL Sensitive, kapsułki do prania, 33 szt.", count: 1 },
        { name: "ARIEL Sensitive, płyn do prania, 1.93 l", count: 1 },
        { name: "ARIEL Sensitive, płyn do prania, 2.2 l", count: 1 },
        { name: "ARIEL Sensitive, płyn do prania, 2.31 l", count: 1 },
        { name: "ARIEL Sensitive, płyn do prania, 3.41 l", count: 2 },
        { name: "ARIEL Sensitive, płyn do prania, 990 ml", count: 1 },
        { name: "FAIRY Sensitive, płyn do mycia naczyń, 5 l", count: 2 },
        {
          name: "GILLETTE Blue 3 Sensitive, maszynki jednorazowe, 12 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Blue 3 Sensitive, maszynki jednorazowe, 3 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Blue 3 Sensitive, maszynki jednorazowe, 6 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Blue 3 Sensitive, maszynki jednorazowe, 8 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Fusion 5 Ultra Sensitive, żel do golenia, 200 ml",
          count: 1,
        },
        {
          name: "GILLETTE Fusion Ultra Sensitive, żel do golenia, 75 ml",
          count: 1,
        },
        { name: "GILLETTE Fusion, żel do golenia, 200 ml", count: 1 },
        {
          name: "GILLETTE Mach 3 Sensitive, żel do golenia, 2x200ml",
          count: 1,
        },
        {
          name: "GILLETTE Match 3 Sensitive, wkłady do maszynki, 4 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Match 3 Sensitive, żel do golenia, 200 ml",
          count: 1,
        },
        { name: "GILLETTE Pro, żel do golenia, 200 ml", count: 1 },
        { name: "GILLETTE Sensitive Cool, żel do golenia, 200 ml", count: 1 },
        { name: "GILLETTE Sensitive, żel do golenia, 75 ml", count: 1 },
        {
          name: "GILLETTE Sensor3 Sensitive 1+6, maszynka do golenia, 6 wkł.",
          count: 1,
        },
        {
          name: "GILLETTE Sensor3 Sensitive, wkłady do maszynki, 8 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Series Sensitive Cool, pianka / żel / krem, 250 ml",
          count: 1,
        },
        {
          name: "GILLETTE Series Sensitive Cool, pianka do golenia, 250 ml",
          count: 1,
        },
        {
          name: "GILLETTE Skinguard Sensitive, maszynka do golenia, 3 wkł.",
          count: 1,
        },
        { name: "GILLETTE Ultra Sensitive, żel do golenia, 200 ml", count: 1 },
        {
          name: "GILLETTE Venus 3 Sensitive, maszynki jednorazowe, 3 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Venus 3 Sensitive, maszynki jednorazowe, 6 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Venus Deluxe Smooth Sensitive, maszynka do golenia, 3 wkł.",
          count: 1,
        },
        {
          name: "GILLETTE Venus Extra Smooth Sensitive, wkłady do maszynki, 4 szt.",
          count: 1,
        },
        {
          name: "GILLETTE Venus Sensitive, maszynki jednorazowe, 3 szt.",
          count: 1,
        },
        { name: "HEAD&SHOULDERS Sensitive, szampon, 250 ml", count: 1 },
        { name: "HEAD&SHOULDERS Sensitive, szampon, 400 ml", count: 1 },
        { name: "LENOR Sensitive, płyn do płukania, 1360 ml", count: 1 },
        { name: "LENOR Sensitive, płyn do płukania, 1800 ml", count: 2 },
        { name: "LENOR Sensitive, płyn do płukania, 2.91 l", count: 1 },
        { name: "LENOR Sensitive, płyn do płukania, 2x1360 ml", count: 1 },
        { name: "LENOR Sensitive, płyn do płukania, 4750 ml", count: 1 },
        { name: "LENOR Sensitive, płyn do płukania, 930 ml", count: 2 },
        {
          name: "ORAL-B Pulsonic Sensitive SR32-4, końcówki do szczoteczek, 4 szt.",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive , chusteczki pielęgnacyjne, 4x52 szt.",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, 100% z włókien roślinnych, chusteczki pielęgnacyjne, 52 szt.",
          count: 1,
        },
        { name: "PAMPERS Sensitive, chusteczki pielęgnacyjne", count: 1 },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 12 szt.",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 12x52szt",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 15x80 szt.",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 3x52szt",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 4x52szt",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 4x80szt",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 52 szt.",
          count: 2,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 6x80 szt.",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 6x80szt",
          count: 1,
        },
        {
          name: "PAMPERS Sensitive, chusteczki pielęgnacyjne, 80 szt.",
          count: 2,
        },
        { name: "VIZIR Delikatny, proszek do prania, 2.63 kg", count: 1 },
        { name: "VIZIR Sensitive, proszek do prania, 3.83 kg", count: 1 },
        { name: "WELLAFLEX Sensitive 3, lakier do włosów, 250 ml", count: 1 },
      ],
    },
    {
      name: "Size",
      type: FacetParam.FACET,
      values: [
        { name: "A0015", count: 1 },
        { name: "A0016", count: 1 },
        { name: "A0049", count: 1 },
        { name: "A0051", count: 2 },
        { name: "A0053", count: 2 },
        { name: "A0060", count: 1 },
        { name: "A0061", count: 2 },
        { name: "A0062", count: 1 },
        { name: "A0094", count: 1 },
        { name: "A0095", count: 1 },
        { name: "A0096", count: 1 },
        { name: "A0098", count: 1 },
        { name: "A0221", count: 1 },
        { name: "A0223", count: 1 },
        { name: "A0226", count: 4 },
        { name: "A0244", count: 1 },
        { name: "A0247", count: 1 },
        { name: "A0274", count: 1 },
        { name: "A0275", count: 1 },
        { name: "A0278", count: 1 },
        { name: "A0281", count: 1 },
        { name: "A0289", count: 1 },
        { name: "A0392", count: 1 },
        { name: "A0393", count: 1 },
        { name: "A0395", count: 1 },
        { name: "A0396", count: 1 },
        { name: "A0397", count: 1 },
        { name: "A0398", count: 2 },
        { name: "A0399", count: 2 },
        { name: "A0422", count: 1 },
        { name: "A0468", count: 1 },
        { name: "A0474", count: 1 },
        { name: "A0476", count: 1 },
        { name: "A0477", count: 1 },
        { name: "A0505", count: 1 },
        { name: "A0527", count: 1 },
        { name: "A0537", count: 1 },
        { name: "A0539", count: 1 },
        { name: "A0553", count: 2 },
        { name: "A0556", count: 1 },
        { name: "A0561", count: 1 },
        { name: "A0568", count: 1 },
        { name: "A0586", count: 2 },
        { name: "A0587", count: 1 },
        { name: "A0629", count: 1 },
        { name: "A0631", count: 1 },
        { name: "A0757", count: 1 },
        { name: "A0762", count: 2 },
        { name: "A0804", count: 1 },
        { name: "A0834", count: 1 },
        { name: "A0835", count: 1 },
        { name: "A0841", count: 1 },
        { name: "AZZZZ", count: 1 },
      ],
    },
    {
      name: "cat02",
      type: FacetParam.TREE,
      values: [
        { name: "Household cleaners", count: 20, id: "0202", parentId: "02" },
        {
          name: "Cosmetics, care & hygiene",
          count: 28,
          id: "0203",
          parentId: "02",
        },
        { name: "Baby care", count: 12, id: "0204", parentId: "02" },
        { name: "Small home appliances", count: 1, id: "0205", parentId: "02" },
        { name: "Professional products", count: 3, id: "0206", parentId: "02" },
      ],
    },
    {
      name: "cat03",
      type: FacetParam.TREE,
      values: [
        {
          name: "Laundry detergents",
          count: 19,
          id: "020201",
          parentId: "0202",
        },
        { name: "Dishwashing", count: 1, id: "020202", parentId: "0202" },
        { name: "Feminine care", count: 2, id: "020301", parentId: "0203" },
        { name: "Hair care", count: 3, id: "020302", parentId: "0203" },
        {
          name: "Shaving & depilation",
          count: 22,
          id: "020304",
          parentId: "0203",
        },
        {
          name: "Cleansing & make-up removal",
          count: 1,
          id: "020307",
          parentId: "0203",
        },
        { name: "Care wipes", count: 12, id: "020402", parentId: "0204" },
        { name: "Care & beauty", count: 1, id: "020501", parentId: "0205" },
        {
          name: "Washing powders & liquids",
          count: 2,
          id: "020602",
          parentId: "0206",
        },
        {
          name: "Disinfection products",
          count: 1,
          id: "020603",
          parentId: "0206",
        },
      ],
    },
    {
      name: "dedicated_to",
      type: FacetParam.FACET,
      values: [
        { name: "man", count: 16 },
        { name: "women", count: 5 },
      ],
    },
    {
      name: "pools",
      type: FacetParam.FACET,
      values: [{ name: "Gratis", count: 5 }],
    },
    {
      name: "weight",
      type: FacetParam.FACET,
      values: [
        { name: "0,035 kg", count: 1 },
        { name: "0,039 kg", count: 1 },
        { name: "0,042 kg", count: 1 },
        { name: "0,057 kg", count: 1 },
        { name: "0,061 kg", count: 1 },
        { name: "0,064 kg", count: 1 },
        { name: "0,073 kg", count: 1 },
        { name: "0,079 kg", count: 1 },
        { name: "0,084 kg", count: 1 },
        { name: "0,094 kg", count: 1 },
        { name: "0,097 kg", count: 1 },
        { name: "0,117 kg", count: 1 },
        { name: "0,121 kg", count: 1 },
        { name: "0,13 kg", count: 1 },
        { name: "0,131 kg", count: 1 },
        { name: "0,144 kg", count: 1 },
        { name: "0,161 kg", count: 1 },
        { name: "0,258 kg", count: 2 },
        { name: "0,275 kg", count: 1 },
        { name: "0,28 kg", count: 3 },
        { name: "0,289 kg", count: 1 },
        { name: "0,32 kg", count: 1 },
        { name: "0,347 kg", count: 1 },
        { name: "0,38 kg", count: 1 },
        { name: "0,456 kg", count: 1 },
        { name: "0,531 kg", count: 1 },
        { name: "0,563 kg", count: 1 },
        { name: "0,715 kg", count: 1 },
        { name: "0,928 kg", count: 1 },
        { name: "0,976 kg", count: 1 },
        { name: "1,051 kg", count: 1 },
        { name: "1,13 kg", count: 1 },
        { name: "1,398 kg", count: 1 },
        { name: "1,413 kg", count: 1 },
        { name: "1,859 kg", count: 1 },
        { name: "2,133 kg", count: 1 },
        { name: "2,139 kg", count: 1 },
        { name: "2,427 kg", count: 1 },
        { name: "2,55 kg", count: 1 },
        { name: "2,648 kg", count: 1 },
        { name: "2,817 kg", count: 1 },
        { name: "3,022 kg", count: 1 },
        { name: "3,747 kg", count: 1 },
        { name: "3,856 kg", count: 1 },
        { name: "4,467 kg", count: 1 },
      ],
    },
  ],
};

import { TITLE_CONTENT_SETTING_PATTERN } from "../../settingsPatterns.utils";
import {
  IBoxVideoContent,
  IBoxVideoContentSettings,
  IBoxVideoDisplaySettings,
  OrientationOption,
  RatioOption,
} from "./StyledVideo.types";

export const BOX_VIDEO_DISPLAY_PATTERN = (): IBoxVideoDisplaySettings => ({
  padding: {
    top: 0,
    bottom: 0,
  },
  ratio: RatioOption.SIXTEEN_TO_NINE,
  size: { isProcents: true, procents: 100, pixels: 200 },
  orientation: OrientationOption.HORIZONTAL,
});

export const BOX_VIDEO_CONTENT_SETTINGS_PATTERN =
  (): IBoxVideoContentSettings => ({
    title: TITLE_CONTENT_SETTING_PATTERN(),
    video: {
      autoPlay: true,
      showVideoControls: true,
      loopVideo: true,
      volume: 100,
      playbackRate: 1,
      muted: true,
    },
  });

export const BOX_VIDEO_CONTENT_PATTERN = (): IBoxVideoContent => ({
  videoTitle: "",
  coverImage: "",
  videoURL: "",
  seoDescription: "",
});

export const BOX_VIDEO_PATTERN = () => ({
  ...BOX_VIDEO_DISPLAY_PATTERN(),
  ...BOX_VIDEO_CONTENT_SETTINGS_PATTERN(),
});

export const ratioToNumber = (val: string) => {
  const numbers = val.split("/");
  return +numbers[0] / +numbers[1];
};

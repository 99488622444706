import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { PortalPath } from "../../shared/portalPath/portalPath";

export interface IElement {
  boxId: number;
  paths: PortalPath[];
}

export const DynamicPageContext = createContext<{
  activePaths: IElement[];
  setActivePaths: (boxId: number, paths: PortalPath[]) => void;
}>({
  activePaths: [],
  setActivePaths: () => {},
});

export function useDynamicPageContext() {
  const context = useContext(DynamicPageContext);
  if (!context) {
    throw new Error(
      "useDynamicPageContext must be used under DynamicPageContext.Provider"
    );
  }
  return context;
}

export const DynamicPageContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [activePaths, setActivePaths] = useState<IElement[]>([]);

  const setPath = useCallback((boxId: number, paths: PortalPath[]) => {
    setActivePaths((prev) => {
      const index = prev.findIndex((el) => el.boxId === boxId);
      if (index === -1) {
        return [{ boxId, paths }, ...prev];
      }
      const newActivePaths = [...prev];
      newActivePaths.splice(index, 1);
      newActivePaths.unshift({ boxId, paths });
      return newActivePaths;
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      activePaths,
      setActivePaths: setPath,
    }),
    [activePaths, setPath]
  );

  return (
    <DynamicPageContext.Provider value={contextValue}>
      {children}
    </DynamicPageContext.Provider>
  );
};

import {
  ProductContext,
  useCheckPageContext,
} from "@ecp-boxes/helpers/pageContext";
import { useWishList } from "@ecp-boxes/helpers/useWishList";
import { PageCategoryType } from "@ecp-pageTypes";
import { searchResults } from "@ecp-redux/api/searchResults";
import {
  ISrpTransformResponseV2,
  TSrpProductType,
} from "@ecp-redux/dto/searchResults.types";
import { useMemo } from "react";
import { IAddToWishlistSetting } from "../../settingsPatterns/contentPatterns.scheme";

export type ContextType =
  | ProductContext
  | {
      type: "CONTEXT_ERROR";
    };

const useAddToWishlistLogic = (
  product?: ISrpTransformResponseV2,
  addToWishlistSetting?: IAddToWishlistSetting
) => {
  const context = useCheckPageContext<ProductContext>(PageCategoryType.PRODUCT);

  const contextProductSKU =
    context?.type === PageCategoryType.PRODUCT ? context.productSku : undefined;

  const { data: contextProducts } = searchResults.useGetPostProductsQuery(
    {
      projection: ["wishlist", "type", "conceptId", "sku"],
      skus: [contextProductSKU ?? ""],
    },
    { skip: !contextProductSKU }
  );

  const getContextProductWishlistData = useMemo(() => {
    const currentProduct = contextProducts?.products?.[0];
    if (currentProduct) {
      return {
        onWishlist: currentProduct.conceptId
          ? currentProduct.wishlist.concept
          : currentProduct.wishlist.base,
        sku: currentProduct.conceptId
          ? currentProduct.conceptId
          : currentProduct.sku,
        type: currentProduct.conceptId
          ? TSrpProductType.CONCEPT
          : TSrpProductType.PRODUCT,
      };
    }
  }, [contextProducts]);

  const getProductWishlistData = useMemo(() => {
    return {
      onWishlist: product?.conceptId
        ? product.wishlist.concept
        : product?.wishlist.base,
      sku: product?.conceptId ? product.conceptId : (product?.sku ?? ""),
      type: product?.conceptId
        ? TSrpProductType.CONCEPT
        : TSrpProductType.PRODUCT,
    };
  }, [product]);

  const { handleAddToWishList, handleRemoveFromWishList } = useWishList({
    sku: product
      ? getProductWishlistData.sku
      : (getContextProductWishlistData?.sku ?? ""),
    type: product
      ? getProductWishlistData.type
      : (getContextProductWishlistData?.type ?? TSrpProductType.PRODUCT),
  });

  const currentWishlistData = product
    ? getProductWishlistData
    : getContextProductWishlistData;

  const currentLabel = useMemo(() => {
    if (!addToWishlistSetting) return null;
    return addToWishlistSetting[
      currentWishlistData?.onWishlist
        ? "removeFromWishlistLabel"
        : "addToWishlistLabel"
    ];
  }, [addToWishlistSetting, currentWishlistData?.onWishlist]);

  const currentButtonStyle = useMemo(() => {
    if (!addToWishlistSetting) return null;
    return addToWishlistSetting[
      currentWishlistData?.onWishlist
        ? "removeFromWishlistStyle"
        : "addToWishlistStyle"
    ];
  }, [addToWishlistSetting, currentWishlistData?.onWishlist]);

  return {
    addToWishlistButton: {
      label: currentLabel,
      buttonStyle: currentButtonStyle,
    },
    contextProductSKU,
    product,
    addRemoveWishlistFunction: currentWishlistData?.onWishlist
      ? handleRemoveFromWishList
      : handleAddToWishList,
    isOnWishlist: currentWishlistData?.onWishlist,
  };
};

export default useAddToWishlistLogic;

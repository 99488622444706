import { DependencyList, useCallback, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { NotificationErrorB2B_Cart } from "@ecp-redux/dto/cartB2B.types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { usePortalDispatch, usePortalSelector } from "../store";

export type AlertCode =
  | "210"
  | "410"
  | "new_password"
  | "registration_completed"
  | "pa-001"
  | "pa-002"
  | "pa-004"
  | "pa-006"
  | "pa-008"
  | "pa-009"
  | "cons_succ"
  | "cons_error"
  | "ADDRESS_ADDED_SUCCESS"
  | "c_c_data"
  | "200"
  | "400"
  | "DELIVERY_CHANNELS_NOT_AVAILABLE"
  | "1016"
  | "1015"
  | "4004"
  | "1010"
  | "6000"
  | "account_already_exist"
  | "same_password"
  | "register_account_already_exist"
  | "edit_customer_data_validation_error"
  | "execute_captcha_error"
  | NotificationErrorB2B_Cart.CONTRACTOR_HAS_NO_CREDIT_LIMIT
  | NotificationErrorB2B_Cart.CONTRACTOR_HAS_NO_DELIVERY_DATE_AVAILABLE
  | NotificationErrorB2B_Cart.CONTRACTOR_STATUS_BLOCKED
  | NotificationErrorB2B_Cart.CONTRACTOR_STATUS_LIMITED
  | NotificationErrorB2B_Cart.CREDIT_LIMIT_EXCEEDED
  | NotificationErrorB2B_Cart.CREDIT_LIMIT_IS_NOT_ENABLED
  | NotificationErrorB2B_Cart.DELIVERY_DATE_CHANGED
  | NotificationErrorB2B_Cart.DELIVERY_DATE_NOT_AVAILABLE
  | NotificationErrorB2B_Cart.NO_CONCESSION
  | NotificationErrorB2B_Cart.PRODUCT_IS_NOT_AVAILABILITY
  | NotificationErrorB2B_Cart.PRODUCT_IS_PARTIALLY_AVAILABILITY
  | "credit_limit_exceeded"
  | "limited_account"
  | "4006";

interface Alert {
  id: string;
  code: AlertCode;
  message: string;
}

export interface AlertsState {
  alerts: Alert[];
}

const initialState: AlertsState = { alerts: [] };

const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    addAlert: (state, alert: PayloadAction<Omit<Alert, "id">>) => {
      const newAlert: Alert = { ...alert.payload, id: nanoid() };
      state.alerts.push(newAlert);
    },
    removeAlertById: (state, id: PayloadAction<string>) => {
      state.alerts = state.alerts.filter((e) => e.id !== id.payload);
    },
    removeAlertByCode: (state, code: PayloadAction<string[]>) => {
      if (!code.payload) return;
      state.alerts = state.alerts.filter(
        (e) => e.code !== code.payload?.find((c) => c === e.code)
      );
    },
  },
});

export const useAlertByCodes = (alertsCodes: string[]) => {
  const alerts = usePortalSelector((state) => state.alerts);
  const dispatch = usePortalDispatch();

  const alert = alerts.alerts.find((e) => alertsCodes.includes(e.code));

  return {
    alert,
    closeAlert: alert
      ? () => {
          dispatch(removeAlertById(alert.id));
        }
      : undefined,
  };
};

export const useAddAlert = (dependency: DependencyList = []) => {
  const dispatch = usePortalDispatch();
  const [addedAlerts, setAddedAlerts] = useState<string[]>([]);

  useEffect(() => {
    if (addedAlerts.length > 0) {
      addedAlerts.forEach((code) => {
        dispatch(removeAlertByCode([code]));
      });
      setAddedAlerts([]);
    }
  }, dependency);

  const addAlert = useCallback(
    (alert: Omit<Alert, "id">) => {
      setAddedAlerts([...addedAlerts, alert.code]);
      dispatch(alertsSlice.actions.addAlert(alert));
    },
    [dispatch, addedAlerts]
  );

  return {
    addAlert,
  };
};

export const { addAlert, removeAlertById, removeAlertByCode } =
  alertsSlice.actions;

export default alertsSlice;

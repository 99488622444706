import { createAction } from "@reduxjs/toolkit";
import { PageWithBoxesPageBuilder } from "../dto/page/pageBuilder.types";

export const setLayoutAndBoxesAction =
  createAction<PageWithBoxesPageBuilder>("setLayoutAndBoxes");

export const setFooterBoxesAction =
  createAction<PageWithBoxesPageBuilder["footerBoxes"]>("setFooterBoxes");

export const setHeaderBoxesAction =
  createAction<PageWithBoxesPageBuilder["headerBoxes"]>("setHeaderBoxes");

import { ThemeStatePageWizard } from "../../api/themeSettings";
import { defaultTableCellPattern } from "../../dto/themeSettings/GlobalTableCell.types";
import {
  AlignmentHorizontalOption,
  COLOR_TRANSPARENT,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  IThemeState,
  OutlineOptions,
  SettingPositionOptions,
  TGlobalAccordionItem,
  TGlobalDropdownItem,
  TRowWidthOption,
} from "../../dto/themeSettings/themeSettings.types";
import { nanoidNumber } from "../../helpers";

export const themeSettingsMock: IThemeState = {
  typography: [
    {
      id: "typo$$1",
      typoStyleName: "Header 1",
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 36,
      lineHeight: 50,
      letterSpacing: 0,
      inheritedFromBasic: false,
    },
    {
      id: "typo$$2",
      typoStyleName: "Header 2",
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight400,
      size: 20,
      lineHeight: 34,
      letterSpacing: 0,
      inheritedFromBasic: false,
    },
    {
      id: "typo$$3",
      typoStyleName: "Header 3",
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 20,
      lineHeight: 34,
      letterSpacing: 0,
      inheritedFromBasic: false,
    },
    {
      id: "typo$$4",
      typoStyleName: "Header 4",
      family: FontFamilyOptions.NUNITO,
      weight: FontWeightOptionsLongListOfWeights.Weight400,
      size: 20,
      lineHeight: 26,
      letterSpacing: 0,
      inheritedFromBasic: false,
    },
    {
      id: "typo$$5",
      typoStyleName: "Paragraph 1",
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 16,
      lineHeight: 25,
      letterSpacing: 0,
      inheritedFromBasic: false,
    },
    {
      id: "typo$$6",
      typoStyleName: "Paragraph 2",
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 14,
      lineHeight: 24,
      letterSpacing: 0,
      inheritedFromBasic: false,
    },
    {
      id: "typo$$7",
      typoStyleName: "Paragraph 3",
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 12,
      lineHeight: 20,
      letterSpacing: 0,
      inheritedFromBasic: false,
    },
    {
      id: "typo$$8",
      typoStyleName: "Paragraph 4",
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight600,
      size: 14,
      lineHeight: 24,
      letterSpacing: 0,
      inheritedFromBasic: false,
    },
    {
      id: "typo$$9",
      typoStyleName: "Old Price",
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 16,
      lineHeight: 20,
      letterSpacing: 0,
      inheritedFromBasic: false,
    },
    {
      id: "typo$$10",
      typoStyleName: "Link",
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 14,
      lineHeight: 20,
      letterSpacing: 0,
      inheritedFromBasic: false,
    },
  ],
  colorPalette: [
    {
      id: "color$$1",
      name: "1000",
      colorHex: "#000000",
    },
    {
      id: "color$$2",
      name: "700",
      colorHex: "#666972",
    },
    {
      id: "color$$3",
      name: "600",
      colorHex: "#81889C",
    },
    {
      id: "color$$4",
      name: "500",
      colorHex: "#9098AE",
    },
    {
      id: "color$$5",
      name: "300",
      colorHex: "#CCCCCC",
    },
    {
      id: "color$$6",
      name: "200",
      colorHex: "#EEEEEE",
    },
    {
      id: "color$$7",
      name: "100",
      colorHex: "#F4F5F7",
    },
    {
      id: "color$$8",
      name: "50",
      colorHex: "#F9FAFB",
    },
    {
      id: "color$$9",
      name: "White",
      colorHex: "#FFFFFF",
    },
    {
      id: "color$$10",
      name: "Red",
      colorHex: "#EB5757",
    },
    {
      id: "color$$11",
      name: "None",
      colorHex: COLOR_TRANSPARENT,
    },
  ],
  globalObjects: {
    buttons: [
      {
        id: "button$$1",
        buttonStyleName: "Main Button",
        horizontalPadding: 35,
        verticalPadding: 13,
        border: {
          radius: 0,
          radiusV2: {
            topLeft: 0,
            topRight: 0,
            bottomLeft: 0,
            bottomRight: 0,
          },
          width: 0,
          widthV2: { left: 0, right: 0, top: 0, bottom: 0 },
          color: {
            id: "color$$11",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$11",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$11",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$11",
            custom: "#000000",
          },
        },
        font: {
          id: "typo$$6",
          custom: {
            family: FontFamilyOptions.ROBOTO,
            weight: FontWeightOptionsLongListOfWeights.Weight300,
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        background: {
          color: {
            id: "color$$1",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$7",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$1",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$5",
            custom: "#000000",
          },
        },
        text: {
          color: {
            id: "color$$9",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$1",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$1",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$9",
            custom: "#000000",
          },
        },
        icon: {
          iconUrl: "",
          position: SettingPositionOptions.LEFT,
          size: 0,
          spacing: 0,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$1",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$1",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$1",
            custom: "#000000",
          },
        },
      },
      {
        id: "button$$2",
        buttonStyleName: "Second Button",
        horizontalPadding: 35,
        verticalPadding: 13,
        border: {
          radius: 0,
          radiusV2: {
            topLeft: 0,
            topRight: 0,
            bottomLeft: 0,
            bottomRight: 0,
          },
          width: 2,
          widthV2: { left: 2, right: 2, top: 2, bottom: 2 },
          color: {
            id: "color$$1",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$1",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$1",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$5",
            custom: "#000000",
          },
        },
        font: {
          id: "typo$$6",
          custom: {
            family: FontFamilyOptions.ROBOTO,
            weight: FontWeightOptionsLongListOfWeights.Weight300,
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        background: {
          color: {
            id: "color$$9",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$7",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$9",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$9",
            custom: "#000000",
          },
        },
        text: {
          color: {
            id: "color$$1",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$1",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$1",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$5",
            custom: "#000000",
          },
        },
        icon: {
          iconUrl: "",
          position: SettingPositionOptions.LEFT,
          size: 0,
          spacing: 0,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$1",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$1",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$1",
            custom: "#000000",
          },
        },
      },
    ],
    inputs: [
      {
        id: "input$$1",
        inputStyleName: "Input1",
        width: 100,
        height: 40,
        border: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$3",
            custom: "#000000",
          },
        },
        hoverBorder: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        disabledBorder: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        errorBorder: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        activeBorder: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        background: {
          color: {
            id: "color$$9",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$9",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$9",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$9",
            custom: "#000000",
          },
        },
        font: {
          input: {
            id: "typo$$1",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          label: {
            id: "typo$$1",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          placeholder: {
            id: "typo$$6",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          error: {
            id: "typo$$6",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
        },
        text: {
          color: {
            id: "color$$5",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$5",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$5",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$5",
            custom: "#000000",
          },
          errorColor: {
            id: "color$$5",
            custom: "#000000",
          },
          placeholderColor: {
            id: "color$$5",
            custom: "#000000",
          },
          labelColor: {
            id: "color$$5",
            custom: "#000000",
          },
        },
      },
      {
        id: "input$$2",
        inputStyleName: "Input2",
        width: 250,
        height: 40,
        border: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$3",
            custom: "#000000",
          },
        },
        hoverBorder: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        disabledBorder: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        errorBorder: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        activeBorder: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        background: {
          color: {
            id: "color$$4",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$4",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$4",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$4",
            custom: "#000000",
          },
        },
        font: {
          input: {
            id: "typo$$1",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          label: {
            id: "typo$$1",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          placeholder: {
            id: "typo$$3",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          error: {
            id: "typo$$6",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
        },
        text: {
          color: {
            id: "color$$3",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$1",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$1",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$1",
            custom: "#000000",
          },
          errorColor: {
            id: "color$$1",
            custom: "#000000",
          },
          placeholderColor: {
            id: "color$$3",
            custom: "#000000",
          },
          labelColor: {
            id: "color$$1",
            custom: "#000000",
          },
        },
      },
      {
        id: "input$$3",
        inputStyleName: "Input3",
        width: 250,
        height: 40,
        border: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$3",
            custom: "#000000",
          },
        },
        hoverBorder: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        disabledBorder: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        errorBorder: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        activeBorder: {
          radius: 20,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          style: OutlineOptions.SOLID,
          color: {
            id: "color$$1",
            custom: "#000000",
          },
        },
        background: {
          color: {
            id: "color$$4",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$4",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$4",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$4",
            custom: "#000000",
          },
        },
        font: {
          input: {
            id: "typo$$1",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          label: {
            id: "typo$$1",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          placeholder: {
            id: "typo$$3",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          error: {
            id: "typo$$6",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
        },
        text: {
          color: {
            id: "color$$3",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$1",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$1",
            custom: "#000000",
          },
          disabledColor: {
            id: "color$$1",
            custom: "#000000",
          },
          errorColor: {
            id: "color$$1",
            custom: "#000000",
          },
          placeholderColor: {
            id: "color$$3",
            custom: "#000000",
          },
          labelColor: {
            id: "color$$1",
            custom: "#000000",
          },
        },
      },
    ],
    links: [
      {
        id: "link$$1",
        linkStyleName: "Link",
        text: {
          color: {
            id: "color$$1",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$5",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$1",
            custom: "#000000",
          },
        },
      },
      {
        id: "link$$2",
        linkStyleName: "Link2",
        text: {
          color: {
            id: "color$$2",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$3",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$2",
            custom: "#000000",
          },
        },
      },
      {
        id: "link$$3",
        linkStyleName: "Link3",
        text: {
          color: {
            id: "color$$2",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$1",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$2",
            custom: "#000000",
          },
        },
      },
    ],
    tooltips: [
      {
        id: "tooltip$$1",
        tooltipStyleName: "Tooltip 1",
        background: {
          color: {
            id: "color$$4",
            custom: "#000000",
          },
        },
        text: {
          color: {
            id: "color$$2",
            custom: "#000000",
          },
        },
        font: {
          id: "typo$$3",
          custom: {
            family: FontFamilyOptions.ROBOTO,
            weight: FontWeightOptionsLongListOfWeights.Weight300,
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        arrow: true,
        verticalPadding: 10,
        horizontalPadding: 10,
        maximumWidth: 10,
        borderRadius: 10,
      },
      {
        id: "tooltip$$2",
        tooltipStyleName: "Tooltip 2",
        background: {
          color: {
            id: "color$$4",
            custom: "#000000",
          },
        },
        text: {
          color: {
            id: "color$$2",
            custom: "#000000",
          },
        },
        font: {
          id: "typo$$3",
          custom: {
            family: FontFamilyOptions.ROBOTO,
            weight: FontWeightOptionsLongListOfWeights.Weight300,
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        arrow: true,
        verticalPadding: 10,
        horizontalPadding: 10,
        maximumWidth: 10,
        borderRadius: 10,
      },
      {
        id: "tooltip$$3",
        tooltipStyleName: "Tooltip 3",
        background: {
          color: {
            id: "color$$4",
            custom: "#000000",
          },
        },
        text: {
          color: {
            id: "color$$2",
            custom: "#000000",
          },
        },
        font: {
          id: "typo$$3",
          custom: {
            family: FontFamilyOptions.ROBOTO,
            weight: FontWeightOptionsLongListOfWeights.Weight300,
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        arrow: true,
        verticalPadding: 10,
        horizontalPadding: 10,
        maximumWidth: 10,
        borderRadius: 10,
      },
    ],
    snackbars: [
      {
        id: `snackbar$$${1}`,
        snackbarStyleName: "SUCCESS MOCK SNACKBAR",
        timeToDisplay: 5,
        verticalPadding: 20,
        horizontalPadding: 20,
        maxWidth: 376,
        borderStyle: OutlineOptions.NONE,
        borderRadius: 0,
        borderThickness: 0,
        textStyle: {
          id: "typo$$1",
          custom: {
            family: FontFamilyOptions.ROBOTO,
            weight: FontWeightOptionsLongListOfWeights.Weight300,
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        textColor: { id: "color$$1", custom: "#000000" },
        backgroundColor: { id: "color$$1", custom: "#000000" },
        borderColor: { id: "color$$1", custom: "#000000" },
        iconClose: true,
      },
      {
        id: `snackbar$$${2}`,
        snackbarStyleName: "ERROR MOCK SNACKBAR",
        timeToDisplay: 5,
        verticalPadding: 20,
        horizontalPadding: 22,
        maxWidth: 376,
        borderStyle: OutlineOptions.NONE,
        borderRadius: 0,
        borderThickness: 0,
        textStyle: {
          id: "typo$$2",
          custom: {
            family: FontFamilyOptions.ROBOTO,
            weight: FontWeightOptionsLongListOfWeights.Weight300,
            size: 24,
            lineHeight: 10,
            letterSpacing: 1,
          },
        },
        textColor: { id: "color$$1", custom: "#000000" },
        backgroundColor: { id: "color$$1", custom: "#000000" },
        borderColor: { id: "color$$1", custom: "#000000" },
        iconClose: true,
      },
    ],
    options: [
      {
        id: "option$$1",
        optionName: "New Option Style",
        button: "button$$1",
      },
      {
        id: "option$$2",
        optionName: "New Option Style",
        button: "button$$2",
      },
    ],
    accordions: [defaultAccordionPattern(1)],
    dropdowns: [defaultDropdownPattern(1)],
    tableCells: [defaultTableCellPattern(1)],
  },
  stylePages: {
    pages: {
      page: {
        background: {
          color: {
            id: "color$$10",
            custom: "#000000",
          },
        },
      },
      modal: {
        background: {
          color: {
            id: "color$$9",
            custom: "#000000",
          },
        },
      },
      maxWidth: 1444,
      headerFooter: {
        stickyHeader: false,
        fullWidthBgcHeader: true,
        fullWidthBgcFooter: true,
      },
    },
    section: {
      width: {
        selectedOption: TRowWidthOption.MARGIN,
        percent: 100,
        margin: {
          left: 0,
          right: 0,
        },
      },
      background: {
        color: {
          id: "color$$2",
          custom: "#000000",
        },
      },
      border: {
        style: OutlineOptions.SOLID,
        width: { left: 0, right: 0, top: 0, bottom: 0 },
        color: { id: "color$$1", custom: "#000000" },
        radius: 0,
      },
      spacingBetweenSections: 46,
      spacingBetweenSlots: 26,
    },
    slot: {
      background: {
        color: {
          id: "color$$11",
          custom: "#000000",
        },
      },
      border: {
        style: OutlineOptions.DASHED,
        width: { left: 0, right: 0, top: 0, bottom: 0 },
        color: { id: "color$$1", custom: "#000000" },
        radius: 0,
      },
    },
  },
  advanceSettings: {
    messages: {
      taxShort: "%",
      currencyShort: "zł",
      delivery_HOURS_24: "Dostawa w 24h",
      delivery_HOURS_48: "Dostawa w 48h",
      delivery_HOURS_72: "Dostawa w 72h",
      delivery_DAYS_4_5: "Dostawa w ciągu 4-5 dni",
      delivery_DAYS_6_8: "Dostawa w ciągu 6-8 dni",
      delivery_WEEKS_2: "Dostawa w ciągu 2 tygodni",
      delivery_NOT_AVAILABLE: "Termin dostawy nieznany",
      delivery_unknown: "Brak informacji o dostawie",
    },
    settings: {
      backgroundUrl: "https://dev-minio-ecp.nd0.pl/ecp-media-cms/14c4042k.jpg",
      snackbarApiError: "snackbar$$1",
      searchRedirectUrl: "",
      googleMapApiKey: "",
      googleRecaptchaSiteKey: "",
      googleRecaptchaSecretKey: "",
      unsubscribNewsletterSuccesUrl: "/unsubscrib_newsletter_succes",
      dynamicBoxImagePlug: "",
      loginUrl: "/login",
      resetPasswordUrl: "/reset_password",
      faviconURL: "",
      redirectForEmptyResults: "/",
      clipboard_icon_on: "",
      clipboard_icon_off: "",
      ribbons_label_typography: "typo$$1",
      ribbons_label_color: "color$$1",
      new_ribbon_background_color: "color$$2",
      bestseller_ribbon_background_color: "color$$3",
      promotion_ribbon_background_color: "color$$3",
      inpost_geowidget_token: "token-for-geo",
      collect_events: "true",
    },
    customCss: "",
    customJs: "",
    trackingScript: "",
  },
  systemPages: {
    page404: { id: 0 },
    page500: { id: 1 },
    logOutPage: {
      id: 2,
      technicalLogOut: "/log_out_tech",
    },
    orderSummaryPage: {
      id: 3,
      orderSummaryDetails: "/autopay-payment-completed",
    },
  },
};

export const themeSettingsPageWizardMock: ThemeStatePageWizard = {
  basic: {
    typography: [
      {
        id: "typo$$1",
        typoStyleName: "Header 1",
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 36,
        lineHeight: 50,
        letterSpacing: 0,
        inheritedFromBasic: false,
      },
      {
        id: "typo$$2",
        typoStyleName: "Header 2",
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight400,
        size: 20,
        lineHeight: 34,
        letterSpacing: 0,
        inheritedFromBasic: false,
      },
      {
        id: "typo$$3",
        typoStyleName: "Header 3",
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 20,
        lineHeight: 34,
        letterSpacing: 0,
        inheritedFromBasic: false,
      },
      {
        id: "typo$$4",
        typoStyleName: "Header 4",
        family: FontFamilyOptions.NUNITO,
        weight: FontWeightOptionsLongListOfWeights.Weight400,
        size: 20,
        lineHeight: 26,
        letterSpacing: 0,
        inheritedFromBasic: false,
      },
      {
        id: "typo$$5",
        typoStyleName: "Paragraph 1",
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 16,
        lineHeight: 25,
        letterSpacing: 0,
        inheritedFromBasic: false,
      },
      {
        id: "typo$$6",
        typoStyleName: "Paragraph 2",
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 14,
        lineHeight: 24,
        letterSpacing: 0,
        inheritedFromBasic: false,
      },
      {
        id: "typo$$7",
        typoStyleName: "Paragraph 3",
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 12,
        lineHeight: 20,
        letterSpacing: 0,
        inheritedFromBasic: false,
      },
      {
        id: "typo$$8",
        typoStyleName: "Paragraph 4",
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight600,
        size: 14,
        lineHeight: 24,
        letterSpacing: 0,
        inheritedFromBasic: false,
      },
      {
        id: "typo$$9",
        typoStyleName: "Old Price",
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 16,
        lineHeight: 20,
        letterSpacing: 0,
        inheritedFromBasic: false,
      },
      {
        id: "typo$$10",
        typoStyleName: "Link",
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 14,
        lineHeight: 20,
        letterSpacing: 0,
        inheritedFromBasic: false,
      },
    ],
    colorPalette: [
      {
        id: "color$$1",
        name: "1000",
        colorHex: "#000000",
      },
      {
        id: "color$$2",
        name: "700",
        colorHex: "#666972",
      },
      {
        id: "color$$3",
        name: "600",
        colorHex: "#81889C",
      },
      {
        id: "color$$4",
        name: "500",
        colorHex: "#9098AE",
      },
      {
        id: "color$$5",
        name: "300",
        colorHex: "#CCCCCC",
      },
      {
        id: "color$$6",
        name: "200",
        colorHex: "#EEEEEE",
      },
      {
        id: "color$$7",
        name: "100",
        colorHex: "#F4F5F7",
      },
      {
        id: "color$$8",
        name: "50",
        colorHex: "#F9FAFB",
      },
      {
        id: "color$$9",
        name: "White",
        colorHex: "#FFFFFF",
      },
      {
        id: "color$$10",
        name: "Red",
        colorHex: "#EB5757",
      },
      {
        id: "color$$11",
        name: "None",
        colorHex: COLOR_TRANSPARENT,
      },
    ],
    globalObjects: {
      buttons: [
        {
          id: "button$$1",
          buttonStyleName: "Main Button",
          horizontalPadding: 35,
          verticalPadding: 13,
          border: {
            radius: 0,
            radiusV2: {
              topLeft: 0,
              topRight: 0,
              bottomLeft: 0,
              bottomRight: 0,
            },
            width: 0,
            widthV2: { left: 0, right: 0, top: 0, bottom: 0 },
            color: {
              id: "color$$11",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$11",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$11",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$11",
              custom: "#000000",
            },
          },
          font: {
            id: "typo$$6",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          background: {
            color: {
              id: "color$$1",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$7",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$1",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$5",
              custom: "#000000",
            },
          },
          text: {
            color: {
              id: "color$$9",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$1",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$1",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$9",
              custom: "#000000",
            },
          },
          icon: {
            iconUrl: "",
            position: SettingPositionOptions.LEFT,
            size: 0,
            spacing: 0,
            color: {
              id: "color$$9",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$1",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$1",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$1",
              custom: "#000000",
            },
          },
        },
        {
          id: "button$$2",
          buttonStyleName: "Second Button",
          horizontalPadding: 35,
          verticalPadding: 13,
          border: {
            radius: 0,
            radiusV2: {
              topLeft: 0,
              topRight: 0,
              bottomLeft: 0,
              bottomRight: 0,
            },
            width: 0,
            widthV2: { left: 2, right: 2, top: 2, bottom: 2 },
            color: {
              id: "color$$1",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$1",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$1",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$5",
              custom: "#000000",
            },
          },
          font: {
            id: "typo$$6",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          background: {
            color: {
              id: "color$$9",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$7",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$9",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$9",
              custom: "#000000",
            },
          },
          text: {
            color: {
              id: "color$$1",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$1",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$1",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$5",
              custom: "#000000",
            },
          },
          icon: {
            iconUrl: "",
            position: SettingPositionOptions.LEFT,
            size: 0,
            spacing: 0,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$1",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$1",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$1",
              custom: "#000000",
            },
          },
        },
      ],
      inputs: [
        {
          id: "input$$1",
          inputStyleName: "Input1",
          width: 100,
          height: 40,
          border: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$3",
              custom: "#000000",
            },
          },
          hoverBorder: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
          },
          disabledBorder: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
          },
          errorBorder: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
          },
          activeBorder: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
          },
          background: {
            color: {
              id: "color$$9",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$9",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$9",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$9",
              custom: "#000000",
            },
          },
          font: {
            input: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            label: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            placeholder: {
              id: "typo$$6",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            error: {
              id: "typo$$6",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
          },
          text: {
            color: {
              id: "color$$5",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$5",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$5",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$5",
              custom: "#000000",
            },
            errorColor: {
              id: "color$$5",
              custom: "#000000",
            },
            placeholderColor: {
              id: "color$$5",
              custom: "#000000",
            },
            labelColor: {
              id: "color$$5",
              custom: "#000000",
            },
          },
        },
        {
          id: "input$$2",
          inputStyleName: "Input2",
          width: 250,
          height: 40,
          border: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$3",
              custom: "#000000",
            },
          },
          hoverBorder: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
          },
          disabledBorder: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
          },
          errorBorder: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
          },
          activeBorder: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
          },
          background: {
            color: {
              id: "color$$4",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$4",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$4",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$4",
              custom: "#000000",
            },
          },
          font: {
            input: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            label: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            placeholder: {
              id: "typo$$3",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            error: {
              id: "typo$$6",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
          },
          text: {
            color: {
              id: "color$$3",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$1",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$1",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$1",
              custom: "#000000",
            },
            errorColor: {
              id: "color$$1",
              custom: "#000000",
            },
            placeholderColor: {
              id: "color$$3",
              custom: "#000000",
            },
            labelColor: {
              id: "color$$1",
              custom: "#000000",
            },
          },
        },
        {
          id: "input$$3",
          inputStyleName: "Input3",
          width: 250,
          height: 40,
          border: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$3",
              custom: "#000000",
            },
          },
          hoverBorder: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
          },
          disabledBorder: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
          },
          errorBorder: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
          },
          activeBorder: {
            radius: 20,
            width: { left: 0, right: 0, top: 0, bottom: 0 },
            style: OutlineOptions.SOLID,
            color: {
              id: "color$$1",
              custom: "#000000",
            },
          },
          background: {
            color: {
              id: "color$$4",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$4",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$4",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$4",
              custom: "#000000",
            },
          },
          font: {
            input: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            label: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            placeholder: {
              id: "typo$$3",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            error: {
              id: "typo$$6",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
          },
          text: {
            color: {
              id: "color$$3",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$1",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$1",
              custom: "#000000",
            },
            disabledColor: {
              id: "color$$1",
              custom: "#000000",
            },
            errorColor: {
              id: "color$$1",
              custom: "#000000",
            },
            placeholderColor: {
              id: "color$$3",
              custom: "#000000",
            },
            labelColor: {
              id: "color$$1",
              custom: "#000000",
            },
          },
        },
      ],
      links: [
        {
          id: "link$$1",
          linkStyleName: "Link",
          text: {
            color: {
              id: "color$$1",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$5",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$1",
              custom: "#000000",
            },
          },
        },
        {
          id: "link$$2",
          linkStyleName: "Link2",
          text: {
            color: {
              id: "color$$2",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$3",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$2",
              custom: "#000000",
            },
          },
        },
        {
          id: "link$$3",
          linkStyleName: "Link3",
          text: {
            color: {
              id: "color$$2",
              custom: "#000000",
            },
            hoverColor: {
              id: "color$$1",
              custom: "#000000",
            },
            activeColor: {
              id: "color$$2",
              custom: "#000000",
            },
          },
        },
      ],
      tooltips: [
        {
          id: "tooltip$$1",
          tooltipStyleName: "Tooltip 1",
          background: {
            color: {
              id: "color$$4",
              custom: "#000000",
            },
          },
          text: {
            color: {
              id: "color$$2",
              custom: "#000000",
            },
          },
          font: {
            id: "typo$$3",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          arrow: true,
          verticalPadding: 10,
          horizontalPadding: 10,
          maximumWidth: 10,
          borderRadius: 10,
        },
        {
          id: "tooltip$$2",
          tooltipStyleName: "Tooltip 2",
          background: {
            color: {
              id: "color$$4",
              custom: "#000000",
            },
          },
          text: {
            color: {
              id: "color$$2",
              custom: "#000000",
            },
          },
          font: {
            id: "typo$$3",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          arrow: true,
          verticalPadding: 10,
          horizontalPadding: 10,
          maximumWidth: 10,
          borderRadius: 10,
        },

        {
          id: "tooltip$$3",
          tooltipStyleName: "Tooltip 3",
          background: {
            color: {
              id: "color$$4",
              custom: "#000000",
            },
          },
          text: {
            color: {
              id: "color$$2",
              custom: "#000000",
            },
          },
          font: {
            id: "typo$$3",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          arrow: true,
          verticalPadding: 10,
          horizontalPadding: 10,
          maximumWidth: 10,
          borderRadius: 10,
        },
      ],
      snackbars: [
        {
          id: `snackbar$$${1}`,
          snackbarStyleName: "SUCCESS MOCK SNACKBAR",
          timeToDisplay: 5,
          verticalPadding: 20,
          horizontalPadding: 20,
          maxWidth: 376,
          borderStyle: OutlineOptions.NONE,
          borderRadius: 0,
          borderThickness: 0,
          textStyle: {
            id: "typo$$1",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          textColor: { id: "color$$1", custom: "#000000" },
          backgroundColor: { id: "color$$1", custom: "#000000" },
          borderColor: { id: "color$$1", custom: "#000000" },
          iconClose: true,
        },
        {
          id: `snackbar$$${2}`,
          snackbarStyleName: "ERROR MOCK SNACKBAR",
          timeToDisplay: 5,
          verticalPadding: 20,
          horizontalPadding: 22,
          maxWidth: 376,
          borderStyle: OutlineOptions.NONE,
          borderRadius: 0,
          borderThickness: 0,
          textStyle: {
            id: "typo$$2",
            custom: {
              family: FontFamilyOptions.ROBOTO,
              weight: FontWeightOptionsLongListOfWeights.Weight300,
              size: 24,
              lineHeight: 10,
              letterSpacing: 1,
            },
          },
          textColor: { id: "color$$1", custom: "#000000" },
          backgroundColor: { id: "color$$1", custom: "#000000" },
          borderColor: { id: "color$$1", custom: "#000000" },
          iconClose: true,
        },
      ],
      options: [
        {
          id: "option$$1",
          optionName: "New Option Style",
          button: "button$$1",
        },
        {
          id: "option$$2",
          optionName: "New Option Style",
          button: "button$$2",
        },
      ],
      accordions: [defaultAccordionPattern(1), defaultAccordionPattern(2)],
      dropdowns: [defaultDropdownPattern(1), defaultDropdownPattern(2)],
      tableCells: [defaultTableCellPattern(1)],
    },
    stylePages: {
      pages: {
        page: {
          background: {
            color: {
              id: "color$$10",
              custom: "#000000",
            },
          },
        },
        modal: {
          background: {
            color: {
              id: "color$$9",
              custom: "#000000",
            },
          },
        },
        maxWidth: 1444,
        headerFooter: {
          stickyHeader: false,
          fullWidthBgcHeader: true,
          fullWidthBgcFooter: true,
        },
      },
      section: {
        width: {
          selectedOption: TRowWidthOption.MARGIN,
          percent: 100,
          margin: {
            left: 0,
            right: 0,
          },
        },
        background: {
          color: {
            id: "color$$2",
            custom: "#000000",
          },
        },
        border: {
          style: OutlineOptions.SOLID,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
          radius: 0,
        },
        spacingBetweenSections: 46,
        spacingBetweenSlots: 26,
      },
      slot: {
        background: {
          color: {
            id: "color$$11",
            custom: "#000000",
          },
        },
        border: {
          style: OutlineOptions.DASHED,
          width: { left: 0, right: 0, top: 0, bottom: 0 },
          color: { id: "color$$1", custom: "#000000" },
          radius: 0,
        },
      },
    },
    advanceSettings: {
      messages: {
        taxShort: "%",
        currencyShort: "zł",
        delivery_HOURS_24: "Dostawa w 24h",
        delivery_HOURS_48: "Dostawa w 48h",
        delivery_HOURS_72: "Dostawa w 72h",
        delivery_DAYS_4_5: "Dostawa w ciągu 4-5 dni",
        delivery_DAYS_6_8: "Dostawa w ciągu 6-8 dni",
        delivery_WEEKS_2: "Dostawa w ciągu 2 tygodni",
        delivery_NOT_AVAILABLE: "Termin dostawy nieznany",
        delivery_unknown: "Brak informacji o dostawie",
      },
      settings: {
        inpost_geowidget_token: "",
        backgroundUrl:
          "https://dev-minio-ecp.nd0.pl/ecp-media-cms/14c4042k.jpg",
        snackbarApiError: "snackbar$$1",
        searchRedirectUrl: "",
        googleMapApiKey: "",
        googleRecaptchaSiteKey: "",
        googleRecaptchaSecretKey: "",
        unsubscribNewsletterSuccesUrl: "/unsubscrib_newsletter_succes",
        dynamicBoxImagePlug: "",
        loginUrl: "/login",
        resetPasswordUrl: "/reset_password",
        faviconURL: "",
        redirectForEmptyResults: "/",
        clipboard_icon_on: "",
        clipboard_icon_off: "",
        ribbons_label_typography: "typo$$1",
        ribbons_label_color: "color$$1",
        new_ribbon_background_color: "color$$2",
        bestseller_ribbon_background_color: "color$$3",
        promotion_ribbon_background_color: "color$$3",
        collect_events: "true",
      },
      customCss: "",
      customJs: "",
      trackingScript: "",
    },
    systemPages: {
      page404: { id: 0 },
      page500: { id: 1 },
      logOutPage: {
        id: 2,
        technicalLogOut: "/log_out_tech",
      },
      orderSummaryPage: {
        id: 3,
        orderSummaryDetails: "/autopay-payment-completed",
      },
    },
  },
  mobile: null,
};

export function defaultAccordionPattern(id?: number): TGlobalAccordionItem {
  return {
    id: `accordion$$${id ? id : nanoidNumber()}`,
    accordionName: "Accordion",
    accordionAlignment: AlignmentHorizontalOption.CENTER,
    accordionPadding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    accordionWidth: 100,
    accordionTypography: {
      id: "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 24,
        lineHeight: 10,
        letterSpacing: 1,
      },
    },
    accordionBackground: {
      color: { id: "color$$1", custom: "#000000" },
      activeColor: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
      disabledColor: { id: "color$$1", custom: "#000000" },
    },
    accordionText: {
      color: { id: "color$$1", custom: "#000000" },
      activeColor: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
      disabledColor: { id: "color$$1", custom: "#000000" },
    },
    accordionBorder: {
      color: { id: "color$$1", custom: "#000000" },
      radius: 0,
      width: { top: 0, bottom: 0, left: 0, right: 0 },
      style: OutlineOptions.SOLID,
    },
    accordionHoverBorder: {
      color: { id: "color$$1", custom: "#000000" },
      radius: 0,
      width: { top: 0, bottom: 0, left: 0, right: 0 },
      style: OutlineOptions.SOLID,
    },
    accordionActiveBorder: {
      color: { id: "color$$1", custom: "#000000" },
      radius: 0,
      width: { top: 0, bottom: 0, left: 0, right: 0 },
      style: OutlineOptions.SOLID,
    },
    accordionDisabledBorder: {
      color: { id: "color$$1", custom: "#000000" },
      radius: 0,
      width: { top: 0, bottom: 0, left: 0, right: 0 },
      style: OutlineOptions.SOLID,
    },
    openIcon: {
      size: 24,
      position: SettingPositionOptions.RIGHT,
      spacing: 0,
      iconUrl: "",
    },
    closeIcon: {
      size: 24,
      position: SettingPositionOptions.RIGHT,
      spacing: 0,
      iconUrl: "",
    },
  };
}
export function defaultDropdownPattern(id?: number): TGlobalDropdownItem {
  return {
    dropdownStyleName: "Select Dropdown Style",
    id: `dropdown$$${id ? id : nanoidNumber()}`,
    dropdownWidth: 100,
    dropdownHeight: 40,
    dropdownAlign: AlignmentHorizontalOption.LEFT,
    dropdownBackground: {
      color: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
      disabledColor: { id: "color$$1", custom: "#000000" },
    },
    dropdownBorder: {
      color: { id: "color$$1", custom: "#000000" },
      radius: 0,
      width: { top: 0, bottom: 0, left: 0, right: 0 },
      style: OutlineOptions.NONE,
    },

    selectedItemTypography: {
      id: "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 24,
        lineHeight: 10,
        letterSpacing: 1,
      },
    },
    selectedItemLink: {
      id: "link$$1",
      custom: {
        text: {
          color: {
            id: "color$$1",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$1",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$1",
            custom: "#000000",
          },
        },
      },
    },
    selectedItemAlignment: AlignmentHorizontalOption.LEFT,
    selectedItemDisabledTypography: {
      id: "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 24,
        lineHeight: 10,
        letterSpacing: 1,
      },
    },
    selectedItemDisabledColor: { id: "color$$1", custom: "#000000" },

    openOnHover: false,
    openIcon: {
      size: 15,
      position: SettingPositionOptions.RIGHT,
      spacing: 15,
      iconUrl: "",
    },
    closeIcon: {
      size: 15,
      position: SettingPositionOptions.RIGHT,
      spacing: 15,
      iconUrl: "",
    },

    errorMessageTypography: {
      id: "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 24,
        lineHeight: 10,
        letterSpacing: 1,
      },
    },
    errorMessageColor: { id: "color$$1", custom: "#000000" },

    columnWidth: 100,
    columnHeight: 200,
    columnAlignment: AlignmentHorizontalOption.CENTER,
    columnShadow: false,
    columnShadowColor: { id: "color$$1", custom: "#000000" },
    columnShadowPositionX: 0,
    columnShadowPositionY: 0,
    columnShadowBlur: 0,
    columnBorder: {
      color: { id: "color$$1", custom: "#000000" },
      radius: 0,
      width: { top: 0, bottom: 0, left: 0, right: 0 },
      style: OutlineOptions.NONE,
    },
    dropdownItemTypography: {
      id: "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 24,
        lineHeight: 10,
        letterSpacing: 1,
      },
    },
    dropdownItemLink: {
      id: "link$$1",
      custom: {
        text: {
          color: {
            id: "color$$1",
            custom: "#000000",
          },
          hoverColor: {
            id: "color$$1",
            custom: "#000000",
          },
          activeColor: {
            id: "color$$1",
            custom: "#000000",
          },
        },
      },
    },
    dropdownItemPadding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    dropdownItemAlignment: AlignmentHorizontalOption.LEFT,
    dropdownItemBackground: {
      color: { id: "color$$1", custom: "#000000" },
      activeColor: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
      disabledColor: { id: "color$$1", custom: "#000000" },
    },
    dropdownItemDisabledTextColor: { id: "color$$1", custom: "#000000" },
  };
}

import { contractors } from "./../../api/contractors";

export const CONTRACTORS_DATA_MOCK = {
  contractors: [
    {
      contractorId: 1,
      externalId: "CONTRACTOR1",
      name: "Contractor",
      contractorToken: "7b286b3c2749e1b92de3bd97b8d695d42a33c46ce3efc3bc427a5",
      selected: false,
    },
    {
      contractorId: 2,
      externalId: "CONTRACTOR2",
      name: "Contractor2",
      contractorToken:
        "c132a71ed95d8b85a3e8c7ac723749b4099e398728628acfb3a61d401bf3d958b",
      selected: true,
    },
    {
      contractorId: 102,
      externalId: "grerggergre",
      name: "string",
      contractorToken: "b422837e95c4f268867fbf2cfb396794d22863493d831",
      selected: false,
    },
  ],
};

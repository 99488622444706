import styled from "styled-components";

const imgTagAsBackgroundStyles = (
  objectPosition: string,
  objectFit: "cover" | "contain"
) => `
    position: absolute;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: ${objectFit};
    object-position: ${objectPosition};
`;

interface IStyledImageProps {
  opacity: number;
  objectPosition: string;
  objectFit: "cover" | "contain";
  backgroundMode?: boolean;
}
export const StyledSimplyImage = styled.img<IStyledImageProps>((props) => {
  const { opacity, backgroundMode, objectPosition, objectFit } = props;
  return `
        opacity: ${opacity / 100};
        ${
          backgroundMode
            ? imgTagAsBackgroundStyles(objectPosition, objectFit)
            : ""
        }
        `;
});

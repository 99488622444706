export const ASSOCIATIONS_DATA_MOCK = [
  {
    code: "testCode",
    isQuantified: false,
    isTwoWay: false,
    labels: {
      en_US: "TestLabel",
    },
  },
  {
    code: "testCode2",
    isQuantified: false,
    isTwoWay: false,
    labels: {
      en_US: "TestLabel2",
    },
  },
  {
    code: "testCode3",
    isQuantified: false,
    isTwoWay: false,
    labels: {
      en_US: "TestLabel3",
    },
  },
];

export const LOGISTIC_MIN_COLUMNS_NAMES_RESPONSE = {
    columnNames: ["TYPE0", "TYPE1", "TYPE2"],
  };

export const LOGISTIC_MIN =  {
    result: {
      TYPE0: 111,
      TYPE1: 222,
      TYPE2: 333,
    },
  };
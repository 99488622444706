import { IMediaFile } from "../../dto/media.types";

export const UPLOAD_MEDIA_FILE_RESPONSE: IMediaFile = {
  id: "6440d9418b2a605d47830853",
  name: "Screenshot ",
  type: "FILE",
  parentId: "643ff34efe870a417a0d21df",
  createdBy: "System",
  creationDate: "2023-04-20T08:18:41.652281",
  modifiedBy: "System",
  modificationDate: "2023-04-20T08:18:41.652281",
  readOnly: false,
  url: "https://localhost:9000/ecp-media-local/Screenshot%20.png",
  fileSize: 50539,
  fileExtension: "png",
};

import { PaymentMethodOptionB2B } from "../../../dto/cartB2B.types";

export const ecpMiniCartB2BResponse = {
  orders: [
    {
      productType: "productType AAA",
      netLogisticMin: 150,
      netLogisticMinMissing: 0,
      cartLines: [
        {
          sku: "1011000584",
          name: "AKCESORIA 3247, Accessories, Size2246",
          photoUrl: "https://...",
          quantity: 7,
          productPriceDetails: {
            netValue: 125.74,
            grossValue: 154.66,
          },
        },
      ],
    },
  ],
  summary: {
    netTotalCartValue: 3291.87,
    grossTotalCartValue: 4040.01,
    creditLimitAvailable: 0,
    creditLimitExceeded: 0,
    totalCreditLimit: 0,
    paymentMethod: "CASH",
    limitedClientStatus: "ACTIVE",
    paymentMethodOptions: [
      {
        active: true,
        portalPaymentMethod: PaymentMethodOptionB2B.CASH,
      },
      {
        active: false,
        portalPaymentMethod: PaymentMethodOptionB2B.CREDIT,
      },
    ],
  },
  count: 10,
};

export const COLUMNS_NAMES_RESPONSE = {
  columnNames: [
    "id",
    "registered",
    "firstname",
    "lastname",
    "email",
    "mobile",
    "birthdate",
    "gender",
    "storeid",
    "adr_id_billing",
    "adr_id_postal",
    "adr_id_store",
    "adr_id_packstation",
    "adr_id_pickuppoint",
  ],
};

export const SINGLE_DATA_RESPONSE = {
  result: {
    firstname: "arek",
    id: 6005801,
    lastname: "asdasdsa",
    registered: "registereddddd",
  },
};

import { produce } from "immer";
import {
  ChannelType,
  DeliveryType,
  ICartResponse,
  ICartSummary,
  IDeliveryAndPaymentResponse,
  IMiniCartCountResponse,
  IMiniCartResponse,
  IOrderConfirmationResponse,
  IOrderLine,
  IPaymentStatusResponse,
  IPlaceOrderResponse,
  OrderStatusType,
  PaymentType,
  ProductStatusOption,
} from "../../dto/cart.types";
import { DeliveryTimeCode } from "../../dto/searchResults.types";

export const CART_SUMMARY: ICartSummary = {
  cartValueFromProducts: 100.97,
  cartValueAfterDiscounts: 91.97,
  cartDeliveryCosts: 0,
  amountToPay: 91.97,
  loyaltyPoints: 91,
  deliveryDateFrom: "2022-12-15",
  deliveryDateTo: "2022-12-19",
};

export const ORDER_LINES: IOrderLine[] = [
  {
    orderLineId: 952315,
    quantity: 2,
    productSku: "5692494",
    deliveryTime: DeliveryTimeCode.HOURS_72,
    productName: "LEGO Przygoda. DVD",
    salePrice: 10.99,
    price: 17.99,
    nettoPrice: 14.63,
    suggestedRetailPrice: 22.99,
    oversize: false,
    inStock: true,
    stockLevelStatus: ProductStatusOption.AVAILABLE,
    availability: 72,
    visibility: true,
    categoryIds: ["020301"],
    createdAt: "2022-12-09T15:54:51.217+01:00",
    initialAddDateTime: "2022-12-09T15:54:28.635+01:00",
    auxiliaryBundleIdentifier: 0,
    maxSingleProductQuantityInCart: 11,
    enabled: true,
  },
  {
    orderLineId: 952316,
    quantity: 1,
    deliveryTime: DeliveryTimeCode.HOURS_72,
    productSku: "4761420",
    productName: "LEGO Friends Brickmaster. Skarb w Heartlake City",
    salePrice: 64.99,
    price: 64.99,
    nettoPrice: 61.9,
    suggestedRetailPrice: 50,
    oversize: false,
    inStock: true,
    stockLevelStatus: ProductStatusOption.AVAILABLE,
    availability: 24,
    visibility: true,
    categoryIds: ["02011002"],
    createdAt: "2022-12-09T15:54:51.217+01:00",
    initialAddDateTime: "2022-12-07T09:50:34.068+01:00",
    auxiliaryBundleIdentifier: 0,
    maxSingleProductQuantityInCart: 11,
    enabled: true,
  },
  {
    orderLineId: 952317,
    quantity: 1,
    deliveryTime: DeliveryTimeCode.HOURS_72,
    productSku: "5708318",
    productName: "LEGO Star Wars. Tajne misje Yody",
    salePrice: 8.99,
    price: 8.99,
    nettoPrice: 8.56,
    suggestedRetailPrice: 12.99,
    oversize: false,
    inStock: true,
    stockLevelStatus: ProductStatusOption.NOT_AVAILABLE,
    availability: 48,
    visibility: true,
    categoryIds: ["02011002"],
    createdAt: "2022-12-09T15:54:51.217+01:00",
    initialAddDateTime: "2022-12-09T15:54:31.011+01:00",
    auxiliaryBundleIdentifier: 0,
    maxSingleProductQuantityInCart: 11,
    enabled: true,
  },
];

export const CART_RESPONSE: ICartResponse = {
  cartViewResponse: {
    cartSummary: CART_SUMMARY,
    orderLines: ORDER_LINES,
    vouchers: [],
    coupons: [],
    cards: [],
    cartUserStatus: "REGISTERED",
    checkPackageBeforePickingUp: false,
  },
  errors: [],
};

export const ORDER_STATUS_RESPONSE: IPaymentStatusResponse = {
  paymentStatus: OrderStatusType.COMPLETED,
};

export const MINI_CART_RESPONSE: IMiniCartResponse = {
  content: {
    cartSummary: CART_SUMMARY,
    orderLines: ORDER_LINES,
    wishlist: {
      products: [{}],
    },
  },
};

export const MINI_CART_COUNT_RESPONSE: IMiniCartCountResponse = {
  count: 4,
};

export const DELIVERY_AND_PAYMENT: IDeliveryAndPaymentResponse = {
  response: {
    cartSummary: CART_SUMMARY,
    billingAddress: null,
    billingAddresses: [],
    orderLines: [ORDER_LINES[0]],
    deliveryChannels: [
      {
        channelName: "Kurier DPD",
        oneStoreActive: false,
        channelType: ChannelType.DPD,
        pickUpPointsTypes: [],
        price: 0,
        enabled: false,
        checkPackagePrice: 0,
        checkPackageAvailable: false,
        selected: true,
        deliveryDateFrom: "2023-12-15",
        deliveryDateTo: "2023-12-19",
        addresses: [
          {
            id: 1,
            city: "Lublin",
            zipCode: "20-533",
            street: "Walentynowicz",
            streetNumber: "23a",
            defaultAddress: false,
            packstationAvailable: false,
            firstName: "Adam",
            lastName: "Mickiewicz",
            phone: "676789876",
            country: "Polska",
            flatNumber: "2",
          },
        ],
        selectedAddress: {
          id: 1,
          city: "Lublin",
          zipCode: "20-533",
          street: "Walentynowicz",
          streetNumber: "23a",
          defaultAddress: false,
          packstationAvailable: false,
          firstName: "Adam",
          lastName: "Mickiewicz",
          phone: "676789876",
          country: "Polska",
          flatNumber: "2",
        },
        inaccessibilityType: "DISABLED",

        pickUpPointRealizationDays: [
          {
            realizationDays: 2,
            pickUpPointType: "POSTAL",
            cashOnDelivery: false,
          },
          {
            realizationDays: 4,
            pickUpPointType: "POSTAL",
            cashOnDelivery: true,
          },
        ],
      },
      {
        channelName: "Sklep XYZ",
        oneStoreActive: false,
        channelType: ChannelType.UPS,
        deliveryDateFrom: "2023-12-10",
        deliveryDateTo: "2023-12-14",
        pickUpPointsTypes: [
          { name: "Sklep XYZ", type: DeliveryType.STORE, price: 99.0 },
        ],
        price: 99.0,
        enabled: true,
        checkPackagePrice: 0,
        checkPackageAvailable: false,
        selected: false,
        addresses: [],
        selectedAddress: null,
        pickUpPointRealizationDays: [
          {
            realizationDays: 1,
            pickUpPointType: "STORE",
            cashOnDelivery: true,
          },
          {
            realizationDays: 1,
            pickUpPointType: "STORE",
            cashOnDelivery: false,
          },
        ],
      },
      {
        channelName: "Poczta Polska - Odbiór w punkcie",
        oneStoreActive: false,
        channelType: ChannelType.PICK_UP_AT_POINT,
        deliveryDateFrom: "2023-12-15",
        deliveryDateTo: "2023-12-15",
        pickUpPointsTypes: [],
        price: 885,
        enabled: false,
        checkPackagePrice: 0,
        checkPackageAvailable: false,
        selected: false,
        addresses: [],
        inaccessibilityType: "NOT_FOUND_BY_PACKAGE_TYPE",
        pickUpPointsAddresses: [],
        selectedAddress: null,
        pickUpPointRealizationDays: [
          {
            realizationDays: 2,
            pickUpPointType: "ZABKA",
            cashOnDelivery: false,
          },
          {
            realizationDays: 2,
            pickUpPointType: "POCZTA",
            cashOnDelivery: false,
          },
          {
            realizationDays: 2,
            pickUpPointType: "POCZTA",
            cashOnDelivery: true,
          },
          {
            realizationDays: 2,
            pickUpPointType: "RUCH",
            cashOnDelivery: false,
          },
          {
            realizationDays: 2,
            pickUpPointType: "AUTOMAT_POCZTOWY",
            cashOnDelivery: false,
          },
        ],
      },
      {
        channelName: "Paczkomaty Inpost",
        oneStoreActive: false,
        channelType: ChannelType.INPOST,
        deliveryDateFrom: "2023-12-11",
        deliveryDateTo: "2023-12-16",
        pickUpPointsTypes: [
          { name: "Paczkomat", type: DeliveryType.PACKSTATION, price: 99.0 },
        ],
        price: 99.0,
        enabled: true,
        checkPackagePrice: 0,
        checkPackageAvailable: false,
        selected: false,
        addresses: [],
        selectedAddress: null,
        // selectedAddress: {
        //   "@c": ".PortalBoxSystemCartDeliveryAndPaymentResponse$PortalCartAddress",
        //   id: 77113446,
        //   city: "Warszawa",
        //   zipCode: "00-517",
        //   street: "Marszałkowska",
        //   streetNumber: "76",
        //   packstationCode: "WAW322M",
        //   packstationAvailable: true,
        // },
        pickUpPointRealizationDays: [
          {
            realizationDays: 2,
            pickUpPointType: "PACKSTATION",
            cashOnDelivery: false,
          },
          {
            realizationDays: 2,
            pickUpPointType: "PACKSTATION",
            cashOnDelivery: true,
          },
        ],
      },
      {
        channelName: "Kurier Pocztex",
        oneStoreActive: false,
        channelType: ChannelType.POCZTEX,
        deliveryDateFrom: "2023-12-17",
        deliveryDateTo: "2023-12-19",
        pickUpPointsTypes: [
          { name: "Adres pocztowy", type: DeliveryType.POSTAL, price: 99.0 },
        ],
        price: 99.0,
        enabled: true,
        checkPackagePrice: 0,
        checkPackageAvailable: false,
        selected: false,
        addresses: [],
        selectedAddress: null,
        pickUpPointRealizationDays: [
          {
            realizationDays: 2,
            pickUpPointType: "POSTAL",
            cashOnDelivery: false,
          },
          {
            realizationDays: 2,
            pickUpPointType: "POSTAL",
            cashOnDelivery: true,
          },
        ],
      },
      {
        channelName: "Kurier UPS",
        oneStoreActive: false,
        channelType: ChannelType.UPS,
        deliveryDateFrom: "2023-12-13",
        deliveryDateTo: "2023-12-14",
        pickUpPointsTypes: [
          { name: "Adres pocztowy", type: DeliveryType.POSTAL, price: 99.0 },
        ],
        price: 99.0,
        enabled: true,
        checkPackagePrice: 0,
        checkPackageAvailable: false,
        selected: false,
        addresses: [],
        selectedAddress: {
          id: 213,
          city: "Ryki",
          zipCode: "05-123",
          street: "string",
          streetNumber: "string",
          packstationAvailable: false,
          firstName: "Anna",
          lastName: "Nowakowska",
          phone: "876545676",
          country: "Polska",
          flatNumber: "23",
          defaultAddress: true,
        },
        pickUpPointRealizationDays: [
          {
            realizationDays: 2,
            pickUpPointType: "POSTAL",
            cashOnDelivery: false,
          },
          {
            realizationDays: 2,
            pickUpPointType: "POSTAL",
            cashOnDelivery: true,
          },
        ],
      },
      {
        channelName: "Odbiór osobisty",
        oneStoreActive: false,
        channelType: ChannelType.STORE,
        deliveryDateFrom: "2023-12-18",
        deliveryDateTo: "2023-12-19",
        pickUpPointsTypes: [
          { name: "Sklep XYZ", type: DeliveryType.STORE, price: 99.0 },
        ],
        price: 99.0,
        enabled: true,
        checkPackagePrice: 0,
        checkPackageAvailable: false,
        selected: false,
        addresses: [
          {
            id: 1,
            storeCode: "23",
            city: "Lublin",
            zipCode: "20-533",
            street: "Walentynowicz",
            streetNumber: "23a",
            defaultAddress: false,
            packstationAvailable: false,
            firstName: "Adam",
            lastName: "Mickiewicz",
            phone: "676789876",
            country: "Polska",
            flatNumber: "2",
            storeName: "Sklep1",
          },
          {
            id: 2,
            storeCode: "24",
            city: "Warszawa",
            zipCode: "20-232",
            street: "Marszałkowska",
            streetNumber: "2",
            defaultAddress: false,
            packstationAvailable: false,
            firstName: "Jola",
            lastName: "Adamek",
            phone: "233233232",
            country: "Polska",
            flatNumber: "22",
            storeName: "Sklep2",
          },
        ],
        selectedAddress: {
          id: 213,
          storeCode: "25",
          city: "Ryki",
          zipCode: "05-123",
          street: "string",
          streetNumber: "string",
          packstationAvailable: false,
          firstName: "Anna",
          lastName: "Nowakowska",
          phone: "876545676",
          country: "Polska",
          flatNumber: "23",
          defaultAddress: true,
          storeName: "Sklep3",
        },
        pickUpPointRealizationDays: [
          {
            realizationDays: 1,
            pickUpPointType: "STORE",
            cashOnDelivery: true,
          },
          {
            realizationDays: 1,
            pickUpPointType: "STORE",
            cashOnDelivery: false,
          },
        ],
      },
    ],
    payments: [
      { paymentType: PaymentType.E_WALLET, enabled: false, selected: false },
      { paymentType: PaymentType.GIFT_CARD, enabled: false, selected: false },
      {
        paymentType: PaymentType.PAYMENT_IN_ADVANCE,
        enabled: true,
        selected: false,
      },
      {
        paymentType: PaymentType.CASH_ON_DELIVERY,
        enabled: true,
        selected: true,
      },
    ],
    customerCards: [],
    selectedCards: [],
    cartUserStatus: "REGISTERED",
    checkPackageBeforePickingUp: false,
  },
  errors: [
    {
      loyaltyStatus: "LOYALTY_VOUCHERS",
      errorMessage:
        "Z przyczyn technicznych, opcja dodania bonu lojalnościowego jest chwilowo niedostępna. Przepraszamy i prosimy spróbować ponownie za chwilę",
    },
  ],
};

export const DELIVERY_AND_PAYMENT_WITH_EMPTY_ORDER_LINES: IDeliveryAndPaymentResponse =
  produce(DELIVERY_AND_PAYMENT, (draft) => {
    draft.response.orderLines = [];
  });

export const ORDER_SUMMARY_MOCK: IOrderConfirmationResponse = {
  orderId: "123123123123",
  deliveryDate: "2019-12-12",
  deliveryDateFrom: "2019-12-12",
  deliveryDateTo: "2019-12-13",
  creationDate: "2019-12-13",
  repaymentUrl: "/url",
  email: "test@tesst.pl",
  cartSummary: CART_SUMMARY,
  deliveryAddress: {
    id: 1,
    firstName: "Adam",
    lastName: "Kowalczyk",
    phone: "123123120",
    country: "USA",
    city: "New York",
    zipCode: "20-202",
    street: "Minecraft",
    streetNumber: "123",
    flatNumber: "2",
    storeName: "siema",
    pickUpPointName: "picname",
    storeCode: "stronam",
    storeClickAndCollect: "NONE",
    packstationCode: "1222222",
    packstationAvailable: true,
    billing: true,
    companyName: "zoo",
    nip: "1231235565",
    cif: "Asdasd",
    registrationCode: "siema",
    bankName: "PKO",
    bankAccountNumber: "345678123567876543245678765432345",
    cnp: "yyaa",
    voivodeship: "nfnf",
    floor: "",
    pni: 123,
    pickUpPointType: "AUTOMAT_BIEDRONKA",
  },
  billingAddress: {
    id: 1,
    firstName: "Adam",
    lastName: "Kowalczyk",
    phone: "123123120",
    country: "USA",
    city: "New York",
    zipCode: "20-202",
    street: "Minecraft",
    streetNumber: "123",
    flatNumber: "2",
    storeName: "siema",
    pickUpPointName: "picname",
    storeCode: "stronam",
    storeClickAndCollect: "NONE",
    packstationCode: "1222222",
    packstationAvailable: true,
    billing: true,
    companyName: "zoo",
    nip: "1231235565",
    cif: "Asdasd",
    registrationCode: "siema",
    bankName: "PKO",
    bankAccountNumber: "345678123567876543245678765432345",
    cnp: "yyaa",
    voivodeship: "nfnf",
    floor: "",
    pni: 123,
    pickUpPointType: "AUTOMAT_BIEDRONKA",
  },
  payments: [
    {
      paymentType: PaymentType.E_WALLET,
      amount: 123,
    },
  ],
  orderLines: [
    {
      orderLineId: 1,
      quantity: 2,
      productSku: "123123",
      productName: "Lego",
      salePrice: 333.1,
      secondBestPromotionPrice: 11.11,
      price: 33.33,
      nettoPrice: 123.123,
      suggestedRetailPrice: 1.1,
      oversize: true,
      inStock: true,
      stockLevelStatus: "AVAILABLE",
      availability: 3,
      visibility: true,
      categoryIds: [],
      createdAt: "2022-12-12",
      initialAddDateTime: "2022-12-12",
      catalogPromotionDetails: {
        catalogPromotionTitle: "Title",
        catalogPromotionDescription: "desc",
        loyaltyPromotion: false,
      },
      cartPromotionDetails: {
        cartPromotionTitle: "cartPromo",
        cartPromotionDescription: "carft props",
      },
      auxiliaryBundleIdentifier: 3,
      maxSingleProductQuantityInCart: 4,
    },
    {
      orderLineId: 2,
      quantity: 4,
      productSku: "321321",
      productName: "Lego",
      salePrice: 333.1,
      secondBestPromotionPrice: 11.11,
      price: 33.33,
      nettoPrice: 123.123,
      suggestedRetailPrice: 1.1,
      oversize: true,
      inStock: true,
      stockLevelStatus: "AVAILABLE",
      availability: 5,
      visibility: true,
      categoryIds: [],
      createdAt: "2022-12-12",
      initialAddDateTime: "2022-12-12",
      catalogPromotionDetails: {
        catalogPromotionTitle: "Title",
        catalogPromotionDescription: "desc",
        loyaltyPromotion: false,
      },
      cartPromotionDetails: {
        cartPromotionTitle: "cartPromo",
        cartPromotionDescription: "carft props",
      },
      auxiliaryBundleIdentifier: 3,
      maxSingleProductQuantityInCart: 4,
    },
    {
      orderLineId: 3,
      quantity: 2,
      productSku: "111111",
      productName: "StarWars",
      salePrice: 420,
      secondBestPromotionPrice: 350,
      price: 390.1,
      nettoPrice: 123.123,
      suggestedRetailPrice: 1.1,
      oversize: true,
      inStock: true,
      stockLevelStatus: "AVAILABLE",
      availability: 3,
      visibility: true,
      categoryIds: [],
      createdAt: "2023-12-12",
      initialAddDateTime: "2023-12-12",
      catalogPromotionDetails: {
        catalogPromotionTitle: "Title",
        catalogPromotionDescription: "desc",
        loyaltyPromotion: false,
      },
      cartPromotionDetails: {
        cartPromotionTitle: "cartPromo",
        cartPromotionDescription: "carft props",
      },
      auxiliaryBundleIdentifier: 3,
      maxSingleProductQuantityInCart: 4,
    },
    {
      orderLineId: 4,
      quantity: 6,
      productSku: "867923",
      productName: "LEGO TECH",
      salePrice: 450,
      secondBestPromotionPrice: 390,
      price: 394.1,
      nettoPrice: 123.123,
      suggestedRetailPrice: 1.1,
      oversize: true,
      inStock: true,
      stockLevelStatus: "AVAILABLE",
      availability: 7,
      visibility: true,
      categoryIds: [],
      createdAt: "2023-12-12",
      initialAddDateTime: "2023-12-12",
      catalogPromotionDetails: {
        catalogPromotionTitle: "Title",
        catalogPromotionDescription: "desc",
        loyaltyPromotion: false,
      },
      cartPromotionDetails: {
        cartPromotionTitle: "cartPromo",
        cartPromotionDescription: "carft props",
      },
      auxiliaryBundleIdentifier: 3,
      maxSingleProductQuantityInCart: 4,
    },
    {
      orderLineId: 5,
      quantity: 1,
      productSku: "936784",
      productName: "Bionicle",
      salePrice: 450,
      secondBestPromotionPrice: 390,
      price: 394.1,
      nettoPrice: 123.123,
      suggestedRetailPrice: 1.1,
      oversize: true,
      inStock: true,
      stockLevelStatus: "AVAILABLE",
      availability: 7,
      visibility: true,
      categoryIds: [],
      createdAt: "2023-12-12",
      initialAddDateTime: "2023-12-12",
      catalogPromotionDetails: {
        catalogPromotionTitle: "Title",
        catalogPromotionDescription: "desc",
        loyaltyPromotion: false,
      },
      cartPromotionDetails: {
        cartPromotionTitle: "cartPromo",
        cartPromotionDescription: "carft props",
      },
      auxiliaryBundleIdentifier: 3,
      maxSingleProductQuantityInCart: 4,
    },
  ],
  cartUserStatus: "ANONYMOUS",
  customerInfo: {
    customerEmail: "mai@maial.glmail",
    customerAnonymous: true,
    customerAssigned: true,
  },
};

export const ADD_TO_CART_REJECTED_LIMIT = {
  code: 2014,
  message: "Product quantity limit for cart config exceeded",
  serviceName: "portal-services",
  data: {},
};

export const ADD_TO_CART_REJECTED_STOCK = {
  code: 2015,
  message: "Product quantity limit for stock exceeded",
  serviceName: "portal-services",
  data: {},
};

export const PLACE_ORDER_RESPONSE: IPlaceOrderResponse = {
  response: {
    orderId: "23456789876543",
    redirectUrl: "https://autopay.pl/pay", //only when select advance payment
    products: [
      {
        productId: 123,
        quantity: 2,
        commissionId: 444,
        gross: 12,
      },
    ],
  },
  errors: [
    {
      loyaltyStatus: "string",
      errorMessage: "string",
    },
  ],
};

export const PLACE_ORDER_RESPONSE_WITHOUT_REDIRECT: IPlaceOrderResponse =
  produce(PLACE_ORDER_RESPONSE, (draft) => {
    draft.response.redirectUrl = undefined;
  });

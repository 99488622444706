import { nanoid } from "nanoid";
import { IPaginatedPortalResponse } from "../../dto/paginationResponse.types";
import { IStoreLocator } from "../../dto/storesLocator.types";

export const STORES_LOCATOR_RESPONSE: IPaginatedPortalResponse<
  IStoreLocator[]
> = {
  result: [
    {
      code: nanoid(),
      name: "abcdefghijk",
      street: "Alaer",
      streetNumber: "44",
      city: "Alamaata",
      zipCode: "06-403",
      voivodeship: "dolnośląskie",
      floor: "",
      clickAndCollectPrice: 0,
      geoLatitude: "0",
      geoLongitude: "360",
      openingHours: [
        {
          days: "wtorek",
          hours: "07:00 - 17:00",
        },
        {
          days: "piątek",
          hours: "10:00 - 15:00",
        },
      ],
      phone: "123456789",
      photoLocation: "https://dev-minio-ecp.nd0.pl/ecp-media-cms/laptop.png",
      webPageUrl: "www.abc.pl",
      email: "abc@wp.pl",
    },
    {
      code: nanoid(),
      name: "cba",
      street: "Przykładowa",
      streetNumber: "44",
      city: "Sosnowiec",
      zipCode: "41-200",
      voivodeship: "śląskie",
      floor: "",
      clickAndCollectPrice: 0,
      geoLatitude: "50.171",
      geoLongitude: "19.061",
      openingHours: [
        {
          days: "środa",
          hours: "07:00 - 17:00",
        },
        {
          days: "czwartek",
          hours: "10:00 - 15:00",
        },
      ],
      phone: "987654321",
      photoLocation: "https://dev-minio-ecp.nd0.pl/ecp-media-cms/laptop.png",
      webPageUrl: "www.abc.pl",
      email: "abc@wp.pl",
    },
    {
      code: nanoid(),
      name: "cba",
      street: "Przykładowa",
      streetNumber: "44",
      city: "Sosnowiec",
      zipCode: "41-200",
      voivodeship: "śląskie",
      floor: "",
      clickAndCollectPrice: 0,
      geoLatitude: "50.2",
      geoLongitude: "19.1",
      openingHours: [
        {
          days: "środa",
          hours: "07:00 - 17:00",
        },
        {
          days: "czwartek",
          hours: "10:00 - 15:00",
        },
      ],
      phone: "987654321",
      photoLocation: "https://dev-minio-ecp.nd0.pl/ecp-media-cms/laptop.png",
      webPageUrl: "www.abc.pl",
      email: "abc@wp.pl",
    },
    {
      code: nanoid(),
      name: "aaa",
      street: "Dziwna",
      streetNumber: "44",
      city: "Warszawa",
      zipCode: "02-800",
      voivodeship: "mazowieckie",
      floor: "1",
      clickAndCollectPrice: 0,
      geoLatitude: "52.134",
      geoLongitude: "21.001",
      openingHours: [
        {
          days: "poniedziałek",
          hours: "07:00 - 17:00",
        },
        {
          days: "sobota",
          hours: "10:00 - 15:00",
        },
      ],
      phone: "111222333",
      photoLocation: "https://dev-minio-ecp.nd0.pl/ecp-media-cms/laptop.png",
      webPageUrl: "www.abc.pl",
      email: "abc@wp.pl",
    },
    {
      code: nanoid(),
      name: "aaa",
      street: "Dziwna",
      streetNumber: "44",
      city: "Warszawa",
      zipCode: "02-800",
      voivodeship: "mazowieckie",
      floor: "1",
      clickAndCollectPrice: 0,
      geoLatitude: "52.2",
      geoLongitude: "21.1",
      openingHours: [
        {
          days: "poniedziałek",
          hours: "07:00 - 17:00",
        },
        {
          days: "sobota",
          hours: "10:00 - 15:00",
        },
      ],
      phone: "111222333",
      photoLocation: "https://dev-minio-ecp.nd0.pl/ecp-media-cms/laptop.png",
      webPageUrl: "www.abc.pl",
      email: "abc@wp.pl",
    },
  ],
  page: {
    number: 0,
    size: 1000,
    count: 1,
    total: 150,
  },
};

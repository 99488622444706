import { isClientSide } from "@ecp-redux/api/api";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { useState, useEffect } from "react";
import { useTheme } from "styled-components";

export const useRowPopup = () => {
  const themeSettings = useTheme() as IThemeState;
  const [elemLeft, setElemLeft] = useState<number | undefined>(undefined);

  useEffect(() => {
    const updateElemLeft = () => {
      if (isClientSide()) {
        setElemLeft(
          document.getElementById("shadow-container")?.getBoundingClientRect()
            .left
        );
      }
    };

    updateElemLeft();

    window.addEventListener("resize", updateElemLeft);

    return () => {
      window.removeEventListener("resize", updateElemLeft);
    };
  }, [themeSettings.stylePages.pages.maxWidth]);

  return {
    elemLeft,
    isEditMode: elemLeft !== undefined && elemLeft > 0,
  };
};

import {
  Address,
  AddressPackstation,
  AddressPickUpPoint,
  AddressPostalBilling,
  IAddressesResponse,
} from "../../dto/cartAddresses.type";

export const ADDRESS: Address = {
  id: 6,
  city: "Lublin",
  zipCode: "23-100",
  street: "Lipowa",
  streetNumber: "12",
  defaultAddress: true,
  packstationAvailable: true,
};

export const ADDRESS_POSTAL_BILLING: AddressPostalBilling = {
  id: 12,
  city: "Lublin",
  zipCode: "23-100",
  street: "Lipowa",
  streetNumber: "12",
  defaultAddress: true,
  packstationAvailable: true,
  firstName: "Adam",
  lastName: "Mickiewicz",
  phone: "123123123",
  country: "Poland",
  flatNumber: "34",
  nip: "7120152845",
  companyName: "Nike",
};

export const ADDRESS_PICKUP_POINT: AddressPickUpPoint = {
  id: 62,
  city: "Lublin",
  zipCode: "23-100",
  street: "Lipowa",
  streetNumber: "12",
  defaultAddress: true,
  packstationAvailable: true,
  storeName: "Nike store",
  pni: 12,
};

export const ADDRESS_PACKSTATION: AddressPackstation = {
  id: 88,
  city: "Lublin",
  zipCode: "23-100",
  street: "Lipowa",
  streetNumber: "12",
  defaultAddress: true,
  packstationAvailable: true,
  packstationCode: "12",
};

export const ADDRESSES_RESPONSE: IAddressesResponse = {
  billingAddresses: [
    {
      packstationAvailable: false,
      id: 5027302,
      defaultAddress: true,
      firstName: "Adam",
      lastName: "Kowalski",
      phone: "536473673",
      country: "POLAND",
      city: "Lublin",
      zipCode: "20-433",
      street: "Kołyskowa",
      streetNumber: "31",
      flatNumber: "4b",
    },
    {
      packstationAvailable: false,
      id: 5027251,
      defaultAddress: false,
      firstName: "Maks",
      lastName: "Kasykowski",
      phone: "534353247",
      country: "POLAND",
      city: "Lublin",
      zipCode: "20-242",
      street: "Lebranta",
      streetNumber: "12",
      flatNumber: "4b",
    },
  ],
  storeAddresses: [
    {
      storeName: "CH Lublin Plaza",
      storeClickAndCollect: "CLICK_AND_COLLECT",
      packstationAvailable: false,
      id: 5027353,
      defaultAddress: true,
      city: "Lublin",
      zipCode: "20-024",
      street: "Lipowa",
      streetNumber: "13",
      storeCode: "543",
    },
    {
      storeName: "Felicity",
      storeClickAndCollect: "CLICK_AND_COLLECT",
      packstationAvailable: false,
      id: 43566754,
      defaultAddress: false,
      city: "Lublin",
      zipCode: "20-23",
      street: "Witosa",
      streetNumber: "2",
      storeCode: "232",
    },
  ],
  packstationAddresses: [
    {
      packstationCode: "LUB08ML",
      packstationAvailable: true,
      id: 5027352,
      defaultAddress: true,
      city: "Lublin",
      zipCode: "20-207",
      street: "Turystyczna",
      streetNumber: "1",
    },
    {
      packstationCode: "WAW322M",
      packstationAvailable: true,
      id: 5027250,
      defaultAddress: false,
      city: "Warszawa",
      zipCode: "00-517",
      street: "Marszałkowska",
      streetNumber: "76",
    },
  ],
  postalAddresses: [
    {
      packstationAvailable: false,
      id: 5027300,
      defaultAddress: false,
      firstName: "Adam",
      lastName: "Kowalski",
      phone: "536473673",
      country: "POLAND",
      city: "Lublin",
      zipCode: "20-433",
      street: "Kołyskowa",
      streetNumber: "31",
      flatNumber: "4b",
    },
    {
      packstationAvailable: false,
      id: 5027304,
      defaultAddress: false,
      firstName: "Kurde",
      lastName: "Anikokwksa",
      phone: "536346789",
      country: "POLAND",
      city: "Lublin",
      zipCode: "20-430",
      street: "test",
      streetNumber: "123",
      flatNumber: "23",
    },
    {
      packstationAvailable: false,
      id: 5027350,
      defaultAddress: true,
      firstName: "Szyma",
      lastName: "Pola",
      phone: "536654233",
      country: "POLAND",
      city: "Lublin",
      zipCode: "20-234",
      street: "Ponurczykowa",
      streetNumber: "123",
      flatNumber: "2",
    },
  ],
  pickUpPointAddresses: [
    {
      id: 15,
      city: "Warszawa",
      zipCode: "23-100",
      street: "Karmelicka",
      streetNumber: "15",
      defaultAddress: true,
      packstationAvailable: true,
      storeName: "Nike store",
      pni: 12,
    },
  ],
};

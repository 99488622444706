import React from "react";

const ConditionalHTMLElement = React.forwardRef(
  (
    { show = true, renderAs, children, isOpen, ...props }: any,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    const HTMLElement = renderAs ?? "div";

    return show ? (
      <HTMLElement {...props} ref={ref}>
        {children}
      </HTMLElement>
    ) : null;
  }
);

export default ConditionalHTMLElement;

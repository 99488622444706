import styled from "styled-components";
import {
  IBoxBaseSettings,
  IElementImage,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import ConditionalHTMLElement from "../../../global/components/ConditionalHTMLElement/ConditionalHTMLElement";
import composeCss from "../../composeCss";

const StyledImage = styled(ConditionalHTMLElement).attrs((props) => ({
  renderAs: props?.renderAs ?? "a",
  show: props?.["$settings"].show,
}))<IBoxBaseSettings<IElementImage>>(
  ({ $settings }) => `
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: var(--zindex-background-image);
  background: ${composeCss.imageSettings($settings)};
  opacity: ${$settings.opacity / 100};
`
);

export default StyledImage;

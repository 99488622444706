import { Slide, toast } from "react-toastify";
import { useTheme } from "styled-components";
import {
  IGlobalSnackbarObject,
  IThemeState,
  TSnackbarId,
  TSnackbarReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { convertSnackbarIdToValues } from "../../../settingsPatterns/settingsPatterns.methods";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import StyledText from "../../styleElements/StyledText/StyledText";
import { StyledPortalSnackbar } from "./Snackbar.styled";

interface IPortalSnackbarProps {
  snackbarValue: IGlobalSnackbarObject;
  message: string;
  theme: IThemeState;
  closeToast?: () => void;
}

export const PortalSnackbar: React.FC<IPortalSnackbarProps> = ({
  snackbarValue,
  message,
  closeToast,
  theme,
}) => {
  return (
    <StyledPortalSnackbar
      {...snackbarValue}
      theme={theme}
      isEmptySnackbar={!isNotEmptyMessage(message)}
    >
      <StyledText
        className="message"
        renderAs="p"
        $settings={{
          font: snackbarValue.textStyle,
          text: { color: snackbarValue.textColor },
        }}
        theme={theme}
        show={isNotEmptyMessage(message)}
      >
        {message}
      </StyledText>
      {snackbarValue.iconClose && (
        <button onClick={closeToast} className="snackbar-icon-close">
          <CloseIcon />
        </button>
      )}
    </StyledPortalSnackbar>
  );
};

export const useOpenPortalSnackbar = () => {
  const theme = useTheme() as IThemeState;
  return {
    openPortalSnackbar: (
      snackbar: TSnackbarReadoutValue | TSnackbarId,
      message: string
    ) => {
      renderPortalSnackbar(
        convertSnackbarIdToValues(snackbar, theme.globalObjects.snackbars),
        message,
        theme
      );
    },
  };
};

export const renderPortalSnackbar = (
  snackbar: IGlobalSnackbarObject,
  message: string,
  theme: IThemeState
) => {
  return toast(
    ({ closeToast }) => (
      <PortalSnackbar
        snackbarValue={snackbar}
        message={message}
        closeToast={closeToast}
        theme={theme}
      />
    ),
    {
      autoClose: snackbar.timeToDisplay * 1000,
      hideProgressBar: true,
      transition: Slide,
      containerId: "PortalSnackbar",
      closeOnClick: false,
      draggable: false,
    }
  );
};

import { z } from "zod";
import {
  TableCellSetScheme,
  TableCellSetSchemeMobile,
} from "./GlobalTableCell.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  FontFamilyOptions,
  FontWeightOptionsCombine,
  FontWeightOptionsLongListOfWeights,
  OutlineOptions,
  RowHeightOption,
  SettingPositionOptions,
  TColorId,
  TRowWidthOption,
  TTypoId,
} from "./themeSettings.types";

export enum SchemeDescription {
  NUMBER = "NUMBER",
  BUTTON = "BUTTON",
  TYPO = "TYPO",
  DEFAULT = "DEFAULT",
  SNACKBAR = "SNACKBAR",
  TOOLTIP = "TOOLTIP",
  LINK = "LINK",
  INPUT = "INPUT",
  COLOR = "COLOR",
  OPTION = "OPTION",
  ACCORDION = "ACCORDION",
  DROPDOWN = "DROPDOWN",
  TABLE_CELL = "TABLE_CELL",
  ATTRIBUTES = "ATTRIBUTES",
  BOOLEAN = "BOOLEAN",
  ENUM = "ENUM",
}

export enum StringBoolean {
  TRUE = "TRUE",
  FALSE = "FALSE",
}

export const MessageScheme = z.string();
export const StringBooleanScheme = z.nativeEnum(StringBoolean);
export const MessageArrayScheme = z.string();
export const UrlArrayScheme = z.string();
export const SettingSkuScheme = z.string();
export const SizeCSSScheme = z.custom<
  `${number}px` | `${number}em` | `${number}%`
>((val) => {
  return /^-?(\d+(\.\d+)?)(px|em|%)$/.test(val as string);
});
export const MarginSizeScheme = z.custom<
  | `${number}px ${number}px ${number}px ${number}px`
  | `${number}px ${number}px ${number}px`
  | `${number}px ${number}px`
  | `${number}px`
>((val) => {
  return /^\d+(px|em|%)(( +)\d+(px|%|em)){0,3}$/.test(val as string);
});

export const SettingNumberScheme = z.coerce
  .string()
  .describe(SchemeDescription.NUMBER);

export const SettingBooleanScheme = z
  .custom<`true` | `false`>((val) => {
    return /^(false|true)$/.test(val as string);
  })
  .describe(SchemeDescription.BOOLEAN);

export const SettingAttributesScheme = z
  .string()
  .describe(SchemeDescription.ATTRIBUTES);

export const InternalUrlScheme = z.string().startsWith("/");
export const ExternalUrlScheme = z.string().startsWith("https://");
export const goToId = z.string().startsWith("#");
export const emptyUrl = z.string().length(0);
export const UrlScheme = z.union([
  InternalUrlScheme,
  ExternalUrlScheme,
  goToId,
  emptyUrl,
]);

export const SnackbarIdScheme = z
  .custom<`snackbar$$${number}`>((val) => {
    return /^snackbar\$\$.*\d$/.test(val as string);
  })
  .describe(SchemeDescription.SNACKBAR);

export const DropdownIdScheme = z
  .custom<`dropdown$$${number}`>((val) => {
    return /^dropdown\$\$.*\d$/.test(val as string);
  })
  .describe(SchemeDescription.DROPDOWN);

export const ColorIdScheme = z
  .custom<`color$$${number}`>((val) => {
    return /^color\$\$.*\d$/.test(val as string);
  })
  .describe(SchemeDescription.COLOR);
export const HEXScheme = z.custom<`#${string}`>((val) => {
  return /^#.*$/.test(val as string);
});
export const TypoIdScheme = z
  .custom<`typo$$${number}`>((val) => {
    return /^typo\$\$\d+$/.test(val as string);
  })
  .describe(SchemeDescription.TYPO);
export const ButtonIdScheme = z
  .custom<`button$$${string}`>((val) => {
    return /^button\$\$[A-Za-z0-9_-]+/.test(val as string);
  })
  .describe(SchemeDescription.BUTTON);
export const InputIdScheme = z
  .custom<`input$$${number}`>((val) => {
    return /^input\$\$.*\d$/.test(val as string);
  })
  .describe(SchemeDescription.INPUT);
export const LinkIdScheme = z
  .custom<`link$$${string}`>((val) => {
    return /^link\$\$.*\d$/.test(val as string);
  })
  .describe(SchemeDescription.LINK);
export const TooltipIdScheme = z
  .custom<`tooltip$$${number}`>((val) => {
    return /^tooltip\$\$.*\d$/.test(val as string);
  })
  .describe(SchemeDescription.TOOLTIP);
export const OptionIdScheme = z
  .custom<`option$$${number}`>((val) => {
    return /^option\$\$.*\d$/.test(val as string);
  })
  .describe(SchemeDescription.OPTION);
export const AccordionIdScheme = z
  .custom<`accordion$$${number}`>((val) => {
    return /^accordion\$\$.*\d$/.test(val as string);
  })
  .describe(SchemeDescription.ACCORDION);

export const AdvanceMessagesScheme = z.object({
  currencyShort: z.string().default("zł").catch("zł"),
  taxShort: z.string().default("%").catch("%"),
  delivery_HOURS_24: z.string().default("Dostawa w 24h").catch("Dostawa w 24h"),
  delivery_HOURS_48: z.string().default("Dostawa w 48h").catch("Dostawa w 48h"),
  delivery_HOURS_72: z.string().default("Dostawa w 72h").catch("Dostawa w 72h"),
  delivery_DAYS_4_5: z
    .string()
    .default("Dostawa w ciągu 4-5 dni")
    .catch("Dostawa w ciągu 4-5 dni"),
  delivery_DAYS_6_8: z
    .string()
    .default("Dostawa w ciągu 6-8 dni")
    .catch("Dostawa w ciągu 6-8 dni"),
  delivery_WEEKS_2: z
    .string()
    .default("Dostawa w ciągu 2 tygodni")
    .catch("Dostawa w ciągu 2 tygodni"),
  delivery_NOT_AVAILABLE: z
    .string()
    .default("Termin dostawy nieznany")
    .catch("Termin dostawy nieznany"),
  delivery_unknown: z
    .string()
    .default("Brak informacji o dostawie")
    .catch("Brak informacji o dostawie"),
});

export const AdvanceSettingsScheme = z.object({
  backgroundUrl: UrlScheme.default("/static/images/placeholder.png").catch(
    "/static/images/placeholder.png"
  ),
  snackbarApiError:
    SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  searchRedirectUrl: UrlScheme.default("/search").catch("/search"),
  googleMapApiKey: z.string().default("").catch(""),
  googleRecaptchaSiteKey: z.string().default("").catch(""),
  googleRecaptchaSecretKey: z.string().default("").catch(""),
  unsubscribNewsletterSuccesUrl: UrlScheme.default(
    "/unsubscrib_newsletter_succes"
  ).catch("/unsubscrib_newsletter_succes"),
  dynamicBoxImagePlug: UrlScheme.default("").catch(""),
  loginUrl: z.string().default("/login").catch("/login"),
  resetPasswordUrl: z
    .string()
    .default("/reset_password")
    .catch("/reset_password"),
  faviconURL: UrlScheme.default("").catch(""),
  redirectForEmptyResults: UrlScheme.default("/").catch("/"),
  clipboard_icon_on: UrlScheme.default("").catch(""),
  clipboard_icon_off: UrlScheme.default("").catch(""),
  ribbons_label_typography: TypoIdScheme.default("typo$$1").catch("typo$$1"),
  ribbons_label_color: ColorIdScheme.default("color$$1").catch("color$$1"),
  new_ribbon_background_color:
    ColorIdScheme.default("color$$2").catch("color$$2"),
  bestseller_ribbon_background_color:
    ColorIdScheme.default("color$$3").catch("color$$3"),
  promotion_ribbon_background_color:
    ColorIdScheme.default("color$$3").catch("color$$3"),
  inpost_geowidget_token: z.string().default("").catch(""),
  collect_events: SettingBooleanScheme.default("true").catch("true"),
});

export const SpacingScheme = z
  .object({
    top: z.number().default(0).catch(0),
    bottom: z.number().default(0).catch(0),
    right: z.number().default(0).catch(0),
    left: z.number().default(0).catch(0),
  })
  .default({
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  });

export const RadiusScheme = z.object({
  topLeft: z.number().default(0).catch(0),
  topRight: z.number().default(0).catch(0),
  bottomLeft: z.number().default(0).catch(0),
  bottomRight: z.number().default(0).catch(0),
});

export const inheritedFromBasic = {
  inheritedFromBasic: z.boolean().default(true).catch(true),
};

export const FontFamilyOptionsSchema = z.nativeEnum(FontFamilyOptions);
export const FontWeightOptionsSchema = z.nativeEnum(FontWeightOptionsCombine);

export const TypographySchema = z
  .object({
    family: FontFamilyOptionsSchema.default(FontFamilyOptions.OPEN_SANS).catch(
      FontFamilyOptions.OPEN_SANS
    ),
    weight: FontWeightOptionsSchema.default(
      FontWeightOptionsCombine.Weight300
    ).catch(FontWeightOptionsCombine.Weight300),
    size: z.number().default(16).catch(16),
    lineHeight: z.number().default(16).catch(16),
    letterSpacing: z.number().default(2).catch(2),
  })
  .default({
    family: FontFamilyOptions.OPEN_SANS,
    weight: FontWeightOptionsCombine.Weight300,
    size: 16,
    lineHeight: 16,
    letterSpacing: 2,
  });

export const TypographySchemaMobile =
  TypographySchema.removeDefault().extend(inheritedFromBasic);

export const TypoReadoutValueScheme = z
  .object({
    id: TypoIdScheme.or(z.literal("custom"))
      .default("typo$$1")
      .catch("typo$$1"),
    custom: TypographySchema,
  })
  .default({ id: "typo$$1", custom: {} });

export type ITypoReadoutValue = z.infer<typeof TypoReadoutValueScheme>;

export const TypoIdItemSchema = z
  .object({
    id: TypoIdScheme.default("typo$$1").catch("typo$$1"),
    typoStyleName: z.string().default("TypoId").catch("TypoId"),
  })
  .merge(TypographySchema.removeDefault());

export const TypoSetSchema = z.array(
  TypoIdItemSchema.extend(inheritedFromBasic)
);
export const TypoSetSchemaMobile = z.array(
  TypoIdItemSchema.extend(inheritedFromBasic)
);

export const ColorReadoutValueScheme = z
  .object({
    id: ColorIdScheme.or(z.literal("custom")).catch("color$$1"),
    custom: HEXScheme.default("#FFFFFF").catch("#FFFFFF"),
  })
  .default({ id: "color$$1", custom: "#FFFFFF" })
  .catch({ id: "color$$1", custom: "#FFFFFF" });

export const ColorReadoutValueSchemeBlackDefault = z
  .object({
    id: ColorIdScheme.or(z.literal("custom")).catch("color$$1"),
    custom: HEXScheme.default("#000000").catch("#000000"),
  })
  .default({ id: "color$$1", custom: "#000000" })
  .catch({ id: "color$$1", custom: "#000000" });

export const AlignmentScheme = z.object({
  horizontal: z.nativeEnum(AlignmentHorizontalOption),
  vertical: z.nativeEnum(AlignmentVerticalOption),
});

export const HorizontalAlignmentScheme = AlignmentScheme.pick({
  horizontal: true,
}).default({ horizontal: AlignmentHorizontalOption.LEFT });

export const GlobalPaletteColorItemSchema = z.object({
  id: ColorIdScheme.or(z.literal("custom")).catch("custom"),
  name: z.string().default("GlobalPaletteColor").catch("GlobalPaletteColor"),
  colorHex: HEXScheme.default("#FFFFFF").catch("#FFFFFF"),
});

export const GlobalPaletteColorSchema = z.array(GlobalPaletteColorItemSchema);

export const GlobalColorsStylesSchema = z
  .object({
    color: ColorReadoutValueScheme.catch({ id: "custom", custom: "#FFFFFF" }),
    hoverColor: ColorReadoutValueScheme.catch({
      id: "custom",
      custom: "#FFFFFF",
    }),
    activeColor: ColorReadoutValueScheme.catch({
      id: "custom",
      custom: "#FFFFFF",
    }),
    disabledColor: ColorReadoutValueScheme.catch({
      id: "custom",
      custom: "#FFFFFF",
    }),
  })
  .default({
    color: ColorReadoutValueScheme.parse({}),
    hoverColor: ColorReadoutValueScheme.parse({}),
    activeColor: ColorReadoutValueScheme.parse({}),
    disabledColor: ColorReadoutValueScheme.parse({}),
  });

export const BackgroundScheme = z.object({
  color: ColorReadoutValueScheme,
  backgroundUrl: UrlScheme.default("").catch(""),
  fit: z.boolean().default(false),
  alignment: AlignmentScheme,
  opacity: z.number().default(100),
});

export const oldBorderSchema = z.object({
  radius: z.union([
    z.number().optional().default(0),
    z
      .object({
        topLeft: z.number().default(0),
        topRight: z.number().default(0),
        bottomLeft: z.number().default(0),
        bottomRight: z.number().default(0),
      })
      .default({ topLeft: 0, topRight: 0, bottomLeft: 0, bottomRight: 0 }),
  ]),
  radiusV2: z
    .union([
      z.object({
        topLeft: z.number(),
        topRight: z.number(),
        bottomLeft: z.number(),
        bottomRight: z.number(),
      }),
      z.null(),
    ])
    .default(null),
  width: z.union([
    z.number().default(0),
    z.object({
      top: z.number(),
      right: z.number(),
      bottom: z.number(),
      left: z.number(),
    }),
  ]),
  widthV2: z
    .union([
      z.object({
        top: z.number(),
        right: z.number(),
        bottom: z.number(),
        left: z.number(),
      }),
      z.null(),
    ])
    .default(null),
});

export const SettingPositionOptionsSchema = z.nativeEnum(
  SettingPositionOptions
);

export const IIconSchema = z
  .object({
    iconUrl: UrlScheme.nullable().catch(null),
    position: SettingPositionOptionsSchema.default(
      SettingPositionOptions.LEFT
    ).catch(SettingPositionOptions.LEFT),
    size: z.number().default(15).catch(15),
    spacing: z.number().default(5).catch(5),
  })
  .default({
    iconUrl: null,
    position: SettingPositionOptions.LEFT,
    size: 15,
    spacing: 5,
  });

export const IButtonIconSchema = z
  .object({
    iconUrl: UrlScheme.nullable().catch(null),
    position: SettingPositionOptionsSchema.default(
      SettingPositionOptions.LEFT
    ).catch(SettingPositionOptions.LEFT),
    size: z.number().default(15).catch(15),
    spacing: z.number().default(5).catch(5),
    color: ColorReadoutValueScheme,
    hoverColor: ColorReadoutValueScheme,
    activeColor: ColorReadoutValueScheme,
    disabledColor: ColorReadoutValueScheme,
  })
  .default({
    iconUrl: null,
    position: SettingPositionOptions.LEFT,
    size: 15,
    spacing: 5,
    color: ColorReadoutValueScheme.parse({}),
    hoverColor: ColorReadoutValueScheme.parse({}),
    activeColor: ColorReadoutValueScheme.parse({}),
    disabledColor: ColorReadoutValueScheme.parse({}),
  });

export const GlobalButtonSchema = z.object({
  horizontalPadding: z.number().default(0).catch(0),
  verticalPadding: z.number().default(0).catch(0),
  border: oldBorderSchema
    .merge(GlobalColorsStylesSchema.removeDefault())
    .default({
      color: ColorReadoutValueScheme.parse({}),
      hoverColor: ColorReadoutValueScheme.parse({}),
      activeColor: ColorReadoutValueScheme.parse({}),
      disabledColor: ColorReadoutValueScheme.parse({}),
    })
    .transform(({ radius, radiusV2, width, widthV2, ...rest }) => {
      let transformedRadius = radiusV2;
      let transformedWidth = widthV2;

      if (radiusV2 === null && typeof radius === "number") {
        transformedRadius = {
          topLeft: radius,
          topRight: radius,
          bottomLeft: radius,
          bottomRight: radius,
        };
      }
      if (widthV2 === null && typeof width === "number") {
        transformedWidth = {
          top: width,
          right: width,
          bottom: width,
          left: width,
        };
      }
      return {
        ...rest,
        radius,
        width,
        radiusV2: transformedRadius,
        widthV2: transformedWidth,
      };
    }),
  font: TypoReadoutValueScheme,
  text: GlobalColorsStylesSchema,
  background: GlobalColorsStylesSchema,
  icon: IButtonIconSchema,
});

export const ButtonReadoutValueScheme = z
  .object({
    id: ButtonIdScheme.or(z.literal("custom")).catch("custom"),
    custom: GlobalButtonSchema,
  })
  .default({ id: "button$$1", custom: GlobalButtonSchema.parse({}) })
  .catch({
    id: "button$$1",
    custom: GlobalButtonSchema.parse({}),
  });

export const GlobalButtonItemSchema = z
  .object({
    id: ButtonIdScheme.default("button$$1").catch("button$$1"),
    buttonStyleName: z.string().default("Main Button").catch("Main Button"),
  })
  .merge(GlobalButtonSchema);

export const ButtonSetSchema = z.array(GlobalButtonItemSchema).default([]);
export const ButtonSetSchemaMobile = z
  .array(GlobalButtonItemSchema.extend(inheritedFromBasic))
  .default([]);

export const OutlineOptionsScheme = z.nativeEnum(OutlineOptions);

export const Border_v2Scheme = z.object({
  color: ColorReadoutValueScheme.catch({ id: "custom", custom: "#FFFFFF" }),
  width: z
    .object({
      top: z.number().default(0).catch(0),
      right: z.number().default(0).catch(0),
      bottom: z.number().default(0).catch(0),
      left: z.number().default(0).catch(0),
    })
    .default({ top: 0, right: 0, bottom: 0, left: 0 })
    .catch({ top: 0, right: 0, bottom: 0, left: 0 }),
  style: OutlineOptionsScheme.default(OutlineOptions.NONE).catch(
    OutlineOptions.NONE
  ),
  radius: z.number().default(0).catch(0),
});

export const Border_v3Scheme = z.object({
  color: ColorReadoutValueScheme.catch({ id: "custom", custom: "#FFFFFF" }),
  width: z
    .object({
      top: z.number().default(0).catch(0),
      right: z.number().default(0).catch(0),
      bottom: z.number().default(0).catch(0),
      left: z.number().default(0).catch(0),
    })
    .default({ top: 0, right: 0, bottom: 0, left: 0 })
    .catch({ top: 0, right: 0, bottom: 0, left: 0 }),
  style: OutlineOptionsScheme.default(OutlineOptions.NONE).catch(
    OutlineOptions.NONE
  ),
  radius: z
    .object({
      topLeft: z.number().default(0).catch(0),
      topRight: z.number().default(0).catch(0),
      bottomLeft: z.number().default(0).catch(0),
      bottomRight: z.number().default(0).catch(0),
    })
    .default({ topLeft: 0, topRight: 0, bottomLeft: 0, bottomRight: 0 })
    .catch({
      topLeft: 0,
      topRight: 0,
      bottomLeft: 0,
      bottomRight: 0,
    }),
});

export const InputFontSchema = z
  .object({
    input: TypoReadoutValueScheme,
    label: TypoReadoutValueScheme,
    placeholder: TypoReadoutValueScheme,
    error: TypoReadoutValueScheme,
  })
  .default({
    input: TypoReadoutValueScheme.parse({}),
    label: TypoReadoutValueScheme.parse({}),
    placeholder: TypoReadoutValueScheme.parse({}),
    error: TypoReadoutValueScheme.parse({}),
  });

export const InputTextColorSchema = z
  .object({
    color: ColorReadoutValueScheme,
    errorColor: ColorReadoutValueScheme,
    placeholderColor: ColorReadoutValueScheme,
    labelColor: ColorReadoutValueScheme,
  })
  .merge(GlobalColorsStylesSchema.removeDefault())
  .default({
    color: ColorReadoutValueScheme.parse({}),
    errorColor: ColorReadoutValueScheme.parse({}),
    placeholderColor: ColorReadoutValueScheme.parse({}),
    labelColor: ColorReadoutValueScheme.parse({}),
  });

export const defaultBorder = {
  radius: 20,
  width: { left: 0, right: 0, top: 0, bottom: 0 },
  style: "SOLID",
  color: {
    id: "color$$1",
    custom: "#FFFFFF",
  },
};

export const GlobalInputObject = z.object({
  width: z.number().default(150).catch(150),
  height: z.number().default(20).catch(20),
  border: Border_v2Scheme.default(defaultBorder),
  hoverBorder: Border_v2Scheme.default(defaultBorder),
  activeBorder: Border_v2Scheme.default(defaultBorder),
  disabledBorder: Border_v2Scheme.default(defaultBorder),
  errorBorder: Border_v2Scheme.default(defaultBorder),
  background: GlobalColorsStylesSchema,
  font: InputFontSchema,
  text: InputTextColorSchema,
});

export const InputReadoutValueScheme = z
  .object({
    id: InputIdScheme.or(z.literal("custom"))
      .default("input$$1")
      .catch("input$$1"),
    custom: GlobalInputObject,
  })
  .default({ id: "input$$1", custom: GlobalInputObject.parse({}) })
  .catch({ id: "input$$1", custom: GlobalInputObject.parse({}) });

export type IInputReadoutValue = z.infer<typeof InputReadoutValueScheme>;

export const TInputSetItemSchema = z
  .object({
    id: InputIdScheme.default("input$$1").catch("input$$1"),
    inputStyleName: z.string().default("Input1").catch("Input1"),
  })
  .merge(GlobalInputObject);

export const InputSetSchema = z.array(TInputSetItemSchema).default([]);
export const InputSetSchemaMobile = z
  .array(TInputSetItemSchema.extend(inheritedFromBasic))
  .default([]);

export const GlobalLinkObjectSchema = z.object({
  text: GlobalColorsStylesSchema.removeDefault()
    .omit({ disabledColor: true })
    .catch(
      GlobalColorsStylesSchema.removeDefault()
        .omit({ disabledColor: true })
        .parse({})
    ),
  linkUnderline: z.boolean().optional().catch(false),
  linkHoverUnderline: z.boolean().optional().catch(false),
  linkActiveUnderline: z.boolean().optional().catch(false),
});

export const LinkReadoutValueScheme = z
  .object({
    id: LinkIdScheme.or(z.literal("custom"))
      .default("link$$1")
      .catch("link$$1"),
    custom: GlobalLinkObjectSchema,
  })
  .default({ id: "link$$1", custom: GlobalLinkObjectSchema.parse({}) })
  .catch({ id: "link$$1", custom: GlobalLinkObjectSchema.parse({}) });

export const TLinkSetItemSchema = z
  .object({
    id: LinkIdScheme.default("link$$1").catch("link$$1"),
    linkStyleName: z.string().default("Link").catch("Link"),
  })
  .merge(GlobalLinkObjectSchema);

export const LinkSetSchema = z.array(TLinkSetItemSchema).default([]);
export const LinkSetSchemaMobile = z
  .array(TLinkSetItemSchema.extend(inheritedFromBasic))
  .default([]);

export const TooltipGlobalObjectSchema = z.object({
  font: TypoReadoutValueScheme,
  text: GlobalColorsStylesSchema.removeDefault()
    .pick({ color: true })
    .default({ color: "#000000" }),
  background: GlobalColorsStylesSchema.removeDefault()
    .pick({ color: true })
    .default({ color: "#ffffff" }),
  arrow: z.boolean().default(true).catch(true),
  verticalPadding: z.number().default(10).catch(10),
  horizontalPadding: z.number().default(10).catch(10),
  maximumWidth: z.number().default(80).catch(80),
  borderRadius: z.number().default(10).catch(10),
});

export const TTooltipSetItemSchema = z
  .object({
    id: TooltipIdScheme,
    tooltipStyleName: z.string().default("Tooltip").catch("Tooltip"),
  })
  .merge(TooltipGlobalObjectSchema);

export const TooltipSetSchema = z.array(TTooltipSetItemSchema).default([]);
export const TooltipSetSchemaMobile = z
  .array(TTooltipSetItemSchema.extend(inheritedFromBasic))
  .default([]);

export const TooltipReadoutValueScheme = z
  .object({
    id: TooltipIdScheme.or(z.literal("custom")).catch("custom"),
    custom: TooltipGlobalObjectSchema,
  })
  .default({ id: "tooltip$$1", custom: TooltipGlobalObjectSchema.parse({}) })
  .catch({
    id: "tooltip$$1",
    custom: TooltipGlobalObjectSchema.parse({}),
  });

export const SystemPagesScheme = z.object({
  page404: z.object({
    id: z.number().default(-1).catch(-1),
  }),
  page500: z.object({
    id: z.number().default(-1).catch(-1),
  }),
  orderSummaryPage: z
    .object({
      id: z.number().default(-1),
      orderSummaryDetails: z.string().default("/autopay-payment-completed"),
    })
    .default({ id: -1, orderSummaryDetails: "/autopay-payment-completed" }),
  logOutPage: z.object({
    id: z.number().default(-1).catch(-1),
    technicalLogOut: z.string().default("").catch(""),
  }),
});

export const RowWidthOptionScheme = z.nativeEnum(TRowWidthOption);

export const RowWidthScheme = z.object({
  selectedOption: RowWidthOptionScheme.default(TRowWidthOption.PERCENT).catch(
    TRowWidthOption.PERCENT
  ),
  percent: z.number().default(100).catch(100),
  margin: z.object({
    right: z.number().default(0).catch(0),
    left: z.number().default(0).catch(0),
  }),
});

export const RowHeightOptionScheme = z
  .nativeEnum(RowHeightOption)
  .default(RowHeightOption.CONTENT)
  .catch(RowHeightOption.CONTENT);

export const RowHeightScheme = z.object({
  selectedOption: RowHeightOptionScheme,
  value: z.number().default(20).catch(20),
});
export type TRowHeight = z.infer<typeof RowHeightScheme>;

export const ThemePagesSectionScheme = z.object({
  width: RowWidthScheme,
  background: z.object({ color: ColorReadoutValueScheme }),
  border: Border_v2Scheme.default(defaultBorder),
  spacingBetweenSections: z.number().default(0).catch(0),
  spacingBetweenSlots: z.number().default(0).catch(0),
});

export const ThemePagesSlotScheme = z.object({
  background: z.object({ color: ColorReadoutValueScheme }),
  border: Border_v2Scheme.default(defaultBorder),
});

export const ThemePagesHeaderFooterScheme = z.object({
  stickyHeader: z.boolean().default(false).catch(false),
  fullWidthBgcHeader: z.boolean().default(false).catch(false),
  fullWidthBgcFooter: z.boolean().default(false).catch(false),
});

export const GlobalStylePagesScheme = z.object({
  pages: z.object({
    page: z.object({
      background: z.object({ color: ColorReadoutValueScheme }),
    }),
    modal: z.object({
      background: z.object({ color: ColorReadoutValueScheme }),
    }),
    headerFooter: ThemePagesHeaderFooterScheme,
    maxWidth: z.number().default(1444).catch(1444),
  }),
  section: ThemePagesSectionScheme,
  slot: ThemePagesSlotScheme,
});

export const GlobalSnackbarObjectScheme = z.object({
  timeToDisplay: z.number().default(5000).catch(5000),
  verticalPadding: z.number().default(0).catch(0),
  horizontalPadding: z.number().default(0).catch(0),
  maxWidth: z.number().default(376).catch(376),
  borderStyle: OutlineOptionsScheme,
  borderRadius: z.number().default(0).catch(0),
  borderThickness: z.number().default(0).catch(0),
  textStyle: TypoReadoutValueScheme,
  textColor: ColorReadoutValueScheme,
  backgroundColor: ColorReadoutValueScheme,
  borderColor: ColorReadoutValueScheme,
  iconClose: z.boolean().default(false).catch(false),
});

export const SnackbarItemScheme = z
  .object({
    id: SnackbarIdScheme,
    snackbarStyleName: z.string().default("Snackbar1").catch("Snackbar1"),
  })
  .merge(GlobalSnackbarObjectScheme);

export const SnackbarSetScheme = z.array(SnackbarItemScheme).default([]);
export const SnackbarSetSchemeMobile = z
  .array(SnackbarItemScheme.extend(inheritedFromBasic))
  .default([]);

export const GlobalOptionObject = z.object({
  button: ButtonIdScheme.default("button$$1").catch("button$$1"),
});

export const TOptionSetItemSchema = z
  .object({
    id: OptionIdScheme.default("option$$1").catch("option$$1"),
    optionName: z.string().default("Option1").catch("Option1"),
  })
  .merge(GlobalOptionObject);

export const OptionSetSchema = z.array(TOptionSetItemSchema).default([]);
export const OptionSetSchemaMobile = z
  .array(TOptionSetItemSchema.extend(inheritedFromBasic))
  .default([]);

export const GlobalAccordionObject = z.object({
  accordionAlignment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  accordionPadding: SpacingScheme.default({
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  }).catch({ top: 0, bottom: 0, right: 0, left: 0 }),
  accordionWidth: z.number().default(100).catch(100),
  accordionTypography: TypoReadoutValueScheme,
  accordionBackground: GlobalColorsStylesSchema,
  accordionText: GlobalColorsStylesSchema,
  accordionBorder: Border_v2Scheme.default(defaultBorder),
  accordionHoverBorder: Border_v2Scheme.default(defaultBorder),
  accordionActiveBorder: Border_v2Scheme.default(defaultBorder),
  accordionDisabledBorder: Border_v2Scheme.default(defaultBorder),
  openIcon: IIconSchema,
  closeIcon: IIconSchema,
});

export const GlobalDropdownObjectScheme = z.object({
  dropdownWidth: z.number().default(100).catch(100),
  dropdownHeight: z.number().default(40).catch(40),
  dropdownAlign: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  dropdownBackground: GlobalColorsStylesSchema.removeDefault()
    .omit({
      activeColor: true,
    })
    .default({
      color: ColorReadoutValueScheme.parse({}),
      hoverColor: ColorReadoutValueScheme.parse({}),
      disabledColor: ColorReadoutValueScheme.parse({}),
    }),
  dropdownBorder: Border_v2Scheme.default(defaultBorder),

  selectedItemTypography: TypoReadoutValueScheme,
  selectedItemLink: LinkReadoutValueScheme,
  selectedItemAlignment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  selectedItemDisabledTypography: TypoReadoutValueScheme,
  selectedItemDisabledColor: ColorReadoutValueScheme,

  openOnHover: z.boolean().default(false).catch(false),
  openIcon: IIconSchema,
  closeIcon: IIconSchema,

  errorMessageTypography: TypoReadoutValueScheme,
  errorMessageColor: ColorReadoutValueScheme,

  columnWidth: z.number().default(100).catch(100),
  columnHeight: z.number().default(200).catch(200),
  columnAlignment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  columnShadow: z.boolean().default(false).catch(false),
  columnShadowColor: ColorReadoutValueScheme,
  columnShadowPositionX: z.number().default(0).catch(0),
  columnShadowPositionY: z.number().default(0).catch(0),
  columnShadowBlur: z.number().default(0).catch(0),
  columnBorder: Border_v2Scheme.default(defaultBorder),

  dropdownItemTypography: TypoReadoutValueScheme,
  dropdownItemLink: LinkReadoutValueScheme,
  dropdownItemPadding: SpacingScheme.default({
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  }).catch({ top: 0, bottom: 0, right: 0, left: 0 }),
  dropdownItemAlignment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  dropdownItemBackground: GlobalColorsStylesSchema,
  dropdownItemDisabledTextColor: ColorReadoutValueScheme,
});

export const DropdownReadoutValueScheme = z
  .object({
    id: DropdownIdScheme.or(z.literal("custom"))
      .default("dropdown$$1")
      .catch("dropdown$$1"),
    custom: GlobalDropdownObjectScheme,
  })
  .default({ id: "dropdown$$1", custom: GlobalDropdownObjectScheme.parse({}) })
  .catch({ id: "dropdown$$1", custom: GlobalDropdownObjectScheme.parse({}) });

export type IDropdownReadoutValue = z.infer<typeof DropdownReadoutValueScheme>;

export const TAccordionSetItemSchema = z
  .object({
    id: AccordionIdScheme.default("accordion$$1").catch("accordion$$1"),
    accordionName: z.string().default("Accordion1").catch("Accordion1"),
  })
  .merge(GlobalAccordionObject);

export const AccordionSetSchema = z.array(TAccordionSetItemSchema).default([]);
export const AccordionSetSchemaMobile = z
  .array(TAccordionSetItemSchema.extend(inheritedFromBasic))
  .default([]);

export const DropdownItemScheme = z
  .object({
    id: DropdownIdScheme.default(`dropdown$$${1}`).catch(`dropdown$$${1}`),
    dropdownStyleName: z.string().default("Dropdown1").catch("Dropdown1"),
  })
  .merge(GlobalDropdownObjectScheme);

export const DropdownSetScheme = z.array(DropdownItemScheme).default([]);
export const DropdownSetSchemeMobile = z
  .array(DropdownItemScheme.extend(inheritedFromBasic))
  .default([]);

export const GlobalObjectSchemaBasic = z.object({
  buttons: ButtonSetSchema,
  inputs: InputSetSchema,
  links: LinkSetSchema,
  tooltips: TooltipSetSchema,
  snackbars: SnackbarSetScheme,
  options: OptionSetSchema,
  accordions: AccordionSetSchema,
  dropdowns: DropdownSetScheme,
  tableCells: z.lazy(() => TableCellSetScheme),
});

export const ThemeSettingsSchemeBasic = z.object({
  typography: TypoSetSchema,
  colorPalette: GlobalPaletteColorSchema,
  globalObjects: GlobalObjectSchemaBasic,
  stylePages: GlobalStylePagesScheme,
  advanceSettings: z.object({
    messages: AdvanceMessagesScheme,
    settings: AdvanceSettingsScheme,
    customCss: z.string().default("").catch(""),
    customJs: z.string().default("").catch(""),
    trackingScript: z.string().default("").catch(""),
  }),
  systemPages: SystemPagesScheme,
});

export const GlobalObjectSchemaMobile = z.object({
  buttons: ButtonSetSchemaMobile,
  inputs: InputSetSchemaMobile,
  links: LinkSetSchemaMobile,
  tooltips: TooltipSetSchemaMobile,
  snackbars: SnackbarSetSchemeMobile,
  options: OptionSetSchemaMobile,
  accordions: AccordionSetSchemaMobile,
  dropdowns: DropdownSetSchemeMobile,
  tableCells: z.lazy(() => TableCellSetSchemeMobile),
});

export const ThemeSettingsSchemeMobile = z.object({
  typography: TypoSetSchemaMobile,
  colorPalette: GlobalPaletteColorSchema,
  globalObjects: GlobalObjectSchemaMobile,
  stylePages: GlobalStylePagesScheme,
  advanceSettings: z.object({
    messages: AdvanceMessagesScheme,
    settings: AdvanceSettingsScheme,
    customCss: z.string().default("").catch(""),
    customJs: z.string().default("").catch(""),
    trackingScript: z.string().default("").catch(""),
  }),
  systemPages: SystemPagesScheme,
});
export const createTextStyleShowSettingScheme = (
  typoId?: TTypoId,
  colorId?: TColorId,
  show?: boolean
) =>
  z.object({
    show: z
      .boolean()
      .default(show ?? true)
      .catch(show ?? true),
    font: TypoReadoutValueScheme.default({
      id: typoId ?? "typo$$2",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 2,
        lineHeight: 2,
        letterSpacing: 2,
      },
    }).catch({
      id: typoId ?? "typo$$2",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 2,
        lineHeight: 2,
        letterSpacing: 2,
      },
    }),
    text: z
      .object({
        color: ColorReadoutValueScheme,
      })
      .default({
        color: { id: colorId ?? "color$$2", custom: "#000000" },
      })
      .catch({ color: { id: colorId ?? "color$$2", custom: "#000000" } }),
  });

export const textStyleShowSettingFallback = (
  typoId?: TTypoId,
  colorId?: TColorId,
  show?: boolean
) => createTextStyleShowSettingScheme(typoId, colorId, show).parse({});

export const affixFallback = {
  heading: "",
  spaceBetweenHeadingAndAttribute: 5,
  ...createTextStyleShowSettingScheme("typo$$1", "color$$1", false).parse({}),
};

export const productAffixFallback = {
  prefix: affixFallback,
  suffix: affixFallback,
  ...createTextStyleShowSettingScheme("typo$$7", "color$$2").parse({}),
};

export const productFromProductSliderAffixFallback = (
  typoId: TTypoId,
  colorId: TColorId
) => {
  return {
    prefix: affixFallback,
    suffix: affixFallback,
    ...createTextStyleShowSettingScheme(typoId, colorId).parse({}),
  };
};

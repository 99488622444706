import { IMyAccountContsents } from "../../dto/myAccount";

export const MYACCOUNT_CONSENTS: IMyAccountContsents = {
  a1: false,
  a2: true,
  d: true,
  email: false,
  sms: false,
  mobile: "123345456",
  emailAddress: "mock@test.xd",
};

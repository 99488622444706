import { AddressPostalBilling, TAddresses } from "./cartAddresses.type";
import { DeliveryTimeCode } from "./searchResults.types";
import { TUrl } from "./themeSettings/themeSettings.types";

type Date = string;

export enum ProductStatusOption {
  AVAILABLE = "AVAILABLE",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  PARTIALLY_AVAILABLE = "PARTIALLY_AVAILABLE",
}
export interface ICartSummary {
  cartValueFromProducts: number;
  cartValueAfterDiscounts: number;
  cartDeliveryCosts: number;
  amountToPay: number;
  loyaltyPoints: number;
  deliveryDateFrom?: Date; //is in cart step two and summary
  deliveryDateTo?: Date; //is in cart step two and summary
  taxValue?: number; //is in cart step two
}

export interface IOrderLine {
  deliveryTime: DeliveryTimeCode;
  orderLineId: number;
  quantity: number;
  productSku: string;
  productName: string;
  salePrice: number;
  price: number;
  nettoPrice: number;
  suggestedRetailPrice: number;
  oversize: boolean;
  inStock: boolean;
  stockLevelStatus?: ProductStatusOption; //is in cart step one
  availability: number;
  visibility: boolean;
  categoryIds: string[];
  createdAt: Date;
  initialAddDateTime: Date;
  auxiliaryBundleIdentifier: number;
  maxSingleProductQuantityInCart: number;
  secondBestPromotionPrice?: number; // is in box mini cart
  image?: TUrl | null; // is in box mini cart
  enabled: boolean;
}

export enum CART_ERROR_STATUS {
  PRODUCT_OUT_OF_STOCK = "PRODUCT_OUT_OF_STOCK",
  PRODUCT_PARTIALLY_AVAILABLE = "PRODUCT_PARTIALLY_AVAILABLE",
  DELIVERY_CHANNELS_NOT_AVAILABLE = "DELIVERY_CHANNELS_NOT_AVAILABLE",
  PRODUCT_PRICE_HAS_CHANGED = "PRODUCT_PRICE_HAS_CHANGED",
  PRODUCT_DISABLED = "PRODUCT_DISABLED",
  PRODUCT_DELIVERY_TIME_NOT_AVAILABLE = "PRODUCT_DELIVERY_TIME_NOT_AVAILABLE",
  PRODUCT_NOT_EXIST = "PRODUCT_NOT_EXIST",
}

export interface ICartError {
  status: CART_ERROR_STATUS;
}

export interface ICartResponse {
  cartViewResponse: {
    cartSummary: ICartSummary;
    orderLines: IOrderLine[];
    vouchers: [];
    coupons: [];
    cards: [];
    cartUserStatus: "ANONYMOUS" | "REGISTERED";
    checkPackageBeforePickingUp: false;
  };
  errors: ICartError[];
}

export interface IMiniCartResponse {
  content: {
    cartSummary: ICartSummary;
    orderLines: IOrderLine[];
    wishlist: {
      products: any[];
    };
  };
}

export interface IPaymentStatusResponse {
  paymentStatus: OrderStatusType;
}
export interface IMiniCartCountResponse {
  count: number;
}

export interface IRealizationDays {
  realizationDays: number;
  pickUpPointType: any; //"STORE" ZABKA ORLEN POSTAL;
  cashOnDelivery: boolean;
}

export interface IPickUpPointTypes {
  name: string;
  type: DeliveryType;
  price: number;
}

export enum ChannelType {
  STORE = "STORE",
  PICK_UP_AT_POINT = "PICK_UP_AT_POINT",
  POCZTEX = "POCZTEX",
  DPD = "DPD",
  UPS = "UPS",
  INPOST = "INPOST",
}

export enum DeliveryType {
  POSTAL = "POSTAL",
  PACKSTATION = "PACKSTATION",
  STORE = "STORE",
}

export enum PaymentType {
  E_WALLET = "E_WALLET",
  GIFT_CARD = "GIFT_CARD",
  PAYMENT_IN_ADVANCE = "PAYMENT_IN_ADVANCE",
  CASH_ON_DELIVERY = "CASH_ON_DELIVERY",
}

export enum OrderStatusType {
  NEW = "NEW",
  WAITING_FOR_PAYMENT = "WAITING_FOR_PAYMENT",
  COMPLETED = "COMPLETED",
}

export interface IPayment {
  paymentType: PaymentType;
  enabled: boolean;
  selected: boolean;
}

export interface IDeliveryChannel {
  channelName: string;
  channelType: ChannelType;
  pickUpPointsTypes: IPickUpPointTypes[];
  price: number;
  enabled: boolean;
  inaccessibilityType?: string; //TODO to jest enum pewnie
  checkPackagePrice: number;
  checkPackageAvailable?: boolean;
  pickUpPointsAddresses?: any[]; //TODO co to
  selected: boolean;
  selectedAddress: TAddresses | null;
  addresses: TAddresses[];
  pickUpPointRealizationDays: IRealizationDays[];
  deliveryDateFrom: Date;
  deliveryDateTo: Date;
  oneStoreActive: boolean;
}

export interface IDeliveryAndPaymentResponse {
  errors: any[];
  response: {
    deliveryChannels: IDeliveryChannel[];
    cartSummary: ICartSummary;
    orderLines: IOrderLine[];
    payments: IPayment[];
    customerCards: any[];
    selectedCards: any[];
    cartUserStatus: "ANONYMOUS" | "UNREGISTERED" | "REGISTERED";
    checkPackageBeforePickingUp: boolean;
    billingAddress: AddressPostalBilling | null;
    billingAddresses: AddressPostalBilling[];
  };
}

export interface IProductsCountRequest {
  productSku: string;
  quantity: number;
}

export type IProductsCountResponse = [
  {
    loyaltyStatus: "OK";
  }
];

export interface ProductQuantityWithMaxError {
  data: {
    code: number;
    data: {
      PRODUCT_CART_LIMIT_QUANTITY: string;
      PRODUCT_QUANTITY_STOCK_LIMIT: string;
    };
  };
}

export interface IAddToCartQuantity {
  status: "OK" | "ERROR";
  quantity: number;
}

export interface IOrderConfirmationResponse {
  orderId: string;
  deliveryDate: Date;
  deliveryDateFrom: Date;
  deliveryDateTo: Date;
  repaymentUrl: string;
  creationDate: Date;
  email: string;
  cartSummary: ICartSummary;
  deliveryAddress: {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    country: string;
    city: string;
    zipCode: string;
    street: string;
    streetNumber: string;
    flatNumber: string;
    storeName: string;
    pickUpPointName: string;
    storeCode: string;
    storeClickAndCollect: "NONE";
    packstationCode: string;
    packstationAvailable: boolean;
    billing: boolean;
    companyName: string;
    nip: string;
    cif: string;
    registrationCode: string;
    bankName: string;
    bankAccountNumber: string;
    cnp: string;
    voivodeship: string;
    floor: string;
    pni: number;
    pickUpPointType: "AUTOMAT_BIEDRONKA";
  };
  billingAddress: {
    id: number;
    firstName: string;
    lastName: string;
    phone: string;
    country: string;
    city: string;
    zipCode: string;
    street: string;
    streetNumber: string;
    flatNumber: string;
    storeName: string;
    pickUpPointName: string;
    storeCode: string;
    storeClickAndCollect: "NONE";
    packstationCode: string;
    packstationAvailable: boolean;
    billing: boolean;
    companyName: string;
    nip: string;
    cif: string;
    registrationCode: string;
    bankName: string;
    bankAccountNumber: string;
    cnp: string;
    voivodeship: string;
    floor: string;
    pni: number;
    pickUpPointType: "AUTOMAT_BIEDRONKA";
  };
  payments: [
    {
      paymentType: PaymentType;
      amount: number;
    }
  ];
  orderLines: {
    orderLineId: number;
    quantity: number;
    productSku: string;
    productName: string;
    salePrice: number;
    secondBestPromotionPrice: number;
    price: number;
    nettoPrice: number;
    suggestedRetailPrice: number;
    oversize: true;
    inStock: true;
    stockLevelStatus: "AVAILABLE";
    availability: number;
    visibility: true;
    categoryIds: string[];
    createdAt: Date;
    initialAddDateTime: Date;
    catalogPromotionDetails: {
      catalogPromotionTitle: string;
      catalogPromotionDescription: string;
      loyaltyPromotion: boolean;
    };
    cartPromotionDetails: {
      cartPromotionTitle: string;
      cartPromotionDescription: string;
    };
    auxiliaryBundleIdentifier: number;
    maxSingleProductQuantityInCart: number;
  }[];
  cartUserStatus: "ANONYMOUS" | "REGISTERED";
  customerInfo: {
    customerEmail: string;
    customerAnonymous: boolean;
    customerAssigned: boolean;
  };
}

export interface IPlaceOrderResponse {
  response: {
    orderId: string;
    redirectUrl?: string; //only when select advance payment
    products: {
      productId: number;
      quantity: number;
      commissionId: number;
      gross: number;
    }[];
  };
  errors: [
    {
      loyaltyStatus: string;
      errorMessage: string;
    }
  ];
}

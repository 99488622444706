import {
  ISummaryB2B_StepTwo,
  PaymentMethodOptionB2B,
} from "../../../dto/cartB2B.types";

export const ecpCartSummaryB2BResponse_basic: ISummaryB2B_StepTwo = {
  contractorOrderResponseList: [
    {
      orderId: "9876000201744",
      netValue: 0,
      grossValue: 0,
      creationDate: "2024-05-21T10:13:56.428048Z",
      paymentForm: PaymentMethodOptionB2B.CREDIT,
      promiseDeliveryDate: "2024-05-21",

      orderPositions: [
        {
          sku: "test3002",
          grossValue: 0,
          netValue: 0,
          quantity: 20,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3005",
          grossValue: 0,
          netValue: 0,
          quantity: 10,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3005",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
      ],
    },
    {
      orderId: "9876000201751",
      netValue: 0,
      grossValue: 0,
      creationDate: "2024-05-21T10:24:16.242879Z",
      paymentForm: PaymentMethodOptionB2B.CASH,
      promiseDeliveryDate: "2024-05-21",
      orderPositions: [
        {
          sku: "test3002",
          grossValue: 0,
          netValue: 0,
          quantity: 10,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3005",
          grossValue: 0,
          netValue: 0,
          quantity: 10,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
      ],
    },
    {
      orderId: "9876000201768",
      netValue: 0,
      grossValue: 0,
      creationDate: "2024-05-21T10:29:18.08093Z",
      paymentForm: PaymentMethodOptionB2B.CASH,
      promiseDeliveryDate: "2024-05-21",
      orderPositions: [
        {
          sku: "test3005",
          grossValue: 0,
          netValue: 0,
          quantity: 10,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3002",
          grossValue: 0,
          netValue: 0,
          quantity: 20,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3006",
          grossValue: 0,
          netValue: 0,
          quantity: 1,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
      ],
    },
    {
      orderId: "9876000201690",
      netValue: 500,
      grossValue: 550,
      creationDate: "2024-05-21T09:02:54.6692Z",
      paymentForm: PaymentMethodOptionB2B.CASH,
      promiseDeliveryDate: "2024-05-21",
      orderPositions: [
        {
          sku: "test3002",
          grossValue: 55,
          netValue: 50,
          quantity: 10,
          totalNetValue: 500,
          totalGrossValue: 550,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3005",
          grossValue: 0,
          netValue: 0,
          quantity: 90,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
      ],
    },
    {
      orderId: "9876000201706",
      netValue: 500,
      grossValue: 550,
      creationDate: "2024-05-21T09:02:54.911409Z",
      paymentForm: PaymentMethodOptionB2B.CASH,
      promiseDeliveryDate: "2024-05-21",
      orderPositions: [
        {
          sku: "test3002",
          grossValue: 55,
          netValue: 50,
          quantity: 10,
          totalNetValue: 500,
          totalGrossValue: 550,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3005",
          grossValue: 0,
          netValue: 0,
          quantity: 90,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
      ],
    },
    {
      orderId: "9876000201713",
      netValue: 500,
      grossValue: 550,
      creationDate: "2024-05-21T09:16:15.345237Z",
      paymentForm: PaymentMethodOptionB2B.CREDIT,
      promiseDeliveryDate: "2024-05-21",
      orderPositions: [
        {
          sku: "test3005",
          grossValue: 0,
          netValue: 0,
          quantity: 90,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3002",
          grossValue: 55,
          netValue: 50,
          quantity: 10,
          totalNetValue: 500,
          totalGrossValue: 550,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
      ],
    },
    {
      orderId: "9876000201720",
      netValue: 0,
      grossValue: 0,
      creationDate: "2024-05-21T10:07:59.428738Z",
      paymentForm: PaymentMethodOptionB2B.CREDIT,
      promiseDeliveryDate: "2024-05-21",
      orderPositions: [
        {
          sku: "test3002",
          grossValue: 0,
          netValue: 0,
          quantity: 20,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3005",
          grossValue: 0,
          netValue: 0,
          quantity: 10,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
      ],
    },
    {
      orderId: "9876000201737",
      netValue: 0,
      grossValue: 0,
      creationDate: "2024-05-21T10:12:21.806932Z",
      paymentForm: PaymentMethodOptionB2B.CREDIT,
      promiseDeliveryDate: "2024-05-21",
      orderPositions: [
        {
          sku: "test3002",
          grossValue: 0,
          netValue: 0,
          quantity: 20,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3005",
          grossValue: 0,
          netValue: 0,
          quantity: 10,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
      ],
    },
    {
      orderId: "9876000202137",
      netValue: 0,
      grossValue: 0,
      creationDate: "2024-05-21T10:12:21.806932Z",
      paymentForm: PaymentMethodOptionB2B.CREDIT,
      promiseDeliveryDate: "2024-05-28",
      orderPositions: [
        {
          sku: "test3002",
          grossValue: 0,
          netValue: 0,
          quantity: 20,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3005",
          grossValue: 0,
          netValue: 0,
          quantity: 10,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3008",
          grossValue: 0,
          netValue: 0,
          quantity: 20,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3001",
          grossValue: 0,
          netValue: 0,
          quantity: 10,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3009",
          grossValue: 0,
          netValue: 0,
          quantity: 20,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
        {
          sku: "test3012",
          grossValue: 0,
          netValue: 0,
          quantity: 10,
          totalNetValue: 0,
          totalGrossValue: 0,
          name: "test3002",
          coverPhoto: null,
          unitOfMeasurementLabel: "szt",
        },
      ],
    },
  ],
};

import { getButtonPaths } from "@ecp-boxes/shared/hooks/useLinkContentAction";
import {
  ILinkContent,
  SelectedGoToOption,
} from "../../settingsPatterns/contentPatterns.types";
import { PortalPath } from "../../shared/portalPath/portalPath";
import { EffectsData } from "../../structure/Contexts/SectionEffectsContext";
import { IAddToWishlistSetting } from "@ecp-boxes/settingsPatterns/contentPatterns.scheme";

const OFFSET_CORRECTION = 1;
const SCROLL_DURATION = 700; //ms
const easeInOutQuad = (t: number) =>
  t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

export const checkIfButtonShouldBeDisabled = (
  isBoxSelected: boolean,
  link: ILinkContent,
  href: string | undefined
) => {
  if (link.selectedGoToOption === SelectedGoToOption.GO_TO_PAGE) {
    return !href;
  }

  if (link.selectedGoToOption === SelectedGoToOption.GO_TO_ROW) {
    return !link.goToRowSetting.rowId;
  }

  if (link.selectedGoToOption === SelectedGoToOption.OPEN_POPUP) {
    return !link.openPopupSetting.rowId;
  }

  if (link.selectedGoToOption === SelectedGoToOption.CLOSE_POPUP) {
    return !link.closePopupSetting.rowId;
  }

  if (
    link.selectedGoToOption === SelectedGoToOption.CHANGE_ORDER_INSIDE_STACK
  ) {
    return !isBoxSelected;
  }
  return false;
};

export const scrollIntoStackPosition = (
  link: ILinkContent,
  effectsData: EffectsData[]
) => {
  if (link.selectedGoToOption !== SelectedGoToOption.CHANGE_ORDER_INSIDE_STACK)
    return;

  const firstSectionId = getButtonPaths(link).map((el) =>
    new PortalPath(el).getFirstSection()
  )[0];

  const stickyHeaderHeight = effectsData
    .filter((el) => el.sticky)
    .reduce((acc, el) => acc + (el.height ?? 0), 0);

  const element = effectsData.find(
    (data) => data.sectionId?.toString() === firstSectionId?.toString()
  )?.ref?.current;

  const scrollWrapper = document.getElementById("scroll-wrapper");
  if (!element || !scrollWrapper) return;
  const previousScrollBehavior = scrollWrapper.style.scrollBehavior;
  scrollWrapper.style.scrollBehavior = "unset";

  const startScroll = scrollWrapper.scrollTop;
  let startTime: DOMHighResTimeStamp | null = null;

  const scrollAnimation = (currentTime: DOMHighResTimeStamp) => {
    if (!startTime) startTime = currentTime;
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / SCROLL_DURATION, 1);

    const sectionTop =
      element.getBoundingClientRect().top +
      scrollWrapper.scrollTop -
      stickyHeaderHeight;
    const distance = sectionTop - startScroll;
    const easedProgress = easeInOutQuad(progress);
    const newScrollPosition = startScroll + distance * easedProgress;

    scrollWrapper.scrollTo(0, newScrollPosition);

    if (progress < 1) {
      requestAnimationFrame(scrollAnimation);
    } else {
      scrollWrapper.style.scrollBehavior = previousScrollBehavior;
    }
  };

  requestAnimationFrame(scrollAnimation);
};

export const scrollIntoRowPosition = (
  link: ILinkContent,
  path: PortalPath,
  effectsData: EffectsData[]
) => {
  if (link.selectedGoToOption !== SelectedGoToOption.GO_TO_ROW) return;

  const getScrollTargetOffset = () => {
    const buttonIdx = effectsData.findIndex(
      (el) => el.sectionId === path.getFirstSection()
    );
    const buttonOffset =
      (effectsData[buttonIdx]?.height ?? 0) +
      (effectsData[buttonIdx]?.offset ?? 0);

    const scrollTargetIdx = effectsData.findIndex(
      (el) => el.sectionId?.toString() === link.goToRowSetting.rowId
    );
    const topTargetSection =
      (effectsData[scrollTargetIdx]?.sumHeight ?? 0) -
      (effectsData[scrollTargetIdx]?.height ?? 0);

    //obliczanie offsetu przesunięcia dla sticky elementów między sekcją buttona a sekcją target
    const spaceBetweenButtonAndTarget = [...effectsData]
      .splice(buttonIdx + 1, scrollTargetIdx - buttonIdx)
      .filter((el) => el.sticky)
      .reduce((acc, el) => acc + (el.height ?? 0), 0);

    //obliczanie offsetu przesunięcia w przypadku gdy sekcja buttona nie jest sticky
    const spaceBetweenTopPageAndButton = [...effectsData]
      .splice(0, buttonIdx - 1)
      .filter((el) => el.sticky)
      .reduce((acc, el) => acc + (el.height ?? 0), 0);

    if (effectsData[buttonIdx].sticky) {
      return (
        topTargetSection -
        buttonOffset -
        spaceBetweenButtonAndTarget +
        OFFSET_CORRECTION
      );
    } else {
      return (
        topTargetSection -
        spaceBetweenButtonAndTarget -
        spaceBetweenTopPageAndButton +
        OFFSET_CORRECTION
      );
    }
  };

  document?.getElementById("scroll-wrapper")?.scrollTo({
    top: getScrollTargetOffset(),
  });
};

export const getAddToWishlistSettings = (
  addToWishlistSetting: IAddToWishlistSetting,
  isOnWishlist?: boolean
) => {
  return {
    label:
      addToWishlistSetting[
        isOnWishlist ? "removeFromWishlistLabel" : "addToWishlistLabel"
      ],
    style:
      addToWishlistSetting[
        isOnWishlist ? "removeFromWishlistStyle" : "addToWishlistStyle"
      ],
  };
};

import { TSrpProductType } from "../dto/searchResults.types";
import { API, api } from "./api";

type TProductWishList = {
  sku: string;
  type: TSrpProductType;
};

export const wishList = api
  .enhanceEndpoints({
    addTagTypes: ["WISH_LIST"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getWishList: builder.query<
        { products: TProductWishList[]; size: number },
        void
      >({
        query: () => {
          return { url: API.getWishList.endpoint() };
        },
        providesTags: ["WISH_LIST"],
      }),
      postAddProductToWishList: builder.mutation<void, TProductWishList>({
        query: (body: TProductWishList) => {
          return {
            url: API.postAddProductToWishList.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: [
          "SEARCH_RESULTS",
          "WISH_LIST",
          "PRODUCT_SLIDER_PRODUCTS",
        ],
      }),
      deleteProductFromWishList: builder.mutation<void, TProductWishList>({
        query: (body: TProductWishList) => {
          return {
            url: API.deleteProductFromWishList.endpoint(),
            method: "DELETE",
            body,
          };
        },
        invalidatesTags: [
          "SEARCH_RESULTS",
          "WISH_LIST",
          "PRODUCT_SLIDER_PRODUCTS",
        ],
      }),
    }),
  });
export const {
  useGetWishListQuery,
  usePostAddProductToWishListMutation,
  useDeleteProductFromWishListMutation,
} = wishList;

export const DELIVERY_SCHEDULE_COLUMNS_NAMES_RESPONSE = {
    columnNames: ["TYPE0", "TYPE1", "TYPE2"],
  };
  
  export const DELIVERY_SCHEDULE_SINGLE_RESULT_RESPONSE = {
    result: {
      TYPE0: 111,
      TYPE1: 222,
      TYPE2: 333,
    },
  };
  
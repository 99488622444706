export const SUGGESTIONS = {
  suggestions: [
    {
      phrase: "abc",
    },
    {
      phrase: "abc",
      categoryId: "02",
      categoryName: "KSIĄŻKI, MUZYKA, FILM",
    },
    {
      phrase: "abc",
      categoryId: "03",
      categoryName: "ZABAWKI, GRY",
    },
    {
      phrase: "abc",
      categoryId: "01",
      categoryName: "DZIECKO, MAMA",
    },
    {
      phrase: "simba abc",
    },
    {
      phrase: "abc maturzysty",
    },
    {
      phrase: "abc ucznia",
    },
    {
      phrase: "abc ucznia tom",
    },
    {
      phrase: "abc beżowa",
    },
    {
      phrase: "abc beżowa 100x135",
    },
  ],
};

export const SUGGESTIONS_B2B = {
  suggestions: [
    {
      phrase: "abc",
    },
    {
      phrase: "abc",
      categoryId: "02",
      categoryName: "KSIĄŻKI, MUZYKA, FILM",
    },
    {
      phrase: "abc",
      categoryId: "03",
      categoryName: "ZABAWKI, GRY",
    },
    {
      phrase: "abc",
      categoryId: "01",
      categoryName: "DZIECKO, MAMA",
    },
    {
      phrase: "simba abc",
    },
    {
      phrase: "abc maturzysty",
    },
    {
      phrase: "abc ucznia",
    },
    {
      phrase: "abc ucznia tom",
    },
    {
      phrase: "abc beżowa",
    },
    {
      phrase: "abc beżowa 100x135",
    },
  ],
};

import { nanoid } from "nanoid";
import {
  IBoxes,
  ISectionPageBuilder,
  PageWithBoxesPageBuilder,
} from "../../../dto/page/pageBuilder.types";
import { PageCategoryType } from "../../../dto/page/union.types";
import {
  AUTH_STATUS,
  PUBLICATION_COND,
} from "../../../dto/publicationConditions.types";

const boxes: IBoxes = {
  1: {
    id: 1,
    name: "Box Text",
    type: "TEXT",

    contents: [],
    content: {
      text: `[{"type":"paragraph","children":[{"text":"Box nr 1; Begin: 1970; MOBILE, DESKTOP; LOGGED; Group: dwadwa"}],"align":"left"}]`,
    },
    settings: {},
    local: true,
    messages: { asd: "asd" },
    contentSettings: null,
    customCss: "",
    displaySettings: null,
    customJs: "",
    mobileContentSettings: null,
    mobileDisplaySettings: null,
    mobileSettings: {},
    version: 0,
  },
  2: {
    id: 2,
    name: "Box Text",
    type: "TEXT",

    contents: [],
    content: {
      text: `[{"type":"paragraph","children":[{"text":"Box nr 2; Begin: 2023; MOBILE, DESKTOP; ANONYMOUS"}],"align":"left"}]`,
    },
    settings: {},
    local: true,
    messages: { asd: "asd" },
    contentSettings: null,
    customCss: "",
    displaySettings: null,
    customJs: "",
    mobileContentSettings: null,
    mobileDisplaySettings: null,
    mobileSettings: {},
    version: 0,
  },
  3: {
    id: 3,
    name: "Box Text",
    type: "TEXT",

    contents: [],
    content: {
      text: `[{"type":"paragraph","children":[{"text":"Box nr 3; Begin: 1970; DESKTOP; ALL USERS"}],"align":"left"}]`,
    },
    settings: {},
    local: true,
    messages: { asd: "asd" },
    contentSettings: null,
    customCss: "",
    displaySettings: null,
    customJs: "",
    mobileContentSettings: null,
    mobileDisplaySettings: null,
    mobileSettings: {},
    version: 0,
  },
  4: {
    id: 4,
    name: "Box Text",
    type: "TEXT",

    contents: [],
    content: {
      text: `[{"type":"paragraph","children":[{"text":"Box nr 4; Begin: 1970; MOBILE; ANONYMOUS"}],"align":"left"}]`,
    },
    settings: {},
    local: true,
    messages: { asd: "asd" },
    contentSettings: null,
    customCss: "",
    displaySettings: null,
    customJs: "",
    mobileContentSettings: null,
    mobileDisplaySettings: null,
    mobileSettings: {},
    version: 0,
  },
};

const section1: ISectionPageBuilder = {
  id: 1,
  customCss: "",
  uuid: nanoid(),
  mobileSubSection: false,
  name: "sekcja pierwsza",
  slots: [
    {
      id: 1433333,
      customCss: "",
      columns: 6,
      groupingMode: "NONE",
      stacks: [
        {
          publications: [
            {
              id: 123,
              customCss: "",
              uuid: nanoid(),
              alignment: "LEFT",
              published: true,
              end: "",
              begin: "1970-01-01T08:43:40.267138Z",
              boxId: 1,
              publicationConditions: [
                { type: PUBLICATION_COND.MOBILE, mobile: true },
                { type: PUBLICATION_COND.DESKTOP, desktop: true },
                { type: PUBLICATION_COND.USER_AUTH, state: AUTH_STATUS.LOGGED },
              ],
              width: "FULL",
            },
          ],
        },
      ],
      sections: [],
      name: "slot 3",
      displaySettings: {},
      mobileDisplaySettings: {},
    },
    {
      id: 33,
      customCss: "",
      columns: 6,
      groupingMode: "NONE",
      stacks: [
        {
          publications: [
            {
              id: 12341119,
              customCss: "",
              uuid: nanoid(),
              alignment: "LEFT",
              published: true,
              end: "",
              begin: "2023-01-01T08:43:40.267138Z",
              boxId: 2,
              publicationConditions: [
                { type: PUBLICATION_COND.MOBILE, mobile: true },
                { type: PUBLICATION_COND.DESKTOP, desktop: true },
                {
                  type: PUBLICATION_COND.USER_AUTH,
                  state: AUTH_STATUS.ANONYMOUS,
                },
              ],
              width: "FULL",
            },
          ],
        },
      ],
      sections: [],
      name: "slot 3",
      displaySettings: {},
      mobileDisplaySettings: {},
    },
  ],
  displaySettings: {},
  mobileDisplaySettings: {},
};

const section2: ISectionPageBuilder = {
  id: 23,
  customCss: "",
  uuid: nanoid(),
  mobileSubSection: false,
  name: "sekcja druga",
  slots: [
    {
      id: 1231,
      customCss: "",
      columns: 6,
      groupingMode: "NONE",
      stacks: [
        {
          publications: [
            {
              id: 234,
              customCss: "",
              uuid: nanoid(),
              alignment: "LEFT",
              published: true,
              end: "",
              begin: "1970-01-01T08:43:40.267138Z",
              boxId: 3,
              publicationConditions: [
                { type: PUBLICATION_COND.MOBILE, mobile: false },
                { type: PUBLICATION_COND.DESKTOP, desktop: true },
              ],
              width: "FULL",
            },
          ],
        },
      ],
      sections: [],
      name: "slot 3",
      displaySettings: {},
      mobileDisplaySettings: {},
    },
    {
      id: 2422,
      customCss: "",
      columns: 6,
      groupingMode: "NONE",
      stacks: [
        {
          publications: [
            {
              id: 1231,
              customCss: "",
              uuid: nanoid(),
              alignment: "LEFT",
              published: true,
              end: "",
              begin: "1970-01-01T08:43:40.267138Z",
              boxId: 4,
              publicationConditions: [
                { type: PUBLICATION_COND.MOBILE, mobile: true },
                { type: PUBLICATION_COND.DESKTOP, desktop: false },
                {
                  type: PUBLICATION_COND.USER_AUTH,
                  state: AUTH_STATUS.ANONYMOUS,
                },
              ],
              width: "FULL",
            },
          ],
        },
      ],
      sections: [],
      name: "slot 4",
      displaySettings: {},
      mobileDisplaySettings: {},
    },
  ],
  displaySettings: {},
  mobileDisplaySettings: {},
};

export const advancePublicationConditions: Pick<
  PageWithBoxesPageBuilder,
  "page" | "boxes"
> = {
  page: {
    sections: [section1, section2],
    articleCategoryId: null,
    articleId: null,
    content: [],
    createdBy: "",
    creationDate: "",
    headerId: 2,
    footerId: 3,
    folderId: 0,
    id: 1,
    indexed: false,
    modificationDate: "",
    modifiedBy: "",
    name: "",
    onSitemap: false,
    order: 0,
    productCategoryCode: null,
    productCategoryHidden: false,
    productSku: null,
    conceptCode: null,
    removable: false,
    rootId: null,
    sectionsInherited: false,
    settings: {},
    type: PageCategoryType.REGULAR,
    url: "",
    urlAutoGenerated: false,
    version: 0,
    draft: false,
  },
  boxes: boxes,
};

import {
  IOrderDetailsResponse,
  IOrdersHistoryResponse,
} from "../../dto/myOrders.types";

export const MY_ORDERS_HISTORY_MOCK: IOrdersHistoryResponse = {
  orders: [
    {
      orderId: "testowy order",
      orderStatus: "Nowe",
      creationDate: new Date(),
      orderValue: 7,
      products: [
        {
          productSku: "5793904",
          price: 10.99,
          quantity: 1,
          status: "Nowe",
        },
      ],
      billingAddress: {
        type: "POSTAL",
        firstName: "Lidka",
        lastName: "Nowak",
        phone: "66666666",
        country: "POLAND",
        city: "Zamość",
        zipCode: "11-111",
        street: "SZybka",
        streetNumber: "11",
        flatNumber: "15",
      } as any,
      mainShippingAddress: {
        type: "POSTAL",
        firstName: "Lidka",
        lastName: "Nowak",
        phone: "66666666",
        country: "POLAND",
        city: "Zamość",
        zipCode: "11-111",
        street: "SZybka",
        streetNumber: "11",
        flatNumber: "15",
      },
      canBeCancelled: true,
      canBePaid: true,
    },
    {
      orderId: "testowy orasdder",
      orderStatus: "Nowe",
      creationDate: new Date(),
      orderValue: 7,
      products: [
        {
          productSku: "579323904",
          price: 10.99,
          quantity: 1,
        },
      ],
      billingAddress: {
        type: "POSTAL",
        firstName: "Lidka",
        lastName: "Nowak",
        phone: "66666666",
        country: "POLAND",
        city: "Zamość",
        zipCode: "11-111",
        street: "SZybka",
        streetNumber: "11",
        flatNumber: "15",
      } as any,
      mainShippingAddress: {
        type: "POSTAL",
        firstName: "Lidka",
        lastName: "Nowak",
        phone: "66666666",
        country: "POLAND",
        city: "Zamość",
        zipCode: "11-111",
        street: "SZybka",
        streetNumber: "11",
        flatNumber: "15",
      } as any,
      canBeCancelled: false,
      canBePaid: false,
    },
  ],
  pageParams: { page: 1, totalPages: 1, totalElements: 3 },
} as IOrdersHistoryResponse;

export const MY_ORDERS_DETAILS_MOCK: IOrderDetailsResponse = {
  order: {
    orderId: "testowy order",
    email: "Adam@9bits.pl",
    uuid: "f2b19411-g916-4342-bb96-0105d19hhh39",
    goldId: "gold_id",
    customerId: 5000000,
    orderStatus: "Nowe",
    creationDate: new Date(),
    orderValue: 7,
    portalOrderDispatches: [
      {
        orderDispatchId: "aaaaaaa",
        orderDispatchStatus: "Oczekuje na płatność",
        lastChangeDateTime: new Date(),
        deliveryDate: new Date(),
        deliveryChannelType: "UPS",
        products: [
          {
            orderLinesIds: ["353"],
            productSku: "5793904",
            productName: "Pan Tadeusz",
            price: 10.99,
            lineStatus: {
              status: "Anulowana",
              cancellable: false,
            },
            quantity: 1,
          },
        ],
        shippingAddress: {
          type: "POSTAL",
          firstName: "Lidka",
          lastName: "Nowak",
          phone: "66666666",
          country: "POLAND",
          city: "Zamość",
          zipCode: "11-111",
          street: "SZybka",
          streetNumber: "11",
          flatNumber: "15",
        } as any,
        dispatchValue: 10.99,
        deliveryCost: 10,
        canChangeShippingAddress: true,
        documents: [],
        allowedOperations: {
          canBeCancelled: true,
        },
        orderDispatchUuid: "f2b19411-g916-4342-bb96-0105d19hhh39",
      },
    ],
    billingAddress: {
      type: "POSTAL",
      firstName: "Lidka",
      lastName: "Nowak",
      phone: "66666666",
      country: "POLAND",
      city: "Zamość",
      zipCode: "11-111",
      street: "SZybka",
      streetNumber: "11",
      flatNumber: "15",
    } as any,
    mainShippingAddress: {
      type: "POSTAL",
      firstName: "Lidka",
      lastName: "Nowak",
      phone: "66666666",
      country: "POLAND",
      city: "Zamość",
      zipCode: "11-111",
      street: "SZybka",
      streetNumber: "11",
      flatNumber: "15",
    } as any,
    allowedOperations: {
      canChangeBillingAddress: true,
      canChangeShippingAddresses: true,
      canBeCancelled: false,
      canBePaid: false,
    },
    orderPayment: {
      cashOnDeliveryAmount: 0,
      bankTransferAmount: 50.99,
      eWalletAmount: 0,
      giftCardAmount: 0,
      deliveryCostsAmount: 3,
      paymentTypes: ["CASH_ON_DELIVERY"],
    },
  },
};

import { useContext } from "react";
import { DeviceTypeMobileContext } from "../../structure/Contexts/DeviceTypeContext";

const useIsMobilePortal = (): boolean => {
  const platform = useContext(DeviceTypeMobileContext);

  return platform === "MOBILE";
};

export default useIsMobilePortal;

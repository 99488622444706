export const MARKETING_BANNERS_MOCK = [
  {
    bannerUrl:
      "https://uat-minio-ecp.nd0.pl/ecp-media-tenant-belutti-uat/products-images/2/8/b/a/28ba8968d0048d7f34d22f361803476e24fc4d0b_BQM_035_c_001.jpg",
    redirectUrl: "/",
    seoDescription: "111",
  },
  {
    bannerUrl:
      "https://uat-resizer.mysellcentive.com/1080x/https:…11e9e7dae15340ac7e8bb80e17363a5fd1b_BAP120_C2.jpg",
    redirectUrl: "/login",
    seoDescription: "222",
  },
  {
    bannerUrl:
      "https://uat-minio-ecp.nd0.pl/ecp-media-tenant-belutti-uat/products-images/2/8/b/a/28ba8968d0048d7f34d22f361803476e24fc4d0b_BQM_035_c_001.jpg",
    redirectUrl: "/register",
    seoDescription: "333",
  },
  {
    bannerUrl:
      "https://uat-minio-ecp.nd0.pl/ecp-media-tenant-belutti-uat/products-images/2/8/b/a/28ba8968d0048d7f34d22f361803476e24fc4d0b_BQM_035_c_001.jpg",
    redirectUrl: "/search",
    seoDescription: "444",
  },
  {
    bannerUrl:
      "https://uat-resizer.mysellcentive.com/1080x/https:…11e9e7dae15340ac7e8bb80e17363a5fd1b_BAP120_C2.jpg",
    redirectUrl: "/cart",
    seoDescription: "555",
  },
  {
    bannerUrl:
      "https://uat-minio-ecp.nd0.pl/ecp-media-tenant-belutti-uat/products-images/2/8/b/a/28ba8968d0048d7f34d22f361803476e24fc4d0b_BQM_035_c_001.jpg",
    redirectUrl: "/",
    seoDescription: "666",
  },
  {
    bannerUrl:
      "https://uat-minio-ecp.nd0.pl/ecp-media-tenant-belutti-uat/products-images/2/8/b/a/28ba8968d0048d7f34d22f361803476e24fc4d0b_BQM_035_c_001.jpg",
    redirectUrl: "/",
    seoDescription: "777",
  },
  {
    bannerUrl:
      "https://uat-resizer.mysellcentive.com/1080x/https:…11e9e7dae15340ac7e8bb80e17363a5fd1b_BAP120_C2.jpg",
    redirectUrl: "/login",
    seoDescription: "888",
  },
  {
    bannerUrl:
      "https://uat-minio-ecp.nd0.pl/ecp-media-tenant-belutti-uat/products-images/2/8/b/a/28ba8968d0048d7f34d22f361803476e24fc4d0b_BQM_035_c_001.jpg",
    redirectUrl: "/my-account",
    seoDescription: "999",
  },
  {
    bannerUrl:
      "https://uat-minio-ecp.nd0.pl/ecp-media-tenant-belutti-uat/products-images/2/8/b/a/28ba8968d0048d7f34d22f361803476e24fc4d0b_BQM_035_c_001.jpg",
    redirectUrl: "/search",
    seoDescription: "000",
  },
  {
    bannerUrl:
      "https://uat-resizer.mysellcentive.com/1080x/https:…11e9e7dae15340ac7e8bb80e17363a5fd1b_BAP120_C2.jpg",
    redirectUrl: "/cart",
    seoDescription: "111",
  },
  {
    bannerUrl:
      "https://uat-minio-ecp.nd0.pl/ecp-media-tenant-belutti-uat/products-images/2/8/b/a/28ba8968d0048d7f34d22f361803476e24fc4d0b_BQM_035_c_001.jpg",
    redirectUrl: "/contact-us",
    seoDescription: "222",
  },
];

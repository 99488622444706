export type {
  IPortalBox,
  IPortalPublication,
  IPortalSection,
  IPortalPage,
  IPortalSlot,
  IPortalStack,
  TPageContents,
} from "./lib/dto/page/portal.types";

export { SectionType } from "./lib/dto/page/portal.types";

export type {
  ILayoutPageBuilder,
  TPlatform,
  IPageLayout,
  ISectionPageBuilder,
  ISlotPageBuilder,
  IStackPageBuilder,
  IPublicationPageBuilder,
  IBoxPageBuilder,
  IBoxes,
  PageWithBoxesPageBuilder,
} from "./lib/dto/page/pageBuilder.types";

export type {
  TRegularBoxType,
  TSystemBoxType,
  IBoxData,
} from "./lib/dto/page/union.types";

export { PageCategoryType } from "./lib/dto/page/union.types";

export {
  PageBuilderLayoutToPage,
  PageBuilderPublicationToPagePublication,
  PageBuilderSectionToPageSection,
} from "./lib/dto/page/pageBuilderToPortalPage";

import { nanoid } from "nanoid";
import {
  ILayoutPageBuilder,
  PageWithBoxesPageBuilder,
} from "../../dto/page/pageBuilder.types";
import { PageCategoryType } from "../../dto/page/union.types";
import { IPaginatedGridResponse } from "../../dto/paginationResponse.types";
import { AlignmentHorizontalOption } from "../../dto/themeSettings/themeSettings.types";
import { advancePublicationConditions } from "./pages/advPublicConditions";

export type IGetPagesResponse = IPaginatedGridResponse<
  Array<Partial<ILayoutPageBuilder>>
>;

const staticPageForAdvancePublication: PageWithBoxesPageBuilder = {
  page: {
    sections: [
      {
        id: 1,
        customCss: "",
        uuid: nanoid(),
        mobileSubSection: false,
        name: "sekcja pierwsza",
        slots: [
          {
            id: 1433333,
            customCss: "",
            columns: 12,
            groupingMode: "NONE",
            stacks: [
              {
                publications: [
                  {
                    id: 12341119,
                    customCss: "",
                    uuid: nanoid(),
                    alignment: "LEFT",
                    published: true,
                    end: "",
                    begin: "1970-01-01T08:43:40.267138Z",
                    boxId: 1312,
                    publicationConditions: [
                      {
                        type: "DesktopPublicationCondition",
                        desktop: true,
                      },
                      {
                        type: "MobilePublicationCondition",
                        mobile: true,
                      },
                      {
                        type: "ClientTypePublicationCondition",
                        clientType: "B2C",
                      },
                      {
                        type: "UserAuthStatePublicationCondition",
                        state: "LOGGED",
                        included: true,
                      },
                    ],
                    width: "FULL",
                  },
                ],
              },
            ],
            sections: [],
            name: "slot 3",
            displaySettings: {},
            mobileDisplaySettings: {},
          },
        ],
        displaySettings: {},
        mobileDisplaySettings: {},
      },
    ],
    articleCategoryId: null,
    articleId: null,
    content: [],
    createdBy: "",
    creationDate: "",
    headerId: 2,
    footerId: 3,
    folderId: 0,
    id: 1,
    indexed: false,
    modificationDate: "",
    modifiedBy: "",
    name: "",
    onSitemap: false,
    order: 0,
    productCategoryCode: null,
    productCategoryHidden: false,
    productSku: null,
    conceptCode: null,
    removable: false,
    rootId: null,
    sectionsInherited: false,
    settings: {},
    type: PageCategoryType.REGULAR,
    url: "",
    urlAutoGenerated: false,
    version: 0,
    draft: false,
  },
  boxes: {
    1312: {
      id: 1312,
      name: "BoxLogin",
      type: "SYSTEM",
      local: false,
      displaySettings: {},
      contentSettings: {
        boxSettings: { boxAlignment: { horizontal: "right", vertical: "top" } },
      },
      content: { component: "LOGIN" },
      messages: {},
      settings: {
        login_form_redirect_after_login: "first-desktop",
        login_form_redirect_remind_password: "second-desktop",
        login_form_title_align: AlignmentHorizontalOption.LEFT,
      },
      mobileSettings: {
        login_form_title_align: AlignmentHorizontalOption.RIGHT,
      },
      mobileContentSettings: null,
      mobileDisplaySettings: null,
      version: 0,
      customCss: "",
      customJs: "",
    },
  },
  headerBoxes: {},
  footerBoxes: {},
};

export const generatePageByType = (
  pageType: PageCategoryType,
  sku?: string
): PageWithBoxesPageBuilder => ({
  page: {
    sections: [],
    articleCategoryId: 123,
    articleId: 19,
    content: [],
    createdBy: "",
    creationDate: "",
    headerId: null,
    footerId: null,
    folderId: 0,
    id: 1,
    indexed: false,
    modificationDate: "",
    modifiedBy: "",
    name: "",
    onSitemap: false,
    order: 0,
    productCategoryCode: "123",
    productCategoryHidden: false,
    productSku: sku ?? null,
    conceptCode: null,
    removable: false,
    rootId: null,
    sectionsInherited: false,
    settings: {},
    type: pageType,
    url: "",
    urlAutoGenerated: false,
    version: 0,
    draft: false,
  },
  boxes: {},
  headerBoxes: {},
  footerBoxes: {},
});

const header: Pick<PageWithBoxesPageBuilder, "boxes" | "page"> = {
  page: {
    sections: [
      {
        id: 1,
        customCss: "",
        uuid: nanoid(),
        mobileSubSection: false,
        name: "sekcja pierwsza",
        displaySettings: {},
        mobileDisplaySettings: {},
        slots: [
          {
            id: 11,
            customCss: "",
            columns: 12,
            groupingMode: "NONE",
            displaySettings: {},
            mobileDisplaySettings: {},
            stacks: [
              {
                publications: [
                  {
                    id: 11119,
                    customCss: "",
                    uuid: nanoid(),
                    alignment: "LEFT",
                    published: true,
                    end: "",
                    begin: "1970-01-01T08:43:40.267138Z",
                    boxId: 1312,
                    publicationConditions: [],
                    width: "FULL",
                  },
                ],
              },
            ],
            sections: [],
            name: "slot 1",
          },
        ],
      },
    ],
    articleCategoryId: null,
    articleId: null,
    content: [],
    createdBy: "",
    creationDate: "",
    headerId: undefined,
    footerId: undefined,
    folderId: 0,
    id: 2,
    indexed: false,
    modificationDate: "",
    modifiedBy: "",
    name: "",
    onSitemap: false,
    order: 0,
    productCategoryCode: null,
    productCategoryHidden: false,
    productSku: null,
    conceptCode: null,
    removable: false,
    rootId: null,
    sectionsInherited: false,
    settings: {},
    type: PageCategoryType.HEADER,
    url: "",
    urlAutoGenerated: false,
    version: 0,
    draft: false,
  },
  boxes: {
    1312: {
      id: 1312,
      name: "BoxBanner",
      type: "BANNER",
      local: false,
      displaySettings: {},
      contentSettings: {
        boxSettings: { boxAlignment: { horizontal: "right", vertical: "top" } },
      },
      content: { title: "Banner title1111" },
      messages: {},
      settings: {},
      mobileSettings: {},
      mobileContentSettings: null,
      mobileDisplaySettings: null,
      version: 0,
      customCss: "",
      customJs: "",
    },
  },
};

const footer: Pick<PageWithBoxesPageBuilder, "boxes" | "page"> = {
  page: {
    sections: [
      {
        id: 1,
        customCss: "",
        uuid: nanoid(),
        mobileSubSection: false,
        name: "sekcja pierwsza",
        displaySettings: {},
        mobileDisplaySettings: {},
        slots: [
          {
            id: 11,
            customCss: "",
            columns: 12,
            groupingMode: "NONE",
            displaySettings: {},
            mobileDisplaySettings: {},
            stacks: [
              {
                publications: [
                  {
                    id: 12341119,
                    customCss: "",
                    uuid: nanoid(),
                    alignment: "LEFT",
                    published: true,
                    end: "",
                    begin: "1970-01-01T08:43:40.267138Z",
                    boxId: 1312,
                    publicationConditions: [],
                    width: "FULL",
                  },
                ],
              },
            ],
            sections: [],
            name: "slot 1",
          },
        ],
      },
      {
        id: 22,
        customCss: "",
        uuid: nanoid(),
        mobileSubSection: false,
        name: "sekcja druga",
        slots: [
          {
            id: 143,
            customCss: "",
            columns: 3,
            groupingMode: "NONE",
            stacks: [
              {
                publications: [
                  {
                    id: 119,
                    customCss: "",
                    uuid: nanoid(),
                    alignment: "LEFT",
                    published: true,
                    end: "",
                    begin: "1970-01-01T08:43:40.267138Z",
                    boxId: 1500,
                    publicationConditions: [],
                    width: "FULL",
                  },
                ],
              },
            ],
            sections: [],
            name: "slot 3",
            displaySettings: {},
            mobileDisplaySettings: {},
          },
        ],
        displaySettings: {},
        mobileDisplaySettings: {},
      },
    ],
    articleCategoryId: null,
    articleId: null,
    content: [],
    createdBy: "",
    creationDate: "",
    headerId: undefined,
    footerId: undefined,
    folderId: 0,
    id: 3,
    indexed: false,
    modificationDate: "",
    modifiedBy: "",
    name: "",
    onSitemap: false,
    order: 0,
    productCategoryCode: null,
    productCategoryHidden: false,
    productSku: null,
    conceptCode: null,
    removable: false,
    rootId: null,
    sectionsInherited: false,
    settings: {},
    type: PageCategoryType.FOOTER,
    url: "",
    urlAutoGenerated: false,
    version: 0,
    draft: false,
  },
  boxes: {
    1312: {
      id: 123,
      name: "BoxBanner",
      type: "BANNER",
      local: false,
      displaySettings: {},
      contentSettings: {
        boxSettings: { boxAlignment: { horizontal: "right", vertical: "top" } },
        title: { show: true },
      },
      content: { title: "Banner title 2222" },

      messages: {},
      settings: {},
      mobileSettings: {},
      mobileContentSettings: null,
      mobileDisplaySettings: null,
      version: 0,
      customCss: "",
      customJs: "",
    },
    1500: {
      id: -23123,
      name: "Search Filters",
      type: "SEARCH_FILTERS",
      local: true,
      displaySettings: {},
      contentSettings: {
        title: { show: true },
        showMore: { show: true },
        clear: { show: true },
        count: { show: true },
        search: { show: true },
        categories: {
          show: true,
          position: "TOP",
        },
      },
      content: {
        title: "Filters 2222",
        clear: "Clear",
        showMore: "Show more",
        facetsSelection: [
          { attributeId: "Model", translation: "Model1" },
          { attributeId: "Generation", translation: "Generation1" },
          { attributeId: "Brands", translation: "Brands $$$" },
        ],
      },
      messages: {},
      settings: {},
      customJs: "",
      customCss: "",
      mobileSettings: {},
      mobileContentSettings: null,
      mobileDisplaySettings: null,
      version: 0,
    },
  },
};

export const pageMock = {
  staticPageForAdvancePublication,
  advancePublicationConditions,
  footer,
  header,
  generatePageByType,
};

export const PAGES_LIST_MOCK: IGetPagesResponse = {
  result: [
    {
      createdBy: "admin@9bits.pl",
      creationDate: "2022-05-31T13:39:09.461401Z",
      draft: true,
      folderId: 1,
      footerId: 54,
      headerId: 33,
      id: 55,
      indexed: true,
      modificationDate: "2022-05-31T13:39:09.730653Z",
      modifiedBy: "admin@9bits.pl",
      name: "222",
      onSitemap: true,
      order: 0,
      removable: true,
      type: PageCategoryType.REGULAR,
      url: "/222",
      version: 3,
    },
    {
      createdBy: "admin@9bits.pl",
      creationDate: "2022-05-31T13:39:09.461401Z",
      draft: false,
      folderId: 1,
      footerId: 54,
      headerId: 33,
      id: 56,
      indexed: true,
      modificationDate: "2022-05-31T13:39:09.730653Z",
      modifiedBy: "admin@9bits.pl",
      name: "111",
      onSitemap: true,
      order: 0,
      removable: true,
      type: PageCategoryType.REGULAR,
      url: "/111",
      version: 3,
    },
  ],
  page: {
    number: 1,
    size: 25,
    count: 6,
    total: 6,
  },
};

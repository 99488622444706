export enum PUBLICATION_COND {
  MOBILE = "MobilePublicationCondition",
  DESKTOP = "DesktopPublicationCondition",
  CUSTOMER = "CustomerGroupsPublicationCondition",
  USER_AUTH = "UserAuthStatePublicationCondition",
  CLIENT_TYPE = "ClientTypePublicationCondition",
}

export enum AUTH_STATUS {
  LOGGED = "LOGGED",
  ANONYMOUS = "ANONYMOUS",
}

export enum CLIENT_TYPE {
  ALL = "ALL",
  B2B = "B2B",
  B2C = "B2C",
}

export type PublicationCondition =
  | MobilePubCondition
  | DesktopPubCondition
  | CustomerPubCondition
  | UserAuthPubCondition
  | ClientTypePubCondition;

export type PublicationConditionType = PublicationCondition["type"];

export interface MobilePubCondition {
  type: PUBLICATION_COND.MOBILE;
  mobile: boolean;
}

export interface DesktopPubCondition {
  type: PUBLICATION_COND.DESKTOP;
  desktop: boolean;
}

export interface CustomerPubCondition {
  type: PUBLICATION_COND.CUSTOMER;
  groupIds: string[];
  included?: boolean;
  //   TODO: customerGroups: string[]; BE probably send this; to verify
}

export interface UserAuthPubCondition {
  type: PUBLICATION_COND.USER_AUTH;
  state: AUTH_STATUS.LOGGED | AUTH_STATUS.ANONYMOUS;
  included: boolean;
}

export interface ClientTypePubCondition {
  type: PUBLICATION_COND.CLIENT_TYPE;
  clientType: CLIENT_TYPE;
}
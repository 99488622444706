import { useMemo } from "react";
import cleanDeep from "clean-deep";
import get from "lodash.get";
import merge from "lodash.merge";
import set from "lodash.set";
import { useTheme } from "styled-components";
import { IUsePrepareSettingsProps } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  COLOR_TRANSPARENT,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  HEX,
  IGlobalButtonObject,
  IGlobalDropdownObject,
  IGlobalLinkObject,
  IGlobalPaletteColor,
  IGlobalSnackbarObject,
  IGlobalTooltipObject,
  IThemeState,
  ITypography,
  OutlineOptions,
  SettingPositionOptions,
  TButtonId,
  TButtonReadoutValue,
  TButtonSet,
  TColorId,
  TColorReadoutValue,
  TDropdownId,
  TDropdownReadoutValue,
  TDropdownSet,
  TLinkId,
  TLinkReadoutValue,
  TLinkSet,
  TSnackbarId,
  TSnackbarReadoutValue,
  TSnackbarSet,
  TTooltipId,
  TTooltipReadoutValue,
  TTooltipSet,
  TTypoId,
  TTypoReadoutValue,
  TTypoSet,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

const isColorId = (v: TColorReadoutValue | TColorId): v is TColorId => {
  return typeof (v as TColorId) === "string";
};

const isHEX = (v: TColorReadoutValue | TColorId | HEX): v is HEX => {
  return typeof v === "string" && v.startsWith("#");
};

const convertEmptyHEXToTransparent = (v: HEX | undefined): any => {
  if (typeof v === "string" && v === "#") return "#FFFFFF00";
  return v;
};

export const isColorTransparent = (
  color: TColorReadoutValue | TColorId | HEX,
  colorPalette: IGlobalPaletteColor[]
): boolean => {
  const hexColor = convertColorIdToHex(color, colorPalette);

  return hexColor === "#FFFFFF00" || hexColor === "#00000000";
};

export const convertColorIdToHex = (
  color: TColorReadoutValue | TColorId | HEX,
  colorPalette: IGlobalPaletteColor[]
): HEX => {
  const fallbackColor: HEX = COLOR_TRANSPARENT;

  if (color === undefined) return fallbackColor;
  if (isHEX(color)) return convertEmptyHEXToTransparent(color);
  if (isColorId(color) && colorPalette !== undefined) {
    return (
      convertEmptyHEXToTransparent(
        colorPalette?.find((key) => key.id === color)?.colorHex
      ) ??
      convertEmptyHEXToTransparent(colorPalette?.[0].colorHex) ??
      fallbackColor
    );
  }
  if (!isColorId(color) && color.id !== "custom") {
    return (
      convertEmptyHEXToTransparent(
        colorPalette?.find((key) => key.id === color.id)?.colorHex
      ) ??
      convertEmptyHEXToTransparent(colorPalette?.[0].colorHex) ??
      fallbackColor
    );
  } else if (!isColorId(color) && color.id === "custom") {
    return convertEmptyHEXToTransparent(color.custom);
  }

  return fallbackColor;
};

const isTypoId = (v: TTypoReadoutValue | TTypoId): v is TTypoId => {
  return typeof (v as TTypoId) === "string";
};

const isTypography = (
  v: TTypoReadoutValue | TTypoId | ITypography
): v is ITypography => {
  return typeof v !== "string" && Object.keys(v).length === 5;
};

export const convertTypoIdToValues = (
  value: TTypoReadoutValue | TTypoId | ITypography | undefined,
  typography?: TTypoSet
): ITypography => {
  const fallbackFont: ITypography = {
    family: FontFamilyOptions.ROBOTO,
    weight: FontWeightOptionsLongListOfWeights.Weight300,
    size: 16,
    lineHeight: 16,
    letterSpacing: 1,
  };

  if (value === undefined) return fallbackFont;
  if (isTypography(value)) return value;
  if (isTypoId(value) && typography !== undefined) {
    return (
      typography?.find((key) => key.id === value) ??
      typography[0] ??
      fallbackFont
    );
  } else if (!isTypoId(value) && value.id !== "custom") {
    return (
      typography?.find((key) => key.id === value.id) ??
      typography?.[0] ??
      fallbackFont
    );
  } else if (!isTypoId(value) && value.id === "custom") {
    return value.custom;
  }

  return fallbackFont;
};

export const isLinkId = (v: TLinkReadoutValue | TLinkId): v is TLinkId => {
  return typeof (v as TLinkId) === "string";
};

export const isTooltipId = (
  v: TTooltipReadoutValue | TTooltipId
): v is TTooltipId => {
  return typeof (v as TTooltipId) === "string";
};

export const convertLinkIdToValues = (
  value: TLinkReadoutValue | TLinkId,
  links?: TLinkSet
): IGlobalLinkObject => {
  const fallbackFont: IGlobalLinkObject = {
    text: {
      color: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
      activeColor: { id: "color$$1", custom: "#000000" },
    },
  };
  if (value === undefined) return fallbackFont;
  if (isLinkId(value) && links !== undefined) {
    return links?.find((key) => key.id === value) ?? links[0] ?? fallbackFont;
  } else if (!isLinkId(value) && value.id !== "custom") {
    return (
      links?.find((key) => key.id === value.id) ?? links?.[0] ?? fallbackFont
    );
  } else if (!isLinkId(value) && value.id === "custom") {
    return value.custom;
  }

  return fallbackFont;
};

const fallbackTooltip: IGlobalTooltipObject = {
  text: {
    color: { id: "color$$1", custom: "#000000" },
  },
  font: {
    id: "typo$$2",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 24,
      lineHeight: 10,
      letterSpacing: 1,
    },
  },
  background: {
    color: { id: "color$$1", custom: "#000000" },
  },
  arrow: true,
  verticalPadding: 10,
  horizontalPadding: 10,
  maximumWidth: 80,
  borderRadius: 10,
};

export const convertTooltipIdToValues = (
  value: TTooltipReadoutValue | TTooltipId,
  tooltips?: TTooltipSet
): IGlobalTooltipObject => {
  if (value === undefined) return fallbackTooltip;
  if (isTooltipId(value) && tooltips !== undefined) {
    return (
      tooltips?.find((key) => key.id === value) ??
      tooltips[0] ??
      fallbackTooltip
    );
  } else if (!isTooltipId(value) && value.id !== "custom") {
    return (
      tooltips?.find((key) => key.id === value.id) ??
      tooltips?.[0] ??
      fallbackTooltip
    );
  } else if (!isTooltipId(value) && value.id === "custom") {
    return value.custom;
  }

  return fallbackTooltip;
};

export const isSnackbarId = (
  v: TSnackbarReadoutValue | TSnackbarId
): v is TSnackbarId => {
  return typeof v === "string" && v.startsWith("snackbar$$");
};

export const convertSnackbarIdToValues = (
  value: TSnackbarReadoutValue | TSnackbarId,
  snackbars?: TSnackbarSet
): IGlobalSnackbarObject => {
  const fallbackSnackbar: IGlobalSnackbarObject = {
    timeToDisplay: 5,
    verticalPadding: 20,
    horizontalPadding: 20,
    maxWidth: 376,
    borderStyle: OutlineOptions.NONE,
    borderRadius: 0,
    borderThickness: 0,
    textStyle: {
      id: "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
        size: 24,
        lineHeight: 10,
        letterSpacing: 1,
      },
    },
    textColor: { id: "color$$1", custom: "#000000" },
    backgroundColor: { id: "color$$1", custom: "#000000" },
    borderColor: { id: "color$$1", custom: "#000000" },
    iconClose: true,
  };
  if (value === undefined) return fallbackSnackbar;
  if (isSnackbarId(value) && snackbars !== undefined) {
    return (
      snackbars?.find((key) => key.id === value) ??
      snackbars[0] ??
      fallbackSnackbar
    );
  } else if (!isSnackbarId(value) && value.id !== "custom") {
    return (
      snackbars?.find((key) => key.id === value.id) ??
      snackbars?.[0] ??
      fallbackSnackbar
    );
  } else if (!isSnackbarId(value) && value.id === "custom") {
    return value.custom;
  }

  return fallbackSnackbar;
};

const isButtonId = (
  value: TButtonReadoutValue | TButtonId
): value is TButtonId => {
  if (typeof value === "string") {
    return value.startsWith("button$$");
  }
  return false;
};

const fallbackButton: IGlobalButtonObject = {
  horizontalPadding: 20,
  verticalPadding: 20,
  border: {
    radius: 0,
    radiusV2: { topLeft: 0, topRight: 0, bottomLeft: 0, bottomRight: 0 },
    width: 10,
    widthV2: { top: 10, bottom: 10, left: 10, right: 10 },
    color: { id: "color$$1", custom: "#000000" },
    hoverColor: { id: "color$$1", custom: "#000000" },
    activeColor: { id: "color$$1", custom: "#000000" },
    disabledColor: { id: "color$$1", custom: "#000000" },
  },
  font: {
    id: "typo$$2",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 24,
      lineHeight: 10,
      letterSpacing: 1,
    },
  },
  background: {
    color: { id: "color$$1", custom: "#000000" },
    hoverColor: { id: "color$$1", custom: "#000000" },
    activeColor: { id: "color$$1", custom: "#000000" },
    disabledColor: { id: "color$$1", custom: "#000000" },
  },
  text: {
    color: { id: "color$$2", custom: "#000000" },
    hoverColor: { id: "color$$2", custom: "#000000" },
    activeColor: { id: "color$$2", custom: "#000000" },
    disabledColor: { id: "color$$2", custom: "#000000" },
  },
  icon: {
    iconUrl: "",
    position: SettingPositionOptions.RIGHT,
    size: 16,
    spacing: 23,
    color: {
      id: "color$$1",
      custom: "#000000",
    },
    hoverColor: {
      id: "color$$1",
      custom: "#000000",
    },
    activeColor: {
      id: "color$$1",
      custom: "#000000",
    },
    disabledColor: {
      id: "color$$1",
      custom: "#000000",
    },
  },
};

export const convertButtonIdToValues = (
  value: TButtonReadoutValue | TButtonId | undefined,
  buttonSet?: TButtonSet
): IGlobalButtonObject => {
  if (value === undefined) return fallbackButton;
  if (isButtonId(value) && buttonSet !== undefined) {
    return (
      buttonSet?.find((key) => key.id === value) ??
      buttonSet[0] ??
      fallbackButton
    );
  } else if (!isButtonId(value) && value.id !== "custom") {
    return (
      buttonSet?.find((key) => key.id === value.id) ??
      buttonSet?.[0] ??
      fallbackButton
    );
  } else if (!isButtonId(value) && value.id === "custom") {
    return value.custom;
  }

  return fallbackButton;
};

const fallbackDropdown: IGlobalDropdownObject = {
  dropdownWidth: 100,
  dropdownHeight: 40,
  dropdownAlign: AlignmentHorizontalOption.LEFT,
  dropdownBackground: {
    color: { id: "color$$1", custom: "#000000" },
    hoverColor: { id: "color$$1", custom: "#000000" },
    disabledColor: { id: "color$$1", custom: "#000000" },
  },
  dropdownBorder: {
    color: { id: "color$$1", custom: "#000000" },
    radius: 0,
    width: { top: 0, bottom: 0, left: 0, right: 0 },
    style: OutlineOptions.NONE,
  },

  selectedItemTypography: {
    id: "typo$$1",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 24,
      lineHeight: 10,
      letterSpacing: 1,
    },
  },
  selectedItemLink: {
    id: "link$$1",
    custom: {
      text: {
        color: {
          id: "color$$1",
          custom: "#000000",
        },
        hoverColor: {
          id: "color$$1",
          custom: "#000000",
        },
        activeColor: {
          id: "color$$1",
          custom: "#000000",
        },
      },
    },
  },
  selectedItemAlignment: AlignmentHorizontalOption.LEFT,
  selectedItemDisabledTypography: {
    id: "typo$$1",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 24,
      lineHeight: 10,
      letterSpacing: 1,
    },
  },
  selectedItemDisabledColor: { id: "color$$1", custom: "#000000" },

  openOnHover: false,
  openIcon: {
    size: 15,
    position: SettingPositionOptions.RIGHT,
    spacing: 15,
    iconUrl: "",
  },
  closeIcon: {
    size: 15,
    position: SettingPositionOptions.RIGHT,
    spacing: 15,
    iconUrl: "",
  },

  errorMessageTypography: {
    id: "typo$$1",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 24,
      lineHeight: 10,
      letterSpacing: 1,
    },
  },
  errorMessageColor: { id: "color$$1", custom: "#000000" },

  columnWidth: 100,
  columnHeight: 200,
  columnAlignment: AlignmentHorizontalOption.CENTER,
  columnShadow: false,
  columnShadowColor: { id: "color$$1", custom: "#000000" },
  columnShadowPositionX: 0,
  columnShadowPositionY: 0,
  columnShadowBlur: 0,
  columnBorder: {
    color: { id: "color$$1", custom: "#000000" },
    radius: 0,
    width: { top: 0, bottom: 0, left: 0, right: 0 },
    style: OutlineOptions.NONE,
  },

  dropdownItemTypography: {
    id: "typo$$1",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 24,
      lineHeight: 10,
      letterSpacing: 1,
    },
  },
  dropdownItemLink: {
    id: "link$$1",
    custom: {
      text: {
        color: {
          id: "color$$1",
          custom: "#000000",
        },
        hoverColor: {
          id: "color$$1",
          custom: "#000000",
        },
        activeColor: {
          id: "color$$1",
          custom: "#000000",
        },
      },
    },
  },
  dropdownItemPadding: {
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  dropdownItemAlignment: AlignmentHorizontalOption.LEFT,
  dropdownItemBackground: {
    color: { id: "color$$1", custom: "#000000" },
    activeColor: { id: "color$$1", custom: "#000000" },
    hoverColor: { id: "color$$1", custom: "#000000" },
    disabledColor: { id: "color$$1", custom: "#000000" },
  },
  dropdownItemDisabledTextColor: { id: "color$$1", custom: "#000000" },
};

const isDropdownId = (
  value: TDropdownReadoutValue | TDropdownId
): value is TDropdownId => {
  if (typeof value === "string") {
    return value.startsWith("dropdown$$");
  }
  return false;
};

export const convertDropdownIdToValues = (
  value: TDropdownReadoutValue | TDropdownId,
  dropdownSet?: TDropdownSet
): IGlobalDropdownObject => {
  if (value === undefined) return fallbackDropdown;
  if (isDropdownId(value) && dropdownSet !== undefined) {
    return (
      dropdownSet?.find((key) => key.id === value) ??
      dropdownSet[0] ??
      fallbackDropdown
    );
  } else if (!isDropdownId(value) && value.id !== "custom") {
    return dropdownSet?.find((key) => key.id === value.id) ?? fallbackDropdown;
  } else if (!isDropdownId(value) && value.id === "custom") {
    return value.custom;
  }

  return fallbackDropdown;
};

export const getPreparedSettings = <T, S>(
  props: T,
  settingsPattern: S,
  themeSettings?: IThemeState,
  themePath?: string,
  themeWrapperPath?: string
): S & Partial<IThemeState> => {
  let theme = { ...themeSettings };
  //TODO probably should be removed because in Box.tsx we are already cleaning props
  const propsWithoutEmptySettings = cleanDeep(props, {
    nullValues: true,
    emptyStrings: false,
    emptyObjects: false,
    emptyArrays: false,
  });

  if (themeSettings !== undefined && themePath !== undefined) {
    if (themeWrapperPath !== undefined) {
      set(theme, themeWrapperPath, get(themeSettings, themePath));
    } else {
      theme = get(themeSettings, themePath) ?? {};
    }
  }
  return merge({}, settingsPattern, theme, propsWithoutEmptySettings);
};

/**
 * Merges settings and optionally converts values to CSS based on `generator`.
 *
 * @param props -
 * should include any of displaySettings, contentSettings and content fetched from BE
 *
 * @param settingsPattern -
 * should be an object composed of fallback patterns
 *
 * @param options -
 * (optional) `includeTheme` defines if theme should be merged too (themePath option has to be set)
 *
 * @param options -
 * (optional) `themePath` get nested theme property by string path
 *
 * @param options -
 * (optional) `themeWrapperPath` defines if object should be wrapped by a key
 *
 * @returns `settings` | for generator: `cssStyles`
 */
export const usePrepareSettings = <T, S>({
  props,
  settingsPattern,
  options,
}: IUsePrepareSettingsProps<T, S>): {
  settings: S & Partial<IThemeState>;
} => {
  const themeSettings = useTheme() as IThemeState;
  const mutatedSettings = useMemo(
    () =>
      getPreparedSettings(
        props,
        settingsPattern,
        options?.includeTheme === true ? themeSettings : undefined,
        options?.themePath,
        options?.themeWrapperPath
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props]
  );
  return { settings: mutatedSettings };
};

export const getOpacity = (background: any) =>
  background.backgroundUrl !== "" ? background.opacity / 100 : 1;

export const convertHexToRGBA = (hexColor: string, opacity: number) => {
  if (opacity < 0 || opacity > 100) {
    return null;
  }

  hexColor = hexColor.replace("#", "");

  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  const alpha = opacity / 100;

  const rgba = `rgba(${r}, ${g}, ${b}, ${alpha})`;

  return rgba;
};

import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { IPortalSection } from "@ecp-pageTypes";
import { PortalPath } from "../../shared/portalPath/portalPath";
import { usePathname } from "next/navigation";

export const RowPopupContext = createContext({} as IRowPopupContext);

export function useRowPopupContext() {
  const context = useContext(RowPopupContext);
  if (!context) {
    throw new Error(
      "useRowPopupContext must be used under RowPopupContext.Provider"
    );
  }
  return context;
}

interface IRowPopupContext {
  activePopup: {
    sectionData: IPortalSection;
    sectionPath: PortalPath;
  } | null;
  setActivePopup: (path: PortalPath) => void;
  closeActivePopup: (path?: PortalPath) => void;
  togglePopup: (path: PortalPath) => void;
}

export const RowPopupContextProvider = ({
  children,
  sections,
}: {
  children: React.ReactNode;
  sections: IPortalSection[];
}) => {
  const [activeSectionPath, setActiveSectionPath] = useState<PortalPath | null>(
    null
  );

  const activeSection = useMemo(
    () =>
      sections?.find((s, idx) => {
        return (
          activeSectionPath &&
          s.sectionOrder === activeSectionPath.getFirstSectionIndex() &&
          s.id === activeSectionPath.getFirstSection()
        );
      }) ?? null,
    [sections, activeSectionPath]
  );

  const setActivePopup = (path: PortalPath) => {
    const orderId = sections.find(
      (s) => s.id === path.getFirstSection()
    )?.sectionOrder;

    setActiveSectionPath(
      path.setIndexForId(path.getFirstSection(), orderId ?? "x")
    );
  };

  const closeActivePopup = (path?: PortalPath) => {
    if (path === undefined) {
      setActiveSectionPath(null);
    }

    if (path && activeSectionPath?.isPathEqual(path.toString())) {
      setActiveSectionPath(null);
    }
  };

  const togglePopup = (path: PortalPath) => {
    if (activeSectionPath?.isPathEqual(path.toString())) {
      closeActivePopup();
    } else {
      setActivePopup(path);
    }
  };

  const values = useMemo(
    () => ({
      activePopup:
        activeSection && activeSectionPath
          ? {
              sectionData: activeSection,
              sectionPath: activeSectionPath,
            }
          : null,
      setActivePopup,
      closeActivePopup,
      togglePopup,
    }),
    [activeSection, activeSectionPath]
  );

  const pathname = usePathname();

  useEffect(() => {
    setActiveSectionPath(null);
  }, [pathname]);

  return (
    <RowPopupContext.Provider value={values}>
      {children}
    </RowPopupContext.Provider>
  );
};

import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { IGlobalSnackbarObject } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";

export const StyledPortalSnackbar = styled.div<
  IGlobalSnackbarObject & { isEmptySnackbar: boolean }
>(
  ({
    verticalPadding,
    horizontalPadding,
    maxWidth,
    backgroundColor,
    borderStyle,
    borderColor,
    borderRadius,
    borderThickness,
    theme,
    isEmptySnackbar,
  }) => {
    return `
      display: flex;
      justify-content: space-between;
      padding-top: ${verticalPadding}px;
      padding-bottom: ${verticalPadding}px;
      max-width: ${maxWidth}px;
      border-style: ${borderStyle};
      border-width: ${borderThickness}px;
      border-radius: ${borderRadius}px;
      border-color: ${convertColorIdToHex(borderColor, theme.colorPalette)};
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
      ${isEmptySnackbar ? `width: 200px;` : ""}

      background-color: ${convertColorIdToHex(
        backgroundColor,
        theme.colorPalette
      )};

      .message{
        padding-left: ${horizontalPadding}px;
        padding-right: ${horizontalPadding}px;
      }

      .snackbar-icon-close {
        padding-right: 0.75rem;
      }
    `;
  }
);

export const StyledPortalToastContainer = styled(ToastContainer)`
  .Toastify__toast-body {
    padding: 0px;
  }

  .Toastify__toast {
    min-height: auto;
    padding: 0px;
    background-color: unset;
    cursor: auto;
    box-shadow: none;
    padding: 2px;
  }

  &&&.Toastify__toast-container {
    width: auto;
  }
`;

export const DELIVERY_PRICES_COLUMNS_NAMES_RESPONSE = {
  columnNames: [
    "free_delivery_info",
    "free_delivery_amount",
    "lowest_delivery_fare",
  ],
};

export const DELIVERY_PRICES_SINGLE_RESULT_RESPONSE = {
  result: {
    free_delivery_info: 111,
    free_delivery_amount: 222,
    lowest_delivery_fare: 333,
  },
};

import { createContext, useContext, useEffect, useMemo, useState } from "react";

export type EffectsData = {
  height?: number;
  sumHeight?: number;
  sectionUuid?: string;
  sectionId?: number;
  sticky?: boolean;
  offset?: number;
  isHeaderSection?: boolean;
  zIndex?: number;
  ref?: React.RefObject<HTMLDivElement>;
};

export const SectionEffectsContext = createContext<{
  effectsData: EffectsData[];
  isAnySectionSticky: boolean;
  setSectionEffectsData: (
    sectionUuid: string,
    height: number,
    ref?: React.RefObject<HTMLDivElement>
  ) => void;
}>({
  effectsData: [],
  isAnySectionSticky: false,
  setSectionEffectsData: () => null,
});

export function useSectionEffectsContext() {
  const context = useContext(SectionEffectsContext);
  if (!context) {
    console.log(
      "useSectionEffectsContext must be used under StickyOffsetsContext.Provider"
    );
  }
  return context;
}

export const SectionEffectsProvider = ({
  children,
  effects,
}: {
  effects: EffectsData[];
  children: React.ReactNode;
}) => {
  const [effectsData, setEffectsData] = useState<EffectsData[]>(effects);

  useEffect(() => {
    setEffectsData(effects);
  }, [effects]);

  const setSectionEffectsData = (
    sectionUuid: string,
    height: number,
    ref?: React.RefObject<HTMLDivElement>
  ) => {
    setEffectsData((prev) =>
      prev.map((offset) => {
        if (offset.sectionUuid === sectionUuid) {
          return { ...offset, height, ref };
        }
        return offset;
      })
    );
  };

  const getCalculatedEfectsData = useMemo(() => {
    let sumHeight = 0;
    return effectsData.map((offset, idx, array) => {
      if (offset.sticky) {
        let offsetSum = 0;
        for (let i = 0; i < idx; i++) {
          if (array[i].sticky) {
            offsetSum += array[i].height || 0;
          }
        }
        array[idx].offset = offsetSum;
      }
      sumHeight += offset.height || 0;
      array[idx].sumHeight = sumHeight;

      return array[idx];
    });
  }, [effectsData]);

  return (
    <SectionEffectsContext.Provider
      value={{
        effectsData: getCalculatedEfectsData,
        setSectionEffectsData,
        isAnySectionSticky: effectsData.some((offset) => offset.sticky),
      }}
    >
      {children}
    </SectionEffectsContext.Provider>
  );
};

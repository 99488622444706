import { useCallback } from "react";
import { useRouter } from "next/router";
import { getSessionStorageTenant } from "@ecp-redux/api/tenantHelpers";
import { isClientSide } from "../../helpers/helpers";

// This is a React hook that sets the tenant value in sessionStorage based on a URL query parameter, removes the tenant parameter from the URL,
// and removes the tenant value from sessionStorage when the component using the hook unmounts.
const useSetTenant = () => {
  const router = useRouter();

  // function removing tenants from the url and replacing url
  const removeQueryFromUrl = useCallback((): void => {
    const { query } = router;

    const params = { ...query };
    if (params["tenant"] || params["isTheme"]) {
      delete params["tenant"];
      delete params["isTheme"];

      router.replace({ href: "/", query: params }, undefined, {
        shallow: true,
      });
    }
  }, [router]);

  const moveQueryToSessionStorage = useCallback(
    (storageValue: string | null, type: "tenant" | "isTheme"): void => {
      const urlValue = router?.query[type];
      if (
        typeof urlValue === "string" &&
        storageValue !== urlValue &&
        urlValue != undefined
      ) {
        sessionStorage.setItem(type, urlValue);
      }
    },
    [router]
  );

  if (!isClientSide()) return;

  const tenantValue = getSessionStorageTenant();
  const themeModeValue = sessionStorage.getItem("isTheme");

  moveQueryToSessionStorage(tenantValue, "tenant");
  moveQueryToSessionStorage(themeModeValue, "isTheme");

  removeQueryFromUrl();
};

export default useSetTenant;

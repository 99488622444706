import { IDisplaySettingsSection } from "../../dto/themeSettings/StyledSection.types";
import { IDisplaySettingsSlot } from "../../dto/themeSettings/StyledSlot.types";
import { IBoxData, PageCategoryType, TRegularBoxType } from "./union.types";

export type TPlatform = "MOBILE" | "DESKTOP";

export interface IPortalSection {
  id: number;
  customCss: string;
  uuid: string;
  sectionOrder: number;
  mobileSubSection: boolean;
  slots: IPortalSlot[];
  displaySettings: Partial<IDisplaySettingsSection>;
}

export interface IPortalSlot {
  id: number;
  customCss: string;
  sectionId: number;
  columns: number;
  groupingMode: string;
  stacks: IPortalStack[];
  sections: IPortalSection[];
  displaySettings: Partial<IDisplaySettingsSlot>;
  slotOrder?: number;
}

export interface IPortalStack {
  publications: IPortalPublication[];
}

export interface IPortalPublication {
  id: number;
  box: IPortalBox<IBoxData>;
  publicationOrder: number;
  alignment: string; // to type
  width: string; // to type
  boxId: number;
}

export interface IPortalBox<T extends IBoxData = IBoxData> {
  id: number;
  name: string;
  type: TRegularBoxType;
  local: boolean;
  content: T["content"];
  messages: T["messages"];
  settings: T["settings"];
  contentSettings: T["contentSettings"];
  displaySettings: T["displaySettings"];
  version: number;
  customCss: string;
  customJs: string;
}

export interface IPortalPage {
  id: number;
  name: string;
  url: string;
  type: PageCategoryType;
  contents: TPageContents;
  sections: IPortalSection[];
  header: IPortalPage | null;
  footer: IPortalPage | null;
}

export type TPageContents = [
  {
    id: number | null;
    title: string;
    description: string;
    keywords: string;
    language: string;
  },
];

export enum SectionType {
  HEADER = "HEADER",
  FOOTER = "FOOTER",
  MAIN = "MAIN",
  POPUP = "POPUP",
}

export type IPortalBoxes = { [index in string | number]: IPortalBox<IBoxData> };

export type PortalPageWithBoxes = {
  page: IPortalPage;
  boxes: IPortalBoxes;
  headerBoxes: IPortalBoxes;
  footerBoxes: IPortalBoxes;
};

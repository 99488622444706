import React, { Context } from "react";
import { useContext } from "react";
import { TRegularBoxType, TSystemBoxType } from "@ecp-pageTypes";

export const MessagesSettingsContext = React.createContext<Record<string, any>>(
  {
    messages: {},
    settings: {},
    boxType: "",
  }
);

export function useMessagesSettingsContext<
  Messages extends Record<string, any>,
  Settings extends Record<string, any>
>() {
  const context = useContext<{
    messages: Messages;
    settings: Settings;
    boxType: TRegularBoxType | TSystemBoxType;
  }>(
    MessagesSettingsContext as unknown as Context<{
      messages: Messages;
      settings: Settings;
      boxType: TRegularBoxType | TSystemBoxType;
    }>
  );

  if (!context) {
    throw new Error(
      "useMessagesContext must be used under MessagesSettingsContext.Provider"
    );
  }
  return context;
}

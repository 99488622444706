import { nanoid } from "nanoid";
import { z } from "zod";
import {
  Border_v2Scheme,
  ColorReadoutValueScheme,
  GlobalColorsStylesSchema,
  SchemeDescription,
  SpacingScheme,
  TypoReadoutValueScheme,
  defaultBorder,
  inheritedFromBasic,
} from "./themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  OutlineOptions,
} from "./themeSettings.types";

// == PATTERN START ==

// to remove after improvment
// export const defaultTableCellPattern = (id?: number): GlobalTableCellItem => (cellTest);

export const defaultTableCellPattern = (id?: number): GlobalTableCellItem => ({
  id: `tableCell$$${id ? id : nanoid()}`,
  name: "Table Style",
  oddBackgroundColor: {
    color: { id: "color$$1", custom: "#000000" },
    activeColor: { id: "color$$1", custom: "#000000" },
    hoverColor: { id: "color$$1", custom: "#000000" },
    disabledColor: { id: "color$$1", custom: "#000000" },
  },
  evenBackgroundColor: {
    color: { id: "color$$1", custom: "#000000" },
    activeColor: { id: "color$$1", custom: "#000000" },
    hoverColor: { id: "color$$1", custom: "#000000" },
    disabledColor: { id: "color$$1", custom: "#000000" },
  },
  border: {
    color: { id: "color$$1", custom: "#000000" },
    radius: 0,
    width: { top: 1, bottom: 1, left: 1, right: 1 },
    style: OutlineOptions.SOLID,
  },
  padding: {
    top: 5,
    bottom: 5,
    right: 5,
    left: 5,
  },
  textStyle: {
    id: "typo$$1",
    custom: {
      family: FontFamilyOptions.ROBOTO,
      weight: FontWeightOptionsLongListOfWeights.Weight300,
      size: 24,
      lineHeight: 10,
      letterSpacing: 1,
    },
  },
  textColor: { id: "color$$1", custom: "#000000" },
  alignmentHorizontal: AlignmentHorizontalOption.CENTER,
  alignmentVertical: AlignmentVerticalOption.CENTER,
});

// == PATTERN END ==
// == SCHEMEs START ==

export const TableCellIdScheme = z
  .custom<`tableCell$$${string}`>((val) => {
    return /tableCell\$\$[A-Za-z0-9_-]+/.test(val as string);
  })
  .describe(SchemeDescription.TABLE_CELL);

export const GlobalTableCellObjectScheme = z.object({
  oddBackgroundColor: GlobalColorsStylesSchema,
  evenBackgroundColor: GlobalColorsStylesSchema,
  border: Border_v2Scheme.default(defaultBorder),
  padding: SpacingScheme.default({
    top: 5,
    bottom: 5,
    right: 5,
    left: 5,
  }),
  textStyle: TypoReadoutValueScheme,
  textColor: ColorReadoutValueScheme.catch({
    id: "color$$1",
    custom: "#FFFFFF",
  }),
  alignmentHorizontal: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  alignmentVertical: z
    .nativeEnum(AlignmentVerticalOption)
    .default(AlignmentVerticalOption.CENTER)
    .catch(AlignmentVerticalOption.CENTER),
});

export const GlobalTableCellReadoutValueScheme = z
  .object({
    id: TableCellIdScheme.or(z.literal("custom"))
      .default("tableCell$$1")
      .catch("tableCell$$1"),
    custom: GlobalTableCellObjectScheme,
  })
  .default({
    id: "tableCell$$1",
    custom: GlobalTableCellObjectScheme.parse(defaultTableCellPattern()),
  }) // poprawka
  .catch({
    id: "tableCell$$1",
    custom: GlobalTableCellObjectScheme.parse(defaultTableCellPattern()),
  }); // poprawka

export const TableCellSetItemScheme = z
  .object({
    id: TableCellIdScheme.default("tableCell$$1").catch("tableCell$$1"),
    name: z.string().default("Table Style").catch("Table Style"),
  })
  .merge(GlobalTableCellObjectScheme);

export const TableCellSetScheme = z.array(TableCellSetItemScheme).default([]);

export const TableCellSetSchemeMobile = z
  .array(TableCellSetItemScheme.extend(inheritedFromBasic))
  .default([]);

// == SCHEMEs END ==
// == TYPEs START ==

export type TableCellId = z.infer<typeof TableCellIdScheme>;
export type GlobalTableCellObject = z.infer<typeof GlobalTableCellObjectScheme>;
export type GlobalTableCellReadoutValue = z.infer<
  typeof GlobalTableCellReadoutValueScheme
>;
export type GlobalTableCellItem = z.infer<typeof TableCellSetItemScheme>;
export type TableCellSet = z.infer<typeof TableCellSetScheme>;
export type TableCellSetMobile = z.infer<typeof TableCellSetSchemeMobile>;

// == TYPEs END ==
